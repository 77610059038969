import { Button, Modal } from "antd";
import { trimStart } from "lodash";
import * as mobx from "mobx";
import { observer, Observer } from "mobx-react";
import * as React from "react";
import * as ReactDOM from "react-dom";
import styled from "styled-components";

import QRCodeGenerator from "../../utils/QRCodeGenerator";
import BaseComponent from "../BaseComponent";

interface IProps {
    visible: boolean;
    text: string;
    imageUrl?: string;
    image?: HTMLImageElement;
    size?: number;
    onSubmitted?: () => void;
    onCancelled?: () => void;
}
export interface IModalFuncProps {
    text: string;
    imageUrl?: string;
    image?: HTMLImageElement;
    size?: number;
}
@observer
export default class QRCodeModal extends BaseComponent<IProps, any> {

    @mobx.computed
    public get qrCodeUrl() { return this.$qrCodeUrl; }

    @mobx.observable
    private $qrCodeUrl: string = "";

    public componentDidMount() {
        this.generate();
    }

    public componentWillReceiveProps(nextProps) {
        this.generate();
    }

    private resolveOrigin() {
        let url = (window as any).location.href;
        let host = (window as any).location.host;
        let a = document.createElement("a");
        a.href = url;
        // 对于IE浏览器，尤其是IE8、IE9，如果你给.href设置一个相对的URl，取不到完整的链接属性；
        // 然而，当你他自己的href属性再一次赋给.href时，则会返回一个绝对的URL，这时候你就可以获取所有的链接属性了。
        // https://stackoverflow.com/questions/736513/how-do-i-parse-a-url-into-hostname-and-path-in-javascript
        if (a.host === "") {
            a.href = a.href;
        }
        return a.origin || a.protocol + "//" + host;
    }

    private generate() {
        let text = this.props.text;
        if (!text) {
            return;
        }
        if (text.indexOf("http") !== 0) {
            let host = this.resolveOrigin();
            text = trimStart(text, "/");
            text = `${host}/${text}`;
        }
        QRCodeGenerator.generate(text, this.props)
            .then((blobUrl: string) => {
                this.$qrCodeUrl = blobUrl;
                console.log(this.qrCodeUrl);
            });
    }

    private download() {
        if (this.qrCodeUrl) {
            let aEl = document.createElement("a");
            aEl.href = this.qrCodeUrl;
            aEl.setAttribute("download", "二维码.png");
            document.body.appendChild(aEl);
            aEl.click();
            document.body.removeChild(aEl);
        }
        console.log(`二维码下载方法已经执行`);
    }

    public render() {
        // if (!this.props.text) {
        //     return null;
        // }
        return (
            <Modal
                visible={this.props.visible}
                okText="下载"
                cancelText="关闭"
                closable={false}
                footer={null}
                centered={true}
            >
                <Observer>{() => <ImageContainer><QrImage src={this.qrCodeUrl} /></ImageContainer>}</Observer>
                <ActionContainer>
                    <Button size="large" onClick={() => this.props.onCancelled()}>关&nbsp;&nbsp;闭</Button>
                    <Button type="primary" size="large" onClick={() => { this.download(); console.log(`被点击了`); }}>下&nbsp;&nbsp;载</Button>
                </ActionContainer>
            </Modal>
        );
    }

    public static async show(config: IModalFuncProps): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            let container = document.createElement("div");
            document.body.appendChild(container);

            let dispose = (success: boolean) => {
                ReactDOM.render(
                    <QRCodeModal
                        {...config}
                        visible={false}
                        onCancelled={() => {
                            // dispose(false);
                        }}
                    />,
                    container);
                setTimeout(() => {
                    ReactDOM.unmountComponentAtNode(container);
                    document.body.removeChild(container);
                    resolve(success);
                }, 300);

            };
            ReactDOM.render(
                <QRCodeModal
                    {...config}
                    visible={true}
                    onCancelled={() => {
                        dispose(false);
                    }}
                />,
                container);
        });
    }
}
const ImageContainer = styled.div`
    width: 350px;
    height: 350px;
    margin: auto;
`;
const QrImage = styled.img`
    display: block;
    width: 100%;
`;
const ActionContainer = styled.div`
    margin-top: 20px;
    text-align: center;
    button{
        margin: auto 10px;
    }
`;