import { Button, Form, Modal } from "antd";
import _ from "lodash";
import * as mobx from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import ReactDOM from "react-dom";
import styled, { injectGlobal } from "styled-components";

import Doc from "../../../models/Doc";
import Folder from "../../../models/Folder";
import Node from "../../../models/Node";
import BaseComponent from "../../BaseComponent";
import Hezhi from "../../hezhi/Hezhi";

import ModalUtils from "./ModalUtils";
import NodeInfoView from "./NodeInfoView";

const FormItem = Form.Item;

interface IProps {
    node: Node;
    visible: boolean;
    onSubmitted?: () => void;
    onCancelled?: () => void;
    afterClose?(): void;
}
@observer
export default class NodeDeleteModal extends BaseComponent<IProps, any> {

    @mobx.computed
    public get node() { return this.props.node; }

    @mobx.observable
    public folder: Folder = null;

    @mobx.observable
    public doc: Doc = null;

    @mobx.observable
    private canDelete: boolean = true;

    @mobx.observable
    private cannotDeleteMessage: string = null;

    @mobx.observable
    private isLoaded: boolean = false;

    /**
     * 盒知扩展。是否被盒知主程序使用。
     */
    @mobx.observable
    private isHezhiUsed: boolean = false;

    public componentDidMount() {
        this.run("加载节点信息", async () => {
            /**
             * 有以下条件之一，就不能删除
             */

            if (this.node.type === "folder") {
                this.folder = Folder.createById(this.node.id, this.store);
                await this.folder.refresh();

                if (this.folder.children && this.folder.children.length > 0) {
                    this.canDelete = false;
                    this.cannotDeleteMessage = `不允许删除有内容的文件夹`;
                }
            } else {
                this.doc = await Doc.createById(this.node.id, this.store);
                await this.doc.refresh();
                if (this.doc.beReferenced && this.doc.beReferenced.length > 0) {
                    this.canDelete = false;
                    this.cannotDeleteMessage = `不允许删除带有引用关系的文档`;
                }
            }

            this.isLoaded = true;

            /**
             * 盒知扩展。
             */
            if (Hezhi.isRunning) {
                if (this.node.type === "hezhi-product") {
                    // 判断这个文档，是否被盒知主程序引用
                    this.isHezhiUsed = await Hezhi.instance.checkProductUsage(this.node.data);

                    console.log(`this.isHezhiUsed`, this.isHezhiUsed);

                }
            }
        });
    }

    private delete() {
        this.props.onSubmitted();
    }

    private buildTitle(): React.ReactNode {
        let message: string;
        if (this.isHezhiUsed) {
            message = "这个文档被盒知主程序使用，不能删除。";
        }
        if (this.canDelete) {
            message = `是否删除这个${this.node.type === "folder" ? "文件夹" : "文档"}？`;
        } else {
            message = this.cannotDeleteMessage;
        }
        return ModalUtils.title({
            text: message,
            type: "stop",
            color: "#f5222d",
        });
    }

    public render() {
        // 读取结束之后，再渲染内容。
        if (!this.isLoaded) {
            return null;
        }
        return (
            <Modal
                afterClose={this.props.afterClose}
                title={this.buildTitle()}
                visible={this.props.visible}
                closable={false}
                width={900}
                footer={null}
                centered={true}
            // wrapClassName="TitleColor"
            >
                <Container>
                    <NodeInfoView node={this.node} />
                    <FormItem style={{ textAlign: "right", marginTop: 20 }}>
                        {(this.canDelete && !this.isHezhiUsed) ?
                            <>
                                <Button onClick={() => this.props.onCancelled()}>取消</Button>
                                <Button type="danger" onClick={() => this.delete()} style={{ marginLeft: 8 }}>确认删除</Button>
                            </>
                            :
                            <Button type="primary" onClick={() => this.props.onCancelled()}>我知道了</Button>
                        }
                    </FormItem>
                </Container>
            </Modal >
        );
    }

    public static async show(node: Node): Promise<boolean> {
        return new Promise<any>((resolve, reject) => {
            let container = document.createElement("div");
            document.body.appendChild(container);

            let dispose = (success: boolean) => {
                ReactDOM.render(
                    <NodeDeleteModal
                        node={node}
                        visible={false}
                        onSubmitted={() => { }}
                        onCancelled={() => { }}
                        afterClose={() => {
                            ReactDOM.unmountComponentAtNode(container);
                            document.body.removeChild(container);
                            resolve(success);
                        }}
                    />,
                    container);
            };

            ReactDOM.render(
                <NodeDeleteModal
                    node={node}
                    visible={true}
                    onSubmitted={() => { dispose(true); }}
                    onCancelled={() => { dispose(false); }}
                />,
                container);
        });
    }
}

const Container = styled.div`
    .ant-form-item:last-child {
        margin-bottom: 0;
    }
    /* .ant-modal-title{
        color: red;
    } */
`;

// injectGlobal`
//     .TitleColor {
//         .ant-modal-title {
//             color:#f5222d;
//         }
//     }
// `;