import { Button, Form, Modal } from "antd";
import _ from "lodash";
import * as mobx from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

import Doc from "../../../models/Doc";
import Node from "../../../models/Node";
import BaseComponent from "../../BaseComponent";

import ModalUtils from "./ModalUtils";
import NodeInfoView from "./NodeInfoView";
interface IProps {
    node: Node;
    visible: boolean;
    onSubmitted?: () => void;
    onCancelled?: () => void;
    afterClose?: () => void;
}

const FormItem = Form.Item;
@observer
export default class NodeInfoModal extends BaseComponent<IProps, any> {

    @mobx.computed
    public get node() { return this.props.node; }

    public componentDidMount() {
    }

    public render() {
        return (
            <Modal
                title={ModalUtils.title({
                    text: this.node.name,
                    type: "info-circle",
                    color: "#1890ff",
                })}
                visible={this.props.visible}
                closable={false}
                width={900}
                footer={null}
                centered={true}
                afterClose={this.props.afterClose}
            >
                <Container>
                    <NodeInfoView
                        node={this.node}
                    />
                    <FormItem style={{ textAlign: "right", marginTop: 20 }}>
                        <Button type="primary" onClick={() => { this.props.onCancelled(); }} >确认</Button>
                    </FormItem>
                </Container>
            </Modal >
        );
    }

    public static async show(node: Node): Promise<boolean> {
        return new Promise<any>((resolve, reject) => {
            let container = document.createElement("div");
            document.body.appendChild(container);

            let dispose = (success: boolean) => {
                ReactDOM.render(
                    <NodeInfoModal
                        node={node}
                        visible={false}
                        onSubmitted={() => { }}
                        onCancelled={() => { }}
                        afterClose={() => {
                            ReactDOM.unmountComponentAtNode(container);
                            document.body.removeChild(container);
                            resolve(success);
                        }}
                    />,
                    container);
            };

            ReactDOM.render(
                <NodeInfoModal
                    node={node}
                    visible={true}
                    onSubmitted={() => { dispose(true); }}
                    onCancelled={() => { dispose(false); }}
                />,
                container);
        });
    }
}

const Container = styled.div`
    .ant-form-item:last-child {
        margin-bottom: 0;
    }
`;