import * as React from "react";
import { RouteComponentProps } from "react-router-dom";

import Store from "../models/Store";

import Base from "./BaseComponent";

export default abstract class RouterBase<T extends RouteComponentProps<any>> extends Base<T, {}> {

    public get history() { return this.props.history; }

    public get location() { return this.props.location; }

    public get match() { return this.props.match; }
}