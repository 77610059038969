import _ from "lodash";
import * as mobx from "mobx";

const LOCALSTORAGE_KEY = "Preferences";

/**
 * 首选项类。与 Settings 不同，Preferences 是基于 LocalStorage 存储在本地的。
 */
export default class Preferences {

    public get nodeViewMode() { return this.$nodeViewMode; }
    @mobx.observable
    private $nodeViewMode: "card" | "list" = "card";

    /**
     * 登录方式
     */
    public get loginType() { return this.$loginType; }
    @mobx.observable
    private $loginType: string = "default";

    public get openInNewWindow() { return this.$openInNewWindow; }
    @mobx.observable
    private $openInNewWindow: boolean = true;

    public get editWindow() { return this.$editWindow; }
    @mobx.observable
    private $editWindow: boolean = true;

    public get showNodeIcon() { return this.$showNodeIcon; }
    @mobx.observable
    private $showNodeIcon: boolean = true;

    /**
     * 是否在资源节点信息中显示 meta 代码。默认 false。
     */
    public get showMetaCode() { return this.$showMetaCode; }
    @mobx.observable
    private $showMetaCode: boolean = false;

    constructor() {
        this.load();
    }

    public setLoginType(value: string) {
        this.$loginType = value;
        this.save();

        return this;
    }

    public setNodeViewMode(value: "card" | "list") {
        this.$nodeViewMode = value;
        this.save();

        return this;
    }

    public setOpenInNewWindow(value: boolean) {
        this.$openInNewWindow = value;
        this.save();

        return this;
    }

    public setEditWindow(value: boolean) {
        this.$editWindow = value;
        this.save();

        return this;
    }

    public setShowNodeIcon(value: boolean) {
        this.$showNodeIcon = value;
        this.save();

        return this;
    }

    public setShowMetaCode(value: boolean) {
        this.$showMetaCode = value;
        this.save();

        return this;
    }

    private save() {
        let jsonText = JSON.stringify({
            nodeViewMode: this.nodeViewMode,
            openInNewWindow: this.openInNewWindow,
            editWindow: this.editWindow,
            loginType: this.loginType,
            showNodeIcon: this.showNodeIcon,
            showMetaCode: this.showMetaCode,
        });
        window.localStorage.setItem(LOCALSTORAGE_KEY, jsonText);
    }

    private load() {
        let jsonText = window.localStorage.getItem(LOCALSTORAGE_KEY) || "{}";
        let json = JSON.parse(jsonText);

        this.$nodeViewMode = this.getValueOrDefault(json.nodeViewMode, "card");
        this.$openInNewWindow = this.getValueOrDefault(json.openInNewWindow, true);
        this.$editWindow = this.getValueOrDefault(json.editWindow, true);
        this.$loginType = this.getValueOrDefault(json.loginType, "default");
        this.$showNodeIcon = this.getValueOrDefault(json.showNodeIcon, true);
        this.$showMetaCode = this.getValueOrDefault(json.showMetaCode, false);
    }

    private getValueOrDefault<T>(valueObject: any, defaultValue: T) {
        if (valueObject === undefined || valueObject === null) {
            return defaultValue;
        }
        return valueObject as T;
    }
}