import { Alert, Button, Checkbox, Col, Form, Input, Modal, Row } from "antd";
import * as mobx from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import v8n from "v8n";

import UserGroup from "../../models/users/UserGroup";
import UserManager from "../../models/users/UserManager";
import BaseComponent from "../BaseComponent";

interface IProps {
    userManager: UserManager;
    userGroup?: UserGroup;
    mode: "create" | "update";
    visible: boolean;
    onCancelled(): void;
}
const FormItem = Form.Item;

@observer
export default class UserGroupEditModal extends BaseComponent<IProps, any> {

    @mobx.observable
    private name: string = "";

    @mobx.observable
    private userIds: string[] = null;

    @mobx.computed
    private get isUpdatingMode() { return this.props.mode === "update"; }

    private get userManager() { return this.props.userManager; }

    private get userGroup() {return this.props.userGroup; }

    @mobx.computed
    private get isCreatingMode() { return !this.isUpdatingMode; }

    @mobx.observable
    private isUsergroupsCorrect: boolean = true;
    @mobx.observable
    private isLoaded: boolean = false;
    public componentDidMount() {
        !this.isCreatingMode &&
        this.run("读取用户列表", async () => {
            await this.userManager.listUser();
            this.isLoaded = true;
            this.name = this.userGroup.name;
            let listUserIds = this.userGroup.listUserIds();
            listUserIds.then((result) => {this.userIds = result; });
        });
    }

    private handleSubmit() {
        if (this.name || this.isCreatingMode || !this.name) {
            this.isUsergroupsCorrect = v8n().string().minLength(1).maxLength(20).test(this.name);
        } else {
            this.isUsergroupsCorrect = true;
        }

        if (this.isCreatingMode && this.isUsergroupsCorrect) {
            this.run("增加用户组", async () => {
                await this.userManager.createUserGroup(this.name);
                await this.props.onCancelled();
            });
        }
        if (this.isUpdatingMode && this.onUsersChanged && this.isUsergroupsCorrect) {
            this.run("增加修改用户组与成员", async () => {
                await this.props.userGroup.updateName(this.name);
                await this.props.userGroup.updateUsers(this.userIds);
                this.props.onCancelled();
            });
        }

    }

    private onUsersChanged(checkedValues: string[]) {
        this.userIds = checkedValues;
    }

    public render() {
        if (this.isUpdatingMode && this.props.userGroup === null && !this.isLoaded) {
            return null;
        }
        return (
            <div>
                <Modal
                    title={this.props.userGroup ? this.props.userGroup.name : "新增用户组"}
                    visible={this.props.visible}
                    footer={null}
                    closable={false}
                    centered={true}
                >
                    <Container>
                        <Form onSubmit={(evt) => { evt.preventDefault(); this.handleSubmit(); }}>
                            {this.isCreatingMode ?
                                <FormItem {...formItemLayout}
                                    validateStatus={this.isUsergroupsCorrect ? "success" : "error"}
                                    help="用户组不能为空,不允许带有特殊字符\/:*?/<>|"
                                    label="用户组名称">
                                    <Input
                                        autoFocus={true}
                                        pattern={`^[^\\\\/:*?"<>|]+$`}
                                        maxLength={20}
                                        value={this.name}
                                        onChange={(evt) => this.name = evt.target.value} />
                                </FormItem>
                                :
                                [
                                    <FormItem key="form" {...formItemLayout}
                                        validateStatus={this.isUsergroupsCorrect ? "success" : "error"}
                                        help="用户组不能为空,不允许带有特殊字符\/:*?/<>|"
                                        label="用户组名称">
                                        <Input
                                            autoFocus={true}
                                            pattern={`^[^\\\\/:*?"<>|]+$`}
                                            maxLength={20}
                                            value={this.name}
                                            onChange={(evt) => this.name = evt.target.value} />
                                    </FormItem>,
                                    <Alert showIcon={false} key="alert" message="勾选你需要增加的成员加入小组" type="success" banner />,
                                    <Checkbox.Group
                                        key="group"
                                        style={{ width: "100%", margin: 15 }}
                                        value={this.userIds}
                                        onChange={(evt) => this.onUsersChanged(evt as string[])}>
                                        <Row>
                                            {
                                                this.userManager.users &&
                                                this.userManager.users.map((item) => {
                                                        return (
                                                            <Col key={item.id} span={12} style={{ marginBottom: 10 }}>
                                                                <Checkbox key={item.id} value={item.id}>
                                                                    <span>{item.displayName ? item.displayName : item.name}</span>
                                                                    <span className="username">( {item.name} )</span>
                                                                </Checkbox>
                                                            </Col>
                                                        );
                                                    })
                                            }
                                        </Row>
                                    </Checkbox.Group>,
                                ]
                            }
                            <FormItem style={{ textAlign: "right" }}>
                                <Button onClick={() => { this.props.onCancelled(); }}>取消</Button>
                                <Button type="primary" htmlType="submit" style={{ marginLeft: 8 }}>确认</Button>
                            </FormItem>

                        </Form>
                    </Container>
                </Modal>
            </div>
        );
    }
    public static async show(userManager: UserManager, userGroup: UserGroup, mode): Promise<boolean> {

        return new Promise<any>((resolve, reject) => {
            let container = document.createElement("div");
            document.body.appendChild(container);

            let dispose = (success: boolean) => {
                ReactDOM.render(
                    <UserGroupEditModal
                        userManager={userManager}
                        userGroup={userGroup}
                        visible={false}
                        mode={mode}
                        onCancelled={() => { }}
                    />,
                    container);
                setTimeout(() => {
                    ReactDOM.unmountComponentAtNode(container);
                    document.body.removeChild(container);
                }, 300);
                resolve(success);
            };

            ReactDOM.render(
                <UserGroupEditModal
                    userManager={userManager}
                    userGroup={userGroup}
                    visible={true}
                    mode={mode}
                    onCancelled={() => {
                        dispose(false);
                    }}
                />,
                container);
        });
    }
}
const formItemLayout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 19 },
};
const Container = styled.div`
    .ant-form-item:last-child {
        margin-bottom: 0;
    }
    .username{
        margin-left:10px;
        color:#666;
        font-size:13px;
    }
`;