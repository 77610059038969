import axios, { AxiosRequestConfig } from "axios";
import jwtDecoder from "jwt-decode";
import _ from "lodash";
import moment from "moment";

const StorageKey = "BLADE_TOKEN";

export class Service {
    private readonly restClient = axios.create();

    public get token() {
        return this.$token;
    }
    private $token: string = null;

    public get userId() {
        return this.$userId;
    }
    private $userId: string = null;

    public get username() {
        return this.$username;
    }
    private $username: string = null;

    public get roles() {
        return this.$roles;
    }
    private $roles: string[] = null;

    public get password() {
        return this.$password;
    }
    private $password: string = null;

    public get provider() {
        return this.$provider;
    }
    private $provider: string = null;

    public get tokenExpiredAt() {
        return this.$tokenExpiredAt;
    }
    private $tokenExpiredAt: Date = null;

    /**
     * 获取当前 token 是否过期。
     */
    public get isTokenExpired() {
        if (this.tokenExpiredAt) {
            return this.tokenExpiredAt.getTime() < Date.now();
        }
        return true;
    }

    public constructor() {
        // 从localStorage里面读取信息
        let storage = JSON.parse(localStorage.getItem(StorageKey) || "{}");

        this.$token = storage.token || null;
        this.$password = storage.password || null;
        this.$provider = storage.provider || null;

        this.parseToken();

        this.restClient.interceptors.response.use(
            async (res) => {
                if (res.status === 200 && (res.data ? !res.data.error : true)) {
                    return res;
                } else {
                    this.onError(res.data.status);
                }
                return res;
            },
            async (error) => {
                this.onError(error.response.status);
            },
        );
    }

    /**
     * 尝试自动登录。
     */
    public async tryAutoLogin() {
        if (this.password) {
            if (this.isTokenExpired) {
                try {
                    await this.createToken(
                        this.username,
                        this.password,
                        this.provider,
                    );
                    return true;
                } catch (err) {
                    return false;
                }
            } else {
                return true;
            }
        }
        return false;
    }

    /**
     * 用户登录。
     */
    public async createToken(
        username: string,
        password: string,
        provider: string = null,
    ) {
        /**
         * 登录单独调用。
         */
        let res = await this.restClient.post(`/apis/sessions`, {
            username: username,
            password: password,
            provider: provider,
        });

        this.$token = res.data.data ? res.data.data.token : null;

        this.parseToken();

        localStorage.setItem(
            StorageKey,
            JSON.stringify({
                token: this.token,
                user_id: this.userId,
                username: this.username,
                password: password,
                provider: provider,
            }),
        );

        return res.data.data;
    }

    /**
     * 用户注销。
     */
    public async deleteToken() {
        localStorage.removeItem(StorageKey);

        this.$token = null;
        this.$userId = null;
        this.$username = null;
        this.$password = null;
        this.$provider = null;
        this.$tokenExpiredAt = null;
    }
    public get timer() {
        return new Date().getTime();
    }

    /**
     * 加载权限树
     */
    public async loadPermission() {
        let res = await axios.get(`./permission.json?t=${this.timer}`);
        return res.data;
    }

    /**
     * 获取已有权限
     */
    public async getPermissionList(id: string) {
        let res = await this.get(`/apis/UnitMenu/${id}`);
        return res;
    }

    /**
     * 获取所有用户权限
     */
    public async getAllPermissionList(path: string, params: any = null): Promise<any> {
        // let res = await this.get(`/apis/UnitMenu/ByUserId`);
        // return res;
        let res = await axios({
            method: "get",
            params: params,
            url: path,
            headers: {
                Authorization: `Bearer ${this.token}`,
            },
        });

        return res.data;
    }

    /**
     * 设置用户权限
     */
    public async setPermission(id: string, permission: string[]) {
        let data = await this.post(`/apis/UnitMenu`, {
            unit: id,
            menus: permission,
        });
        return data;
    }

    /**
     * 更新用户权限
     */
    public async updatePermission(id: string, permission: string[]) {
        let data = await this.put(`/apis/UnitMenu/${id}`, {
            unit: id,
            menus: permission,
        });
        return data;
    }

    /**
     * 获取资源节点的信息。包括子结点，和父节点。
     * 根据 ID 获取，根据路径获取，以及根据数据存储位置获取，虽然调用的一个 URL 地址，但只能三选一。
     * @param nodeId 资源节点 ID
     */
    public async readNodeById(
        nodeId: string,
        request: IReadNodeRequest = null,
    ) {
        nodeId = nodeId || "00000000-0000-0000-0000-000000000010";

        let data = (await this.get(
            `/apis/resources/${nodeId}`,
            request,
        )) as INodeDetail;

        // 移除根目录
        if (data.parents) {
            data.parents.pop();
        }

        return data;
    }

    /**
     * 根据节点路径查询。例如 "文档库/产品库/上线产品/SPU-001"。
     * 每层文件夹用“/”分割。
     * 根据 ID 获取，根据路径获取，以及根据数据存储位置获取，虽然调用的一个 URL 地址，但只能三选一。
     * @param path 资源节点路径
     */
    public async readNodeByPath(
        path: string,
        request: IReadNodeRequest = null,
    ) {
        let data = (await this.get(`/apis/resources`, {
            ...request,
            path: path,
        })) as INodeDetail;

        // 移除根目录
        if (data.parents) {
            data.parents.pop();
        }

        return data;
    }

    /**
     * 根据节点的数据存储路径查询。
     * 根据 ID 获取，根据路径获取，以及根据数据存储位置获取，虽然调用的一个 URL 地址，但只能三选一。
     * @param dataUrl 资源节点数据存储路径
     */
    public async readNodeByData(
        dataUrl: string,
        request: IReadNodeRequest = null,
    ) {
        let data = (await this.get(`/apis/resources`, {
            ...request,
            data: dataUrl,
        })) as INodeDetail;

        // 移除根目录
        if (data.parents) {
            data.parents.pop();
        }

        return data;
    }

    /**
     * 搜索获取根目录以下所有的节点。
     */
    public async searchNodes(request: ISearchNodesRequest) {
        let data = await this.get(
            `/apis/resources/00000000-0000-0000-0000-000000000010/decents`,
            request,
        );

        return data as IPagedNodeList;
    }

    /**
     * 创建一个资源节点。
     */
    public async createNode(request: ICreateNodeRequest) {
        let url = request.source
            ? `/apis/resources?source=${request.source}`
            : `/apis/resources`;

        request.source = null;

        let data = await this.post(url, request);

        return data as INode;
    }

    /**
     * 删除一个资源节点。
     */
    public async deleteNode(nodeId: string) {
        let data = await this.delete(`/apis/resources/${nodeId}`);

        return data as INode;
    }
    /**
     * 删除用户
     */
    public async deleteUserId(userId: string) {
        let data = await this.delete(`/apis/users/${userId}`);
        return data;
    }
    /**
     * 删除用户组
     */
    public async deleteUserGroup(groupId: string) {
        let data = await this.delete(`/apis/usergroups/${groupId}`);
        return data;
    }
    /**
     * 更新一个资源节点。
     */
    public async updateNode(nodeId: string, request: IUpdateNodeRequest) {
        let data = await this.put(`/apis/resources/${nodeId}`, request);

        return data as INode;
    }
    /**
     * 更新文档
     * @param id 文档节点 ID
     */
    public async updateDoc(id: string, request) {
        let data = await this.put(`/apis/resources/${id}`, request);
        console.log("更新文档成功");

        return data as INodeDetail;
    }
    /**
     * 更新密码(用户自己修改密码)
     */
    public async updateSelfPassword(oldPassword: string, newPassword: string) {
        let data = await this.put(`/apis/account/password`, {
            old_password: oldPassword,
            new_password: newPassword,
        });
        return data;
    }
    /**
     * 更新文件夹
     * @param id 文件夹ID
     */
    public async updateFolder(id: string, request) {
        let data = await this.put(`/apis/resources/${id}`, request);
        return data;
    }
    /**
     * 更改文件权限
     */
    public async updateFolderPower(id: string, permissions) {
        let data = await this.put(`/apis/permissions/${id}`, permissions);
        return data;
    }
    /**
     * 更改用户密码（管理员更改）
     * @param id 用户ID
     */
    public async updateUserPassword(id: string, password: string) {
        let data = await this.put(`/apis/users/${id}`, {
            password: password,
        });
        return data;
    }
    /**
     * 更改用户信息（管理员更改）
     * @param id 用户ID
     * @param roles 用户角色
     * @param userGroups 用户组
     * @param displayName 显示名称
     */
    public async updateUserInfo(
        id: string,
        params: {
            roles?: string[];
            userGroups?: string[];
            password?: string;
            displayName?: string;
        },
    ) {
        let data = await this.put(`/apis/users/${id}`, {
            roles: params.roles,
            groups: params.userGroups, // values.userGroups.map((userGroup) => userGroup.id),
            password: params.password,
            display_name: params.displayName,
        });
        return data;
    }

    /**
     * 更改用户组
     * @param id 用户组 ID
     * @param params 用户组參數
     */
    public async updateUserGroup(id: string, name: string) {
        let data = await this.put(`/apis/usergroups/${id}`, { name: name });
        return data;
    }
    public async updateUserGroupUsers(id: string, users: string[]) {
        let data = await this.put(`/apis/usergroups/${id}/users`, {
            users: users,
        });

        return data;
    }

    /**
     *  添加新用户
     */
    public async createUser(
        name: string,
        password: string,
        roles: string[],
        groups: string[],
        displayName?: string,
    ) {
        let data = await this.post(`/apis/users`, {
            name: name,
            password: password,
            roles: roles,
            groups: groups,
            display_name: displayName,
        });
        return data;
    }
    /**
     *  添加新用户组
     */
    public async createUserGroup(name: string) {
        let data = await this.post(`/apis/usergroups`, {
            name: name,
        });
        return data;
    }
    /**
     * 获取资源的 data 数据，以 zip 的形式打包。
     */
    public async readNodeZip(nodeId: string) {
        let data = await this.download(`/apis/resources/${nodeId}/data.zip`);

        return data as Blob;
    }

    /**
     * 更新节点的 data 数据，zip 文件会完整的覆盖现有的 data 目录。
     * @param nodeId 节点 ID
     * @param zipFile 打包的 zip 文件
     */
    public async updateNodeDataByZip(nodeId: string, zipFile: File) {
        let form = new FormData();
        form.append("file", zipFile);

        let data = await this.put(`/apis/resources/${nodeId}/data.zip`, form);

        return data;
    }

    /**
     * 获取用户组所有用户ID
     * @param groupId 用户组ID
     */
    public async listUsersInGroup(groupId: string) {
        let data = await this.get(`/apis/usergroups/${groupId}/users`);
        return data;
    }

    /**
     * 获取文档基础数据
     * @param docId 文档ID
     */
    public async readDoc(docId: string) {
        let data = await this.get(`/apis/resources/${docId}`);
        return data;
    }

    /**
     * 获取文档引用信息
     * @param docId 文档ID
     */
    public async listDocBeReferenced(docId: string) {
        let data = await this.get(`/apis/resources/${docId}/referenced`);
        return data;
    }

    /**
     * 获取文档权限信息
     * @param id 文档ID
     */
    public async readDocPermissions(id: string) {
        let data = await this.get(`/apis/permissions/${id}`);
        return data;
    }

    /**
     * 获取文件夹基础数据
     * @param id 文件夹ID
     * @param  depth 深度
     */
    public async readFolder(
        id: string,
        depth: number,
        take: number = 1000,
        type: string = null,
    ) {
        let query = `skip=0&take=${take}&depth=${depth}`;
        if (type) {
            query += `&type=${type}`;
        }
        let data = await this.get(`/apis/resources/${id}?${query}`);
        return data;
    }

    /**
     * 获取文件夹权限信息
     * @param folderId 文件夹ID
     */
    public async listFolderPermissions(folderId: string) {
        let data = await this.get(`/apis/permissions/${folderId}`);
        return data;
    }

    /**
     * 获取系统中的所有用户。
     */
    public async listUsers() {
        let data = await this.get(`/apis/users`, {
            skip: 0,
            take: 1000,
        });
        return data;
    }
    /**
     * 获取用户组信息
     */
    public async listUserGroups() {
        let data = await this.get(`/apis/usergroups`, {
            skip: 0,
            take: 1000,
        });
        return data;
    }
    /**
     * 获取listactivites
     */
    public async listActivities(request: {
        skip: number;
        take: number;
        query: string;
    }) {
        let data = await this.get("/apis/activities", request);
        return data;
    }

    /**
     * 下载数据，接收 Blob 返回值。
     */
    public async download(path: string, params: any = null): Promise<Blob> {
        let res = await axios({
            method: "get",
            params: params,
            url: path,
            headers: {
                Authorization: `Bearer ${this.token}`,
            },
            responseType: "blob",
        });

        return res.data;
    }

    private async get(path: string, params: any = null): Promise<any> {
        return await this.execute({
            method: "get",
            params: params,
            url: path,
        });
    }

    private async post(path: string, body: object): Promise<any> {
        return await this.execute({
            method: "post",
            data: body,
            url: path,
        });
    }

    private async put(path: string, body: object): Promise<any> {
        return await this.execute({
            method: "put",
            data: body,
            url: path,
        });
    }

    private async delete(path: string): Promise<any> {
        return await this.execute({
            method: "delete",
            url: path,
        });
    }

    private async execute(options: AxiosRequestConfig): Promise<any> {
        /**
         * 如果过期，那就自动登录一下。
         */
        if (this.isTokenExpired && this.password) {
            await this.createToken(this.username, this.password, this.provider);
        }

        options.headers = {
            Authorization: `Bearer ${this.token}`,
        };

        let res = await this.restClient(options);

        return res.data.data;
    }

    private onError(status: number) {
        let error = new Error();
        switch (status) {
            case 401:
                error.message = `没有登录`;
                break;
            case 409:
                error.message = `名字冲突`;
                break;
            default:
                error.message = `Web服务出错：${status}`;
                break;
        }
        error.name = status.toString();
        throw error;
    }

    /**
     * 从 token 中解析用户ID、用户名等信息。
     */
    private parseToken() {
        if (this.token) {
            let jwt = jwtDecoder<{
                id: string;
                name: string;
                exp: number;
                role: string[] | string;
            }>(this.token);

            // console.log(jwt);

            this.$tokenExpiredAt = new Date(jwt.exp * 1000);
            this.$userId = jwt.id;
            this.$username = jwt.name;
            this.$roles = jwt.role instanceof Array ? jwt.role : [jwt.role];

            console.log(`当前 token 会在 ${moment(this.tokenExpiredAt).format("YYYY-MM-DD HH:mm:ss")} 过期`);
        }
    }
}

export interface IPagedRequest {
    skip?: number;
    take?: number;
}

export interface IPagedList<T> {
    skip: number;
    take: number;
    total: number;
    items: T[];
}

export interface ISearchNodesRequest extends IPagedRequest {
    keyword?: string;
    type?: string;
}

export interface IReadNodeRequest extends IPagedRequest {
    /**
     * 子结点的深度限定。
     */
    depth?: number;

    /**
     * 子结点的类型限定。
     */
    type?: string;

    /**
     * 查询条件。
     */
    query?: string;
}

export interface IPagedNodeList extends IPagedList<INodeDetail> { }

export interface INode {
    id: string;
    type: string;
    name: string;
    meta: any;
    data: string;
    created_at: string;
    updated_at: string;
    parent: string;
    thumbnail: string;
}

export interface INodeDetail extends INode {
    /**
     * 所有父节点。从当前节点向上遍历。
     */
    parents: INodeParent[];
    permissions: string[];
    children: IPagedList<INodeDetail>;
    references: INodeDetail[];
}

export interface INodeParent {
    id: string;
    type: string;
    name: string;
}

export interface ICreateNodeRequest {
    type: string;
    name: string;
    parent: string;
    source?: string;
    meta?: any;
}

export interface IUpdateNodeRequest {
    name?: string;
    meta?: object;
    data?: any;
    parent?: string;
    order?: number;
}
export default Service;
