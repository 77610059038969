import Service from "../../models/Service";
import Store from "../../models/Store";

import HezhiService from "./HezhiService";

export default class Hezhi {

    public static get instance() {
        if (this.$instance === null) {
            this.$instance = new Hezhi();
        }
        return this.$instance;
    }
    private static $instance: Hezhi = null;

    private static get store() { return (window as any).store as Store; }

    private readonly service: HezhiService = new HezhiService("");

    /**
     * 判断当前是否在盒知环境下运行。
     */
    public static get isRunning() {
        return this.store.config.authBasicProvider === "hezhi";
    }

    private constructor() {
        let bladeService = new Service();
        if (bladeService.username && bladeService.password) {
            this.service.username = bladeService.username;
            this.service.password = bladeService.password;
        } else {
            throw new Error(`还没有登录。`);
        }
    }

    /**
     * 检查这个文档是否被盒知主程序使用。
     * @param productId 产品文档的 data 属性。
     */
    public async checkProductUsage(productId: string): Promise<boolean> {
        try {
            await this.service.checkProductRel(productId, -1);
            return false;
        } catch (error) {
            return true;
        }
    }
}