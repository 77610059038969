import { Spin } from "antd";
import { observer } from "mobx-react";
import * as React from "react";
import styled from "styled-components";

import BaseComponent from "../BaseComponent";

@observer
export default class Cover extends BaseComponent<any, any> {

    public render() {
        if (!this.task.isRunning) {
            return null;
        }

        return (
            <Container className="Cover">
                <Spin className="Cover" spinning={this.task.isRunning} size="large" delay={200} />
            </Container>
        );
    }
}

const Container = styled.div`
    position: fixed;
    left:   0;
    top:    0;
    right:  0;
    bottom: 0;
    /* background-color: rgba(255, 255, 255, 0.8); */
    background-color: transparent;
    overflow: hidden;
    z-index: 5000;
    text-align: center;
    .ant-spin {
        position: absolute;
        left:       0;
        right:      0;
        width:      100%;
        top:        50%;
        margin-top: -29px;
    }
`;