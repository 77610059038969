import { Button, Col, Form, Input, message, Row } from "antd";
import * as mobx from "mobx";
import { observer } from "mobx-react";
import moment from "moment";
import * as React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import v8n from "v8n";

import RuleChecker from "../../utils/RuleChecker";
import VerticalCenter from "../common/VerticalCenter";
import RouterBase from "../RouterBase";

@observer
class HezhiWelcomeView extends RouterBase<any> {

    @mobx.observable
    private username: string = "";

    @mobx.observable
    private password: string = "";

    public componentDidMount() {
        if (this.store.account.isAuthenticated) {
            this.redirectToDefault();
        }
    }

    private submit() {
        if (!v8n().string().minLength(2).test(this.username)) {
            message.warning("请输入有效的用户名！");
            return;
        }
        if (!v8n().string().minLength(3).test(this.password)) {
            message.warning("请输入有效的密码！");
            return;
        }
        this.run("登录", async () => {
            try {
                await this.store.account.login(this.username, this.password);
                this.redirectToDefault();
            } catch (e) {
                message.warning("账号或密码错误！");
            } finally {

            }
        });
    }

    private redirectToDefault() {
        let query = new URLSearchParams(this.location.search);
        let callback = query.get("callback") || "/list";

        console.log(`redirectToDefault`, callback);
        this.history.push(callback);
    }

    public render() {
        return (
            <Container>
                <VerticalCenter>
                    <Content>
                        <Row>
                            <Col span={12}>
                                <img src="assets/hezhi/login_logo.png" alt=""/>
                            </Col>
                            <Col span={12} style={{ textAlign: "right" }}>
                                <img src="assets/hezhi/login_header_bg.png" alt="" style={{ marginRight: "34px" }} />
                            </Col>
                        </Row>
                        <img src="assets/hezhi/login_banner.jpg" alt="" style={{ width: "100%" }} />
                        <img src="assets/hezhi/login_content_bg.png" alt="" style={{ width: "100%" }} />
                        <p>Copyright © 2018 劲嘉盒知　版本：{moment(this.store.timestamp).format("YYYY-MM-DD")}</p>
                        <Register>
                            <Row>
                                <Col span={12} style={{ paddingBottom: "10px" }}>
                                    <a href="#" style={{ marginLeft: "36px", fontSize: "18px", color: "#000" }}>欢迎登录</a>
                                </Col>
                                <Col span={12} style={{ textAlign: "right", paddingBottom: "10px" }}>
                                    <a href="http://www.hezhiiot.com/" style={{ marginRight: "36px", fontSize: "18px", color: "#000" }}>首页</a>
                                </Col>
                            </Row>
                            <Form onSubmit={(evt) => {
                                evt.preventDefault();
                                evt.stopPropagation();
                                this.submit();
                            }}>
                                <Form.Item>
                                    <span>用户名</span>
                                    <Input size="large" value={this.username} onChange={(val) => this.username = val.target.value} />
                                </Form.Item>
                                <Form.Item>
                                    <span>密　码</span>
                                    <Input size="large" value={this.password} onChange={(val) => this.password = val.target.value} type="password" />
                                </Form.Item>
                                <Form.Item>
                                    <Button style={{ display: "block", width: "220px", marginLeft: "62px" }} type="primary" size="large" htmlType="submit">登　录</Button>
                                </Form.Item>
                            </Form>
                        </Register>
                    </Content>
                </VerticalCenter>
            </Container>
        );
    }
}
export default withRouter(HezhiWelcomeView);

const Container = styled.div`
    position: fixed;
    left:   0;
    top:    0;
    right:  0;
    bottom: 0;
    background-color: #fff;
    overflow: hidden;
    z-index: 1000;

    button.ant-btn-sm {
        line-height:1.5;
    }
    form {
        padding: 50px 36px;
        margin: 0 auto;
        background-color: #fff;
        border-radius: 0;
    }
    input {
        max-width: 220px;
        margin-left: 20px;
    }
`;

const Content = styled.div`
    position: relative;
    width: 1024px;
    margin: 0 auto;
    padding: 78px 0;
    text-align: left;
    P {
        border-top: solid 2px #ececec;
        padding-top: 10px;
        text-align: center;
        color: #555;
    }
`;

const Register = styled.div`
    position: absolute;
    top:    135px;
    right:  74px;
    width:  356px;
    height: 400px;
    background-color: #fff;
`;