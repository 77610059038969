import { Button, Form, Icon, Input, Modal } from "antd";
import * as mobx from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

import Folder from "../../../models/Folder";
import RuleChecker from "../../../utils/RuleChecker";
import BaseComponent from "../../BaseComponent";

import ModalUtils from "./ModalUtils";

const FormItem = Form.Item;

interface IProps {
    folder: Folder;
    visible: boolean;
    onSubmitted?: (result: IResultName) => void;
    onCancelled?: () => void;
    afterClose?: () => void;
}
interface IResultName {
    name: string;
}
@observer
export default class FolderCreateModal extends BaseComponent<IProps, any> {

    @mobx.observable
    private newName: string = "";

    @mobx.observable
    private isNameCorrect: boolean = true;

    @mobx.computed
    public get currentFolder(): Folder { return this.props.folder; }

    private handleSubmit() {
        this.isNameCorrect = RuleChecker.NameRule(this.newName);
        if (this.isNameCorrect && this.newName !== null) {
            this.props.onSubmitted({ name: this.newName });
        }
    }
    public render() {

        return (
            <Modal
                title={ModalUtils.title({
                    text: "新建文件夹",
                    type: "folder-add",
                })}
                visible={this.props.visible}
                closable={false}
                width={900}
                footer={null}
                centered={true}
                afterClose={() => this.props.afterClose()}
            >
                <Container >
                    <Form onSubmit={(evt) => { evt.preventDefault(); this.handleSubmit(); }}>
                        <FormItem {...FormItemLayout}
                            label="名称"
                            help="名字不能为空，且不能包含 \ / : * ? < > | ! 符号，长度为8-16位字符"
                            validateStatus={this.isNameCorrect ? "success" : "error"}
                        >
                            <Input
                                autoFocus={true}
                                value={this.newName}
                                onChange={(evt) => {
                                    this.newName = evt.target.value.replace(" ", "");
                                }} />
                        </FormItem>
                        <FormItem style={{ textAlign: "right", marginTop: 20 }}>
                            <Button onClick={() => { this.props.onCancelled(); }} >取消</Button>
                            <Button type="primary" htmlType="submit" style={{ marginLeft: 8 }} >确认</Button>
                        </FormItem>
                    </Form>
                </Container >
            </Modal >
        );
    }

    public static async show(folder: Folder): Promise<IResultName> {
        return new Promise<any>((resolve, reject) => {
            let container = document.createElement("div");
            document.body.appendChild(container);

            let dispose = (result: IResultName) => {
                ReactDOM.render(
                    <FolderCreateModal
                        visible={false}
                        folder={folder}
                        onSubmitted={() => { }}
                        onCancelled={() => { }}
                        afterClose={() => {
                            ReactDOM.unmountComponentAtNode(container);
                            document.body.removeChild(container);
                            resolve(result);
                        }}
                    />,
                    container);
            };

            ReactDOM.render(
                <FolderCreateModal
                    visible={true}
                    folder={folder}
                    onSubmitted={(result) => { dispose(result); }}
                    onCancelled={() => { dispose(null); }}
                />,
                container);
        });
    }
}

const Container = styled.div`
    .ant-form-item:last-child {
        margin-bottom: 0;
    }
`;
const FormItemLayout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 22 },
};