import _ from "lodash";
import * as mobx from "mobx";
import moment from "moment";

import UserManager from "./UserManager";

export default class UserGroup {

    public readonly userManager: UserManager = null;
    public get store() { return this.userManager.store; }
    public get service() { return this.store.service; }

    public get id() { return this.$id; }
    @mobx.observable
    private $id: string = null;

    // /**
    //  * 在当前用户组内的用户ID。
    //  */
    // @mobx.computed
    // public get userIds() { return this.$userIds; }
    // @mobx.observable
    // private $userIds: string[] = null;

    public get name() { return this.$name; }
    @mobx.observable
    private $name: string = null;

    public get createdAt() { return this.$createdAt; }
    @mobx.observable
    private $createdAt: moment.Moment = null;

    constructor(userManager: UserManager) {
        this.userManager = userManager;
    }

    /**
     * 获取用户组下的所有用户的 ID。
     */
    public async listUserIds() {
        let data = await this.service.listUsersInGroup(this.id);
        if (data.items) {
            let userIds = await data.items.map((item) => {
                return item.id;
            });
            return userIds;
        } else {
            let userIds = await data.map((item) => {
                return item.id;
            });
            return userIds;
        }
    }

    public async refresh() {
        // let data = await this.service.get(`/apis/usergroups/${this.id}/users`);
        // this.$userIds = await data.items.map((item) => {
        //     return item.id;
        // });
    }

    public async updateUsers(userIds: string[]) {
        await this.service.updateUserGroupUsers(this.id, userIds);
        await this.userManager.listGroup();

        return this;
    }

    public async updateName(name: string) {
        console.log(name);

        await this.service.updateUserGroup(this.id, name);

        return this;
    }

    public async delete() {
        await this.service.deleteUserGroup(this.id);

        await this.userManager.refresh();

        return this;
    }

    public static createByDataObject(userManager: UserManager, dataObject: any) {
        let userGroup = new UserGroup(userManager);
        userGroup.$id = dataObject.id;
        userGroup.$name = dataObject.name;
        userGroup.$createdAt = moment(dataObject.created_at);

        return userGroup;
    }

}