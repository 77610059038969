import { Row } from "antd";
import * as mobx from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import styled, { injectGlobal } from "styled-components";

import Folder from "../../models/Folder";
import Node from "../../models/Node";
import BaseComponent from "../BaseComponent";

import DocItemView from "./DocItemView";
import EventBus from "./EventBus";

/**
 * 文件模式排序组件
 * react-sortable-hoc
 * https://github.com/clauderic/react-sortable-hoc
 * @param SortableContainer sortable的容器
 * @param SortableElement sortable元素
 */

const SortableListContainer = styled.div`
    position : absolute;
    top:    0;
    left:   0;
    right:  0;
    bottom: 0;
    padding: 10px 10px 150px 10px;
    overflow-y:auto;
`;

const SortableList = SortableContainer((props: any) =>
    <SortableListContainer>
        <Row type="flex" justify="start" align="middle">
            {props.items.map((value: Node, index) => (
                value.canRead && <SortableItem disabled={props.readonly} key={`item-${index}`} hightlight={props.highlight} index={index} node={value}  {...props}
                    onSelect={() => {
                        props.onNodeSelected(value);
                    }} onCheck={() => {
                        props.onNodeChecked(value);
                    }} />
            ))}
        </Row>
    </SortableListContainer>,
);
const SortableItem = SortableElement((props: any) =>
    <DocItemView {...props} />,
);

interface IProps {
    onSortStart(node: Node): void;
    onSortEnd: void;
    onNodeSelected(node: Node): void;
    eventBus: EventBus;
    selectedNode: Node;
    viewType: string;
    children: Node[];
    folder: Folder;
    readonly: boolean;
    highlight: string;
    onNodeChecked(node: Node): void;
}

@observer
export default class DocCardModeView extends BaseComponent<IProps, any> {
    @mobx.computed
    public get highlight() {
        return this.props.highlight;
    }
    @mobx.computed
    public get children() {
        return this.props.children.slice();
    }

    @mobx.computed
    public get readonly() {
        return this.props.readonly;
    }
    public render() {
        return (
            <SortableList
                readonly={this.readonly}
                folder={this.props.folder}
                viewType={this.props.viewType} items={this.props.children} axis={"xy"} pressDelay={0} distance={5} helperClass={"sortable-active"}
                onSortStart={this.props.onSortStart}
                onSortEnd={this.props.onSortEnd}
                onNodeSelected={this.props.onNodeSelected}
                eventBus={this.props.eventBus}
                selectedNode={this.props.selectedNode}
                highlight={this.props.highlight}
                onNodeChecked={this.props.onNodeChecked}
            />
        );
    }
}