import * as mobx from "mobx";

import TypedEvent from "../TypedEvent";

import Store from "./Store";

/**
 * 账户（当前用户）的数据管理类。包括权限。
 */
export default class Account {

    public readonly store: Store;

    public get service() { return this.store.service; }

    /**
     * 用户身份是否被证实（用户名密码是否正确）。
     */
    public get isAuthenticated() { return this.$isAuthenticated; }
    @mobx.observable
    private $isAuthenticated: boolean = false;

    @mobx.observable
    private $PermissionList: [] = [];

    /**
     * 当前账号的权限
     */
    public get PermissionList() { return this.$PermissionList; }

    /**
     * 当前用户是否是管理员。
     */
    public get isAdmin() { return this.$isAdmin; }
    @mobx.observable
    private $isAdmin: boolean = false;

    /**
     * 当前用户是否是超级管理员。
     */
    public get isSuperAdmin() { return this.$isSuperAdmin; }
    @mobx.observable
    private $isSuperAdmin: boolean = false;

    public get userId() { return this.$userId; }
    @mobx.observable
    private $userId: string = null;

    public get username() { return this.$username; }
    @mobx.observable
    private $username: string = null;

    public get displayName() { return this.$displayName; }
    @mobx.observable
    private $displayName: string = null;

    public readonly loggedIn: TypedEvent<{}> = new TypedEvent();

    public readonly loggedOut: TypedEvent<{}> = new TypedEvent();

    constructor(store: Store) {
        this.store = store;
    }

    public async tryAutoLogin() {
        let isLoggedIn = await this.service.tryAutoLogin();

        if (isLoggedIn) {
            this.fetchUserDataFromService();
        }

        this.loggedIn.emit({});

        try {
            let res = await this.store.service.getPermissionList(this.store.account.userId);
            this.$PermissionList = res.menus;
        } catch (error) {

        }

    }

    /**
     * 用户登录。
     * @param username 用户名
     * @param password 密码
     */
    public async login(username: string, password: string) {
        await this.service.createToken(username, password, this.store.config.authBasicProvider);

        this.fetchUserDataFromService();

        this.loggedIn.emit({});

        return this;
    }

    /**
     * 用户注销。
     */
    public async logout() {

        await this.service.deleteToken();

        this.$isAuthenticated = false;

        this.loggedOut.emit({});

        return this;
    }

    /**
     * 当前用户，修改密码。
     * @returns 密码是否修改成功。
     */
    public async updatePassword(oldPassword: string, newPassword): Promise<boolean> {

        // 如果是第三方 Provider，那么就不允许从这里修改密码。

        if (this.store.config.authBasicProvider) {
            throw new Error(`第三方登录，不能在本系统修改密码。`);
        }

        // // 首先，验证旧密码是否正确。
        // try {
        //     await this.service.createToken(this.username, oldPassword);
        // } catch (error) {
        //     // 密码不正确。
        //     return false;
        // }

        try {
            // 再去修改新密码。
            await this.service.updateSelfPassword(oldPassword, newPassword);
        } catch (error) {
            return false;
        }

        // 考虑这里重新登录，因为要刷新 token
        await this.login(this.username, newPassword);

        return true;
    }

    private fetchUserDataFromService() {
        this.$isAdmin = this.service.roles.find((role) => role === "admin") && true;
        this.$isSuperAdmin = this.service.userId === "00000000-0000-0000-0000-000000000000";

        this.$username = this.service.username;
        this.$userId = this.service.userId;

        this.$isAuthenticated = this.service.token !== null;
    }
}