import { Alert, Button, Card, Checkbox, Col, Form, Modal, Radio, Row, Select, Switch, Table } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import _ from "lodash";
import * as mobx from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

import User from "../../..//models/users/User";
import UserGroup from "../../..//models/users/UserGroup";
import Folder, { IFolderPermission } from "../../../models/Folder";
import UserManager from "../../../models/users/UserManager";
import BaseComponent from "../../BaseComponent";

import ModalUtils from "./ModalUtils";
const FormItem = Form.Item;

interface IProps {
    folder: Folder;
    visible: boolean;
    onSubmitted?: () => void;
    onCancelled?: () => void;
    afterClose?: () => void;
}
@observer
export default class FolderPropertyModal extends BaseComponent<IProps, any> {

    @mobx.computed
    public get folder() { return this.props.folder; }

    @mobx.observable
    private readableUserIds: string[] = ["all"];

    @mobx.observable
    private userIds: string[] = [];

    @mobx.observable
    private userGroupIds: string[] = [];

    @mobx.observable
    private userManager: UserManager = null;

    @mobx.observable
    private userPermissions: IFolderPermission[] = [];

    @mobx.observable
    private folderPermissions: IFolderPermission[] = [];

    @mobx.computed
    private get folderPermissionsMap() {
        let map = {};
        this.folderPermissions.forEach((p) => {
            let originP = p.permissions.slice();
            if (_.isEqual(originP, ["read"])) {
                map[p.userId] = "R";
            } else if (_.isEqual(originP, ["read", "write"])) {
                map[p.userId] = "RW";
            } else {
                map[p.userId] = "";
            }
        });
        return map;
    }

    /**
     * 选中的严格类型。
     */
    @mobx.observable
    private selectedStrictType: string = null;

    @mobx.observable
    private isLoaded: boolean = false;

    public componentDidMount() {
        // this.userIds = this.folder.editors.map((item) => {
        //     return item.id;
        // });
        // this.userGroupIds = this.folder.editorGroups.map((item) => {
        //     return item.id;
        // });
        this.folderPermissions = this.folder.folderPermissions.map((item) => {
            return {
                userId: item.userId,
                permissions: item.permissions,
            };
        });
        this.selectedStrictType = this.folder.strictType;

        if (this.store.account.isAdmin) {
            // 加载用户管理类
            this.userManager = new UserManager(this.store);
            this.run(`读取用户信息`, async () => {
                await this.userManager.refresh();
                this.isLoaded = true;
            });
        }
    }

    private toggleSetAllUserReadable(evt: CheckboxChangeEvent) {
        if (evt.target.checked) {
            let users = this.userManager.users.map((user) => user.id);
            this.readableUserIds = users;
        } else {
            this.readableUserIds = [];
        }
    }

    private onReadableUsersChanged(checkedValues: string[]) {
        this.readableUserIds = checkedValues;
    }

    private onUsersChanged(checkedValues: string[]) {
        this.userIds = checkedValues;
    }

    private onUserGroupsChanged(checkedValues: string[]) {
        this.userGroupIds = checkedValues;
    }

    private onPermissionChanged(id: string, value: any) {
        let permissions = [];
        if (value === "R") {
            permissions = ["read"];
        } else if (value === "RW") {
            permissions = ["read", "write"];
        }
        _.remove(this.folderPermissions, (p) => p.userId === id);

        this.folderPermissions.push({
            userId: id,
            permissions: permissions,
        });
    }

    private getChecked(id: string) {
        let userPermission = this.folderPermissions.find((p) => p.userId === id);
        if (userPermission) {
            return userPermission.permissions;
        } else {
            return [];
        }
    }

    private handleSubmit() {
        this.run("更新文件夹属性", async () => {
            await this.props.folder.update({
                strictType: this.selectedStrictType,
                editorIds: this.folderPermissions,
            });
            await this.props.onSubmitted();
        });
    }

    public render() {
        // 读取结束之后，再渲染内容。
        if (!this.isLoaded) {
            return null;
        }

        return (
            <Modal
                title={ModalUtils.title({
                    text: "文件夹属性",
                    type: "file-text",
                })}
                visible={this.props.visible}
                onOk={this.props.onSubmitted}
                onCancel={this.props.onCancelled}
                closable={false}
                width={900}
                footer={null}
                centered={true}
                afterClose={this.props.afterClose}
            >
                <Container >
                    <Form onSubmit={(evt) => { evt.preventDefault(); this.handleSubmit(); }}>
                        <div style={{ paddingBottom: "20px" }}>
                            <Row gutter={10} type="flex" align="middle" style={{ height: 36 }}>
                                {/* <Col style={{ marginRight: 10 }}> */}
                                <span style={{ marginRight: 10 }}>严格模式：</span>
                                {/* <Switch checkedChildren="开" unCheckedChildren="关" defaultChecked={this.useStrictMode} onChange={(evt) => this.useStrictMode = evt} /> */}
                                {/* </Col> */}
                                <Col span={10}>
                                    <Select
                                        placeholder="选择一个文档类型"
                                        style={{ width: "200px" }}
                                        value={this.selectedStrictType}
                                        // disabled={!this.useStrictMode}
                                        onChange={(val) => this.selectedStrictType = val.toString()}
                                    >
                                        <Select.Option value={Folder.STRICT_TYPES_INHERITED}>
                                            继承父文件夹设置
                                        </Select.Option>
                                        <Select.Option value={Folder.STRICT_TYPES_ALL}>
                                            不使用严格模式
                                        </Select.Option>
                                        {/* <Select.OptGroup key="dynamic" label="表单类型">
                                            {this.store.models.templateModels.map((model) =>
                                                <Select.Option key={model.type} value={model.type}>
                                                    {model.name}
                                                </Select.Option>)}
                                        </Select.OptGroup> */}
                                        <Select.OptGroup key="static" label="限定类型">
                                            {this.store.models.pluginModels.map((model) =>
                                                <Select.Option key={model.type} value={model.type}>
                                                    {model.name}
                                                </Select.Option>)}
                                        </Select.OptGroup>
                                    </Select>
                                </Col>
                            </Row>
                            <Alert style={{ width: 390, marginTop: 10 }} showIcon={false} message="严格模式是指只允许在文件夹内创建一种类型文件" type="info" banner />
                        </div>
                        <Row style={{ marginTop: 20 }} gutter={16}>
                            <Col span={24}>
                                <p>用户组的权限设置</p>
                                <Table
                                    bordered
                                    rowKey="id"
                                    pagination={false}
                                    dataSource={this.userManager.userGroups}
                                >
                                    <Table.Column title="用户组" dataIndex="name"></Table.Column>
                                    <Table.Column key="action" title="权限" render={(text, record: UserGroup) => (
                                        <Radio.Group
                                            style={{ marginLeft: 10 }}
                                            defaultValue={this.folderPermissionsMap[record.id]}
                                            onChange={(evt) => this.onPermissionChanged(record.id, evt.target.value)}>
                                            <Radio value={""}>无</Radio>
                                            <Radio value={"R"}>只读</Radio>
                                            <Radio value={"RW"}>可读可写</Radio>
                                        </Radio.Group>
                                    )}>
                                    </Table.Column>
                                </Table>
                            </Col>
                            <Col span={24} style={{ marginTop: 20 }}>
                                <p>用户的权限设置</p>
                                <Table
                                    bordered
                                    pagination={false}
                                    rowKey="id"
                                    dataSource={this.userManager.users}
                                >
                                    <Table.Column key="name" title="用户" render={(text, record: User) => (
                                        <>
                                            <span>{record.displayName ? record.displayName : record.name}</span>
                                            <span className="username">( {record.name} )</span>
                                        </>
                                    )}>
                                    </Table.Column>
                                    <Table.Column key="action" title="权限" render={(text, record: User) => (<>
                                        <Radio.Group
                                            style={{ marginLeft: 10 }}
                                            defaultValue={this.folderPermissionsMap[record.id]}
                                            onChange={(evt) => this.onPermissionChanged(record.id, evt.target.value)}
                                        >
                                            <Radio value={""}>无</Radio>
                                            <Radio value={"R"}>只读</Radio>
                                            <Radio value={"RW"}>可读可写</Radio>
                                        </Radio.Group>
                                    </>)}>
                                    </Table.Column>
                                </Table>
                            </Col>
                        </Row>
                        <FormItem style={{ textAlign: "right", marginTop: 20 }}>
                            <Button onClick={() => { this.props.onCancelled(); }} >取消</Button>
                            <Button type="primary" htmlType="submit" style={{ marginLeft: 8 }} >确认</Button>
                        </FormItem>
                    </Form>
                </Container >
            </Modal >
        );
    }

    public static async show(folder: Folder) {
        return new Promise<any>((resolve, reject) => {
            let container = document.createElement("div");
            document.body.appendChild(container);

            let dispose = (success: boolean) => {
                ReactDOM.render(
                    <FolderPropertyModal
                        folder={folder}
                        visible={false}
                        onSubmitted={() => { }}
                        onCancelled={() => { }}
                        afterClose={() => {
                            ReactDOM.unmountComponentAtNode(container);
                            document.body.removeChild(container);
                            resolve(success);
                        }}
                    />,
                    container);
            };

            ReactDOM.render(
                <FolderPropertyModal
                    folder={folder}
                    visible={true}
                    onSubmitted={() => { dispose(true); }}
                    onCancelled={() => { dispose(false); }}
                />,
                container);
        });
    }
}

const Container = styled.div`
    .ant-form-item:last-child {
        margin-bottom: 0;
    }
    .username{
        margin-left:10px;
        color:#666;
        font-size:13px;
    }
`;