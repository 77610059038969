import * as mobx from "mobx";
import * as React from "react";
import { RouteComponentProps } from "react-router";

import RouterBase from "./RouterBase";

export default abstract class AuthoredComponent<T extends RouteComponentProps<any>> extends RouterBase<T> {

    public UNSAFE_componentWillMount() {
        if (this.store.account.isAuthenticated) {
        } else {
            this.redirectToWelcome();
        }
        this.store.account.loggedOut.on(() => this.redirectToWelcome());
    }

    private redirectToWelcome() {
        console.log(`redirectToWelcome from`, this.location.pathname);
        this.history.push(`/?callback=${encodeURIComponent(this.location.pathname)}`);
    }
}