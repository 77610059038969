import { Button } from "antd";
import * as mobx from "mobx";
import { observer } from "mobx-react";
import Postmate from "postmate";
import * as React from "react";
import ReactModal from "react-modal";
import styled, { createGlobalStyle } from "styled-components";

import Node from "../../models/Node";
import BaseComponent from "../BaseComponent";
import { JsNode, JsTree } from "../common/JsTree";

interface IProps {
    title?: string;
}
@observer
export default class OpenFileModal extends BaseComponent<IProps, any> {

    private treeContainer: HTMLElement = null;

    private tree: JsTree = null;

    private skipNodeId: string = null;

    @mobx.observable
    private selectedNode: JsNode = null;

    private postmateParent: any = null;

    /**
     * 节点的类型限定
     */
    private readonly type: string = null;

    /**
     * 根节点ID
     */
    private readonly rootNodeId: string = null;

    constructor(props) {
        super(props);
        let queryParam = new URLSearchParams(window.location.search);

        this.skipNodeId = queryParam.get("skip") || null;

        this.type = queryParam.get("type") || "folder";
        this.rootNodeId = queryParam.get("root") || "00000000-0000-0000-0000-000000000010";

        console.log(this.type, this.rootNodeId);
    }

    private async submit() {
        let nodeId = this.selectedNode.id;
        let data = await this.store.service.readNodeById(nodeId);
        this.postmateParent.emit("submit", JSON.stringify(data));
    }

    private async cancel() {
        this.postmateParent.emit("cancel");
    }

    private async notifyReady() {
        this.postmateParent = await new Postmate.Model({
            // Expose your model to the Parent. Property values may be functions, promises, or regular values
            // height: () => document.height || document.body.offsetHeight
        });

        this.postmateParent.emit("ready", "Hello, World!");
    }

    /**
     * 获取根节点以下所有的文件夹节点。
     */
    private async listNodes() {
        this.selectedNode = null;
        if (this.tree === null) {
            this.tree = new JsTree(this.treeContainer, {
                checkBox: false,
                contextMenu: false,
                keyboard: false,
                dragAndDrop: false,
            });
            this.tree.onNodeSelected = (node: JsNode) => {
                this.selectedNode = node;
            };
        }
        let rootData = await this.store.service.readNodeById(this.rootNodeId, {
            type: this.type,
            depth: 100,
        });
        console.log(rootData);
        let rootNode = this.parseNode(rootData);
        this.tree.root = rootNode;
        this.tree.refresh();

        this.tree.openAll(rootNode);

        this.notifyReady();
    }

    public parseNode(nodeData: any) {
        let node = new JsNode(this.tree)
            .setId(nodeData.id)
            .setText(nodeData.name || "根目录")
            .setType(nodeData.type || "folder")
            .setIcon(nodeData.type === "folder" ? "fas fa-fw fa-lg fa-folder" : "fas fa-fw fa-file");
        // if (nodeData.children && this.movingNode) {
        if (nodeData.children) {
            for (const item of nodeData.children.items) {
                if ((item.type === "folder" || item.type === this.type) && item.id !== this.skipNodeId) {
                    node.children.push(this.parseNode(item));
                }
            }
        }
        return node;
    }

    public render() {
        return (
            <>
                <GlobalStyle />
                <ReactModal
                    overlayClassName="move-file-modal"
                    style={CustomModalStyles}
                    isOpen={true}
                    onAfterOpen={this.listNodes.bind(this)}
                >
                    {/* <CloseButton onClick={() => this.props.onClosed()}><Icon type="close" /></CloseButton> */}
                    <Title>{this.props.title}</Title>
                    {/* <p>移动 <b>{this.movingNode && this.movingNode.name}</b> 至：{this.parentNode && this.parentNode.text}</p> */}
                    <TreeContainer innerRef={(el) => {
                        this.treeContainer = el;
                    }} />
                    <ButtonPanel>
                        <Button type="default" size="large" onClick={this.cancel.bind(this)}>取&nbsp;&nbsp;消</Button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button type="primary" size="large" disabled={this.selectedNode === null} onClick={this.submit.bind(this)}>确&nbsp;&nbsp;定</Button>
                    </ButtonPanel>
                </ReactModal>
            </>
        );
    }
}

const TreeContainer = styled.div`
    position: absolute;
    top:     50px;
    left:    20px;
    right:   20px;
    bottom:  80px;
    border: 1px solid #ececec;
    overflow: auto;
    i.jstree-icon.fa-folder {
        color: #FBCD22;
    }
    i.jstree-icon.fa-file {
        color: #20A0FF;
    }
`;

const Title = styled.div`
    /* line-height: 35px; */
    margin-bottom: 20px;
`;

const ButtonPanel = styled.div`
    position: absolute;
    left:    0;
    right:   0;
    bottom:  20px;
    text-align: center;
`;

const GlobalStyle = createGlobalStyle`
    html, body {
        /* background-color: transparent; */
        background-color: transparent !important;
    }
    .move-file-modal {
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;
        background-color: rgba(0, 0, 0, .5);
    }
`;

const CustomModalStyles = {
    content: {
        position: "absolute",
        top: "20%",
        left: "20%",
        right: "20%",
        bottom: "20%",
        // right: "auto",
        // bottom: "auto",
        // width:  600,
        // height: 600,
        // transform: "translate(-50%, -50%)",
        backgroundColor: "#fff",
        borderRadius: 0,
        border: "none",
    },
};