import { Spin } from "antd";
import * as mobx from "mobx";
import { observer } from "mobx-react";
import Postmate from "postmate";
import * as React from "react";
import * as ReactDOM from "react-dom";
import styled from "styled-components";

declare const __IS_DEBUG__: boolean;

interface IProps {
    link: string;
    onReady?(postmateChild: any): void;
}
/**
 * 这个类用于封装系统的 COM 组件。让调用者能够方便的使用 COM 组件，而不用反复封装 iframe 和相关的通信。
 * 同时，这个类会提供快捷方法，让调用者能够以函数的形式获取 COM 组件的返回结果，不用使用 Component 的形式，这样更加优雅。
 * 所有 COM 组件的使用，统一以这个类为准（Facade），哪个插件调用，把这个文件完整的复制走就行了。这个组件的依赖已经很小了。
 */
@observer
export default class COMWrapper extends React.Component<IProps, any> {

    private container: HTMLElement = null;

    private postmateChild: any = null;

    @mobx.observable
    private isLoading: boolean = false;

    public componentDidMount() {
        this.createContent();
    }

    public componentWillUnmount() {
        this.postmateChild.destroy();
        console.log("destroy COM wrapper");
    }

    private async createContent() {
        return new Promise(async (resolve, reject) => {
            this.isLoading = true;
            // https://github.com/dollarshaveclub/postmate
            this.postmateChild = await new Postmate({
                container: this.container, // Element to inject frame into
                url: this.props.link, // Page to load, must have postmate.js. This will also be the origin used for communication.
            });
            this.postmateChild.on("ready", (data) => {
                this.props.onReady && this.props.onReady(this.postmateChild);
                this.isLoading = false;
                resolve();
            });
            // Fetch the height property in child.html and set it to the iFrames height
            // child.get("height")
            //     .then((height) => child.frame.style.height = `${height}px`);

            // // Listen to a particular event from the child
            // child.on("some-event", (data) => console.log(data)); // Logs "Hello, World!"
        });
    }

    public render() {
        return <Container innerRef={(el) => this.container = el}>
            {/*
                TODO: 这里很奇怪，如果启用 Spin，会造成编译错误："- ua-parser-js: imported at node_modules\_fbjs@0.8.17@fbjs\lib\UserAgentData.js"
                估计是 AntDesign 的错误，可能是和 TypeScript 在某个版本范围冲突了。至于为啥其他地方没有冲突，我也不知道。
                等以后升级了再说。
             */}

            <Spin className="Cover" spinning={this.isLoading} size="large" />
        </Container>;
    }

    /**
     * 选择一个目录。
     * @param skipFolderId 跳过哪个目录。例如做移动的时候，当前目录是需要跳过的。
     */
    public static openFolderDialog(skipFolderId: string = null): any {
        return new Promise<any>((resolve, reject) => {
            let container = document.createElement("div");
            container.id = "openFileDialog";
            document.body.appendChild(container);
            let dispose = () => {
                ReactDOM.unmountComponentAtNode(container);
                document.body.removeChild(container);
            };
            let query = new URLSearchParams();
            query.set("id", "OpenFileModal");
            query.set("type", "folder");
            query.set("root", "00000000-0000-0000-0000-000000000010");
            query.set("skip", skipFolderId);
            console.log(query.toString());
            ReactDOM.render(<COMWrapper link={`${this.buildLink()}/com?${query}`} onReady={(postmateChild) => {
                postmateChild.on("submit", (node: string) => {
                    dispose();
                    resolve(JSON.parse(node));
                });
                postmateChild.on("cancel", () => {
                    dispose();
                    resolve(null);
                });
            }} />, container);
        });
    }

    public static openFileDialog(type: string, rootNodeId: string): any {
        return new Promise<any>((resolve, reject) => {
            let container = document.createElement("div");
            container.id = "openFileDialog";
            document.body.appendChild(container);
            let dispose = () => {
                ReactDOM.unmountComponentAtNode(container);
                document.body.removeChild(container);
            };
            let query = new URLSearchParams();
            query.set("id", "OpenFileModal");
            query.set("type", type);
            query.set("root", rootNodeId);

            ReactDOM.render(<COMWrapper link={`${this.buildLink()}/com?${query}`} onReady={(postmateChild) => {
                postmateChild.on("submit", (node: string) => {
                    dispose();
                    resolve(JSON.parse(node));
                });
                postmateChild.on("cancel", () => {
                    dispose();
                    resolve(null);
                });
            }} />, container);
        });
    }

    private static buildLink() {
        if (__IS_DEBUG__) {
            // return "http://localhost:6660";
            return "";
        } else {
            return "";
        }
    }
}

const Container = styled.div`
    position: absolute;
    top:     0;
    left:    0;
    right:   0;
    bottom:  0;
    background: transparent;
    overflow: hidden;
    z-index: 5000;
    text-align: center;
    .ant-spin {
        position: absolute;
        left:   0;
        right:  0;
        width:  100%;
        top:    50%;
        margin-top: -29px;
    }
    & > iframe {
        position: absolute;
        top:     0;
        left:    0;
        right:   0;
        bottom:  0;
        background: transparent;
    }
`;
