import { Button, Col, Drawer, Form, Icon, Input, Row, Tabs, Tag } from "antd";
import * as mobx from "mobx";
import { observer } from "mobx-react";
import * as React from "react";

import RuleChecker from "../../utils/RuleChecker";
import BaseComponent from "../BaseComponent";
interface IProps {
    isVisible: boolean;
    finish(): void;
}
const FormItem = Form.Item;
const TabPane = Tabs.TabPane;

@observer
export default class AccountDrawer extends BaseComponent<IProps, any> {

    @mobx.observable
    private oldPassword: string = "";

    @mobx.observable
    private newPassword: string = "";

    @mobx.observable
    private confirmNewPassword: string = "";

    @mobx.observable
    private isOldPasswordCorrect: boolean = true;

    @mobx.observable
    private isNewPasswordCorrent: boolean = true;

    @mobx.observable
    private isConfirmNewPassword: boolean = true;

    @mobx.observable
    private name: string;

    @mobx.observable
    private groupNames: string[] = null;

    private get account() { return this.store.account; }

    private handleSubmit() {
        this.isOldPasswordCorrect = RuleChecker.PasswordRule(this.oldPassword);
        this.isNewPasswordCorrent = RuleChecker.PasswordRule(this.newPassword);
        this.isConfirmNewPassword = this.confirmNewPassword === this.newPassword;

        if (this.isOldPasswordCorrect && this.isNewPasswordCorrent && this.isConfirmNewPassword) {
            this.run("更新用户", async () => {
                await this.store.account.updatePassword(this.oldPassword, this.newPassword);
                await this.props.finish();
                this.oldPassword = "";
                this.newPassword = "";
            });
        } else {
            return this;
        }
    }

    public componentDidMount() {
        // if (!this.store.isSuperAdmin) {
        //     this.run("读取用户信息", async () => {
        //         let userManager = new UserManager(this.store);
        //         await userManager.refresh();
        //         this.user = userManager.getUserById(this.store.service.userId);
        //         this.name = this.user.name;
        //         this.groupNames = this.user.groups.map((group) => group.name);
        //     });
        // } else {
        //     return this;
        // }
    }

    public render() {
        return (<div>
            <Drawer
                title="个人中心"
                width={600}
                placement="right"
                closable={true}
                onClose={this.props.finish}
                maskClosable={true}
                destroyOnClose={true}
                visible={this.props.isVisible}
            >
                <Tabs defaultActiveKey="1" animated={false}>
                    <TabPane tab="用户信息" key="1">
                        <Row style={{ marginBottom: "20px" }}>
                            <Col span={12}>
                                <span style={{ marginRight: "20px", marginBottom: "20px" }}>用户名:</span>
                                <span style={{ marginRight: "20px", marginBottom: "20px" }}>{!this.account.isSuperAdmin ? this.store.service.username : "超级管理员"}</span>
                            </Col>
                        </Row>
                        <Row gutter={16} style={{ marginBottom: "20px" }}>
                            <Col>
                                <span style={{ marginRight: "20px" }}>身份:</span>
                                {this.account.isSuperAdmin ? (<Tag color="red">超级管理员</Tag>) : (this.account.isAdmin ? (<Tag color="orange">管理员</Tag>) : (<Tag>普通用户</Tag>))}
                            </Col>
                        </Row>
                    </TabPane>
                    {!this.account.isSuperAdmin ?
                        (
                            <TabPane tab="用户安全" key="2"  >
                                <span>修改密码：</span>
                                <Row gutter={8} type="flex" justify="center" style={{ marginBottom: "20px" }}>
                                    <Col span={12}>
                                        <Form style={{ marginTop: "20px", maxWidth: "300px" }} onSubmit={(evt) => { evt.preventDefault(); this.handleSubmit(); }}>
                                            <FormItem
                                                label="旧密码"
                                                validateStatus={this.isOldPasswordCorrect ? "success" : "error"}
                                                help={this.isOldPasswordCorrect ? "" : "旧密码不能为空"}
                                            >
                                                <Input
                                                    value={this.oldPassword}
                                                    onChange={(evt) => this.oldPassword = evt.target.value}
                                                    prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
                                                    type="password" placeholder="请输入旧密码"
                                                />
                                            </FormItem>
                                            <FormItem
                                                label="新密码"
                                                validateStatus={this.isNewPasswordCorrent ? "success" : "error"}
                                                help={this.isNewPasswordCorrent ? "" : "密码不能为空，长度为6-20位字符，且不能包含 \ / : * ? < > | ! 符号"}
                                            >
                                                <Input
                                                    type="password"
                                                    placeholder="请输入新密码"
                                                    value={this.newPassword}
                                                    prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
                                                    onChange={(evt) => this.newPassword = evt.target.value}
                                                />
                                            </FormItem>
                                            <FormItem
                                                label="再次确认新密码"
                                                validateStatus={this.isConfirmNewPassword ? "success" : "error"}
                                                help={this.isConfirmNewPassword ? "" : "请与新密码保持一致"}
                                            >
                                                <Input
                                                    type="password"
                                                    placeholder="请输入新密码"
                                                    value={this.confirmNewPassword}
                                                    prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
                                                    onChange={(evt) => this.confirmNewPassword = evt.target.value}
                                                />
                                            </FormItem>
                                            <FormItem>
                                                <Button type="primary" htmlType="submit" style={{ width: "100%" }}>修改</Button>
                                            </FormItem>
                                        </Form>
                                    </Col>
                                </Row>
                            </TabPane>
                        )
                        :
                        ("")
                    }
                </Tabs>
            </Drawer>
        </div>);
    }
}
