import _ from "lodash";
import * as mobx from "mobx";

import Node from "./Node";
import Store from "./Store";

const STORAGE_KEY = "BLADE-BOOKMARK";

interface IBookmark {
    id: string;
    name: string;
    type: string;
    icon: string;
}
/**
 * 树状结构管理类。将 list 形式的数据结构转成 tree 类型的，并且以 node 的形式返回。
 */
export default class Bookmarks {

    public readonly store: Store = null;
    public get service() { return this.store.service; }

    public get items() { return this.$items; }
    @mobx.observable
    private $items: IBookmark[] = [];

    constructor(store: Store) {
        this.store = store;
        this.load();
    }

    public getById(id: string) {
        for (let book of this.$items) {
            if (book.id === id) {
                return book;
            }
        }
        return null;
    }

    public createByNode(node: Node) {
        if (!this.getById(node.id)) {
            this.$items.push({
                id: node.id,
                name: node.name,
                type: node.type,
                icon: node.type,
            });
            this.save();
        }
    }

    public deleteById(id: string) {
        _.remove(this.$items, (n) => {
            return n.id === id;
        });
        this.save();
    }

    public move(id: string, positon: number) {
        let item = _.remove(this.$items, (n) => {
            return n.id === id;
        })[0];
        if (item) {
            this.$items.splice(positon, 0, item);
        }
        this.save();
    }

    public rename(id: string, name: string) {
        let item = this.getById(id);
        if (item) {
            item.name = name;
        }
        this.save();
    }

    private save() {
        let books = this.$items ? this.$items.slice() : [];
        let booksText = JSON.stringify(books);
        window.localStorage.setItem(STORAGE_KEY, booksText);
    }

    private load() {
        let booksText = window.localStorage.getItem(STORAGE_KEY);
        if (booksText) {
            this.$items = JSON.parse(booksText);
        } else {
            this.$items = [];
        }
    }
}