import _ from "lodash";
import * as mobx from "mobx";

import FolderRef from "./FolderRef";
import * as REST from "./Service";

/**
 * 记录文档引用关系的数据结构类。
 * 和 Doc 类不同，DocRef 不提供任何操作，只有数据结构，用于查询和展示。
 */
export default class DocRef {

    private constructor() { }

    /**
     * 文档的ID。
     */
    public get id() { return this.$id; }
    @mobx.observable
    private $id: string = null;

    /**
     * 文档的名字。
     */
    public get name() { return this.$name; }
    @mobx.observable
    private $name: string = null;

    /**
     * 所有父文件夹。根目录是文档库。
     */
    public get parents() { return this.$parents; }
    @mobx.observable
    private $parents: FolderRef[] = [];

    /**
     * 父文件夹。
     */
    public get parent(): FolderRef {
        return this.parents[this.parents.length - 1];
    }

    public static createByDataObject(dataObject: REST.INodeDetail) {
        let doc = new DocRef();
        doc.$id = dataObject.id;
        doc.$name = dataObject.name;

        doc.$parents = FolderRef.createParents(dataObject);

        return doc;
    }

}