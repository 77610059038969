import { Icon } from "antd";
import * as React from "react";

export default class ModalUtils {
    /**
     * Title
     */
    public static title = (props: {
        text: string,
        type: string,
        color?: string,
    }) => {
        return (
            <>
                <Icon
                    style={{
                        fontSize: "22PX", marginRight: 16, float: "left", color: (props.color || "#faad14"),
                    }}
                    type={props.type}
                />
                <span className="ant-confirm-title">{props.text}</span>
            </>
        );
    }
}