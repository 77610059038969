import axios from "axios";
import * as mobx from "mobx";

import Store from "./Store";
/**
 * 系统配置管理类。在系统启动的时候，动态读取配置文件 config.json 来获取系统的基础配置信息。
 */
export default class Config {

    public readonly store: Store;

    public get service() { return this.store.service; }

    /**
     * 系统标题。
     */
    public get title() { return this.$title; }
    @mobx.observable
    private $title: string = "";

    /**
     * 默认登录的方式。
     */
    public get authBasicProvider() { return this.$authBasicProvider; }
    @mobx.observable
    private $authBasicProvider: string = null;

    /**
     * 允许的插件列表。
     */
    public get plugins() { return this.$plugins; }
    @mobx.observable
    private $plugins: IPlugin[] = null;

    /**
     * 扩展的菜单列表。
     */
    public get menus() { return this.$menus; }
    @mobx.observable
    private $menus: IMenuItem[] = null;

    constructor(store: Store) {
        this.store = store;
    }

    /**
     * 加载配置文件。
     */
    public async refresh() {

        let res = await axios.get("/config.json");

        let config = res.data;

        this.$title = config.title;

        this.$authBasicProvider = config.auth.basic;

        this.$plugins = config.plugins;

        this.$menus = config.menus;

        return this;
    }

    // private getValue(value: string) {
    //     let names = _.split(value, ".");
    //     if (this.config) {
    //         let current = this.config;
    //         for (const name of names) {
    //             current = current[name];
    //             if (current) {
    //             } else {
    //                 return null;
    //             }
    //         }
    //         return current;
    //     } else {
    //         return null;
    //     }
    // }
}

interface IPlugin {
    type: string;
    name: string;
    modes?: string[];
}

interface IMenuItem {
    name: string;
    url: string;
    target: string;
    type?: string;
    permission?: string;
}