import _ from "lodash";
import * as mobx from "mobx";
import moment from "moment";

import UserGroup from "./UserGroup";
import UserManager from "./UserManager";

export default class User {

    public readonly userManager: UserManager = null;

    // public get userManagerAll() { return this.userManager && this.userManager.userGroups; }
    public get store() { return this.userManager.store; }
    public get service() { return this.store.service; }

    public get id() { return this.$id; }
    @mobx.observable
    private $id: string = null;

    /**
     * 用户登录的名字，全英文的。
     */
    public get name() { return this.$name; }
    @mobx.observable
    private $name: string = null;

    /**
     * 用户显示的名字。例如中文名字，真实的名字。
     */
    public get displayName() { return this.$displayName; }
    @mobx.observable
    private $displayName: string = null;

    public get createdAt() { return this.$createdAt; }
    @mobx.observable
    private $createdAt: moment.Moment = null;

    public get isAdmin() { return this.$isAdmin; }
    @mobx.observable
    private $isAdmin: boolean = false;

    public get groups() { return this.$groups; }
    @mobx.observable
    private $groups: UserGroup[] = [];

    private constructor(userManager: UserManager) {
        this.userManager = userManager;
    }

    public async updatePassword(password: string) {

        await this.service.updateSelfPassword(this.id, password);
        return this;
    }

    /**
     * 更新用户信息。注意，更新密码不是调用这个 API。
     * @param isAdmin 是否是管理员。
     * @param userGroups 所属的用户组，全部用户组，不是差值。
     */
    public async update(
        roles?: string[],
        userGroups?: string[],
        password?: string,
        displayName?: string,
    ) {

        await this.service.updateUserInfo(this.id, {
            roles,
            userGroups,
            password,
            displayName,
        });
        await this.userManager.refresh();

        return this;
    }

    public async delete() {
        await this.service.deleteUserId(this.id);

        await this.userManager.refresh();

        console.log(`删除了用户 ${this.name} ${this.displayName} ${this.id}`);

        return this;
    }

    private refreshByDataObject(dataObject: any) {
        this.$id = dataObject.id;
        this.$name = dataObject.name;
        this.$displayName = dataObject.display_name;
        this.$createdAt = moment(dataObject.created_at);
        if (dataObject.roles instanceof Array) {
            this.$isAdmin = dataObject.roles.find((role) => role === "admin") && true;
        }
        dataObject.groups = dataObject.groups || [];
        let groupIds: string[] = dataObject.groups.map((x) => x.id ? x.id : x);
        this.$groups = this.userManager.userGroups.filter((group) => groupIds.indexOf(group.id) >= 0);
    }

    public static createByDataObject(userManager: UserManager, dataObject: any) {
        let user = new User(userManager);
        user.refreshByDataObject(dataObject);

        return user;
    }
}