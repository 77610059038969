import axios, { AxiosRequestConfig } from "axios";
import { MD5 } from "crypto-js";
import { forEach } from "lodash";
const StorageKey = "BLADE_TOKEN";
/**
 * 用来处理请求盒知APP的API
 */
export default class HezhiService {
    private $baseUrl: string = "";
    private get secret() { return this.$secret; }
    private $secret = "2d6a7a1a3ae04cc8a6dbc7c5f6a6fe89";
    public get token() { return this.$token; }
    public set token(val) { this.$token = val; }
    private $token = "123456";
    public set username(val: string) { this.$username = val; }
    private $username: string = null;
    public set password(val: string) { this.$password = val; }
    private $password: string = null;

    constructor(baseUrl: string) {
        this.$baseUrl = baseUrl;
        // For Debug
        // localStorage.setItem(StorageKey, JSON.stringify({
        //     username: "xuanke_test",
        //     password: "123456",
        // }));
        // 从localStorage里面读取信息
        let itemText = localStorage.getItem(StorageKey) || null;
        let item: any;
        if (itemText) {
            item = JSON.parse(itemText);
        } else {
            item = {
                username: null,
                password: null,
            };
        }
        this.$username = item.username;
        this.$password = item.password;
    }
    private buildSign(params: any) {
        const orderedParams = {};
        Object.keys(params).sort().forEach((key) => {
            orderedParams[key] = params[key];
        });
        let signStr: string = "";
        forEach(orderedParams, (value, key) => {
            signStr += `${key}=${value}&`;
        });
        signStr += `secret=${this.secret}`;
        // console.log("sign string: ", signStr);
        return MD5(signStr).toString().toUpperCase();
    }
    private buildHeaders(params: any = {}) {
        let headers: any = {};
        let timestamp = new Date().getTime();
        params.time = timestamp;
        params.token = this.token;

        headers.sign = this.buildSign(params);
        headers.time = timestamp;
        headers.token = this.token;
        // console.log(headers);
        return headers;
    }

    private async doLogin() {
        let password = MD5(this.$password).toString().toUpperCase();
        let timestamp = new Date().getTime();
        let sign = MD5(`password=${password}&time=${timestamp}&user_name=${this.$username}&secret=${this.$secret}`).toString().toUpperCase();
        let headers = {
            time: timestamp,
            sign: sign,
        };

        let data = await this.post(`/remoteMobile/3D/verifyUserIdentity/${this.$username}/${password}`, headers, null);
        this.$token = data.token;
    }

    /**
     * 获取商户列表
     */
    public async readBusinesseses() {
        let headers = this.buildHeaders();
        try {
            let data = await this.get(`/remoteMobile/3D/getBusinessList`, headers);
            return data;
        } catch (err) {
            if (err.message === "300") {
                await this.doLogin();
                headers = this.buildHeaders();
                let data = await this.get(`/remoteMobile/3D/getBusinessList`, headers);
                return data;
            } else {
                throw err;
            }
        }
    }

    /**
     * 获取商户品牌列表
     * @param businessId 商户ID
     */
    public async readBrands(businessId: number) {
        let headers = this.buildHeaders({
            business_id: businessId,
        });
        try {
            let data = await this.get(`/remoteMobile/3D/getBusinessBrandList/${businessId}`, headers);
            return data;
        } catch (err) {
            if (err.message === "300") {
                await this.doLogin();
                headers = this.buildHeaders({
                    business_id: businessId,
                });
                let data = await this.get(`/remoteMobile/3D/getBusinessBrandList/${businessId}`, headers);
                return data;
            } else {
                throw err;
            }
        }
    }

    /**
     * 获取模板分类列表
     */
    public async readTemplateCategories() {
        let headers = this.buildHeaders();
        try {
            let data = await this.get(`/remoteMobile/3D/getTemplateCategoryList`, headers);
            return data;
        } catch (err) {
            if (err.message === "300") {
                await this.doLogin();
                headers = this.buildHeaders();
                let data = await this.get(`/remoteMobile/3D/getTemplateCategoryList`, headers);
                return data;
            } else {
                throw err;
            }
        }
    }

    /**
     * 获取模板场景列表
     */
    public async readTemplateCases() {
        let headers = this.buildHeaders();
        try {
            let data = await this.get(`/remoteMobile/3D/getTemplateCaseList`, headers);
            return data;
        } catch (err) {
            if (err.message === "300") {
                await this.doLogin();
                headers = this.buildHeaders();
                let data = await this.get(`/remoteMobile/3D/getTemplateCaseList`, headers);
                return data;
            } else {
                throw err;
            }
        }
    }

    /**
     * 检查品牌下3D产品是否有被使用
     */
    public async checkProductRel(product3DId: string, brandId: number) {
        let headers = this.buildHeaders({
            product_3D_id: product3DId,
            brand_id: brandId,
        });
        let body = {
            product_3D_id: product3DId,
            brand_id: brandId,
        };
        try {
            let data = await this.post(`/remoteMobile/3D/checkProductRel`, headers, body);
            return data;
        } catch (err) {
            if (err.message === "300") {
                await this.doLogin();
                headers = this.buildHeaders();
                let data = await this.post(`/remoteMobile/3D/checkProductRel`, headers, body);
                return data;
            } else {
                throw err;
            }
        }
    }

    private async get(path: string, headers: any = {}, params: any = null): Promise<any> {
        return this.execute({
            method: "get",
            params: params,
            headers: headers,
            url: this.$baseUrl + path,
            // auth: this.auth,
            withCredentials: true,
        });
    }

    private async post(path: string, headers: any = {}, body: object): Promise<any> {
        return this.execute({
            method: "post",
            url: this.$baseUrl + path,
            headers: headers,
            data: body,
            withCredentials: true,
        });
    }

    private async put(path: string, headers: any = {}, body: object): Promise<any> {
        return this.execute({
            method: "put",
            url: this.$baseUrl + path,
            headers: headers,
            data: body,
            withCredentials: true,
        });
    }

    private async delete(path: string, headers: any = {}): Promise<any> {
        return this.execute({
            method: "delete",
            url: this.$baseUrl + path,
            withCredentials: true,
        });
    }
    private async execute(options: AxiosRequestConfig): Promise<any> {
        try {
            let res = await axios(options);
            if (res.status === 200) {
                if (res.data.resultCode === "200" && res.data.returnCode === "200") {
                    return res.data.body;
                } else if (res.data.returnCode === "300") {
                    throw new Error("300");
                } else {
                    throw new Error(`Web服务出错：${res.data}`);
                }
            } else {
                throw new Error(`Web服务出错：${res}`);
            }
        } catch (error) {
            throw error;
        }
    }
}