import { Button, Checkbox, Form, Input, Modal, Select } from "antd";
import * as mobx from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import v8n from "v8n";

import User from "../../models/users/User";
import UserManager from "../../models/users/UserManager";
import BaseComponent from "../BaseComponent";

const FormItem = Form.Item;
const Option = Select.Option;

interface IProps {
    userManager: UserManager;
    user?: User;
    mode: "create" | "update";
    visible: boolean;
    onCancelled(): void;
}

@observer
export default class UserEditModal extends BaseComponent<IProps, any> {

    @mobx.observable
    private password: string = "";

    @mobx.observable
    private name: string = "";
    @mobx.observable
    private displayName: string = "";

    @mobx.observable
    private roles: string[] = ["editor"];

    @mobx.observable
    private groups: string[] = [];

    @mobx.observable
    private isAdmin: boolean = null;

    @mobx.observable
    private isPasswordCorrect: boolean = true;

    @mobx.observable
    private isUsernameCorrect: boolean = true;
    @mobx.observable
    private isDisplayName: boolean = true;

    @mobx.computed
    private get user() { return this.props.user; }

    @mobx.computed
    private get userGroups() { return this.props.userManager.userGroups || []; }

    @mobx.computed
    private get isUpdatingMode() { return this.props.mode === "update"; }

    @mobx.computed
    private get isCreatingMode() { return !this.isUpdatingMode; }
    @mobx.observable
    private isLoaded: boolean = false;
    public isCreatingAdmin() {
        this.isAdmin = this.isAdmin !== true;
        this.roles = this.isAdmin ? ["admin", "editor"] : ["editor"];
    }
    private handleSubmit() {
        // 验证数据的有效性
        if (this.password || this.isCreatingMode) {
            this.isPasswordCorrect = v8n().string().minLength(6).maxLength(20).test(this.password);
        } else {
            this.isPasswordCorrect = true;
        }
        if (this.name || this.isCreatingMode) {
            this.isUsernameCorrect = v8n().string().minLength(1).maxLength(20).test(this.name);
        } else {
            this.isUsernameCorrect = true;
        }
        if (this.displayName || this.isCreatingMode) {
            this.isDisplayName = v8n().string().minLength(1).maxLength(20).test(this.displayName);
        } else {
            this.isDisplayName = true;
        }

        if (this.isUsernameCorrect && this.isCreatingMode) {
            this.run("增加用户", async () => {
                await this.props.userManager.createUser(this.name, this.password, this.roles, this.groups, this.displayName);
                await this.props.onCancelled();
            });
        }
        if (this.isUsernameCorrect && this.isUpdatingMode && this.isPasswordCorrect) {
            this.run("更新用户", async () => {
                await this.user.update(this.roles, this.groups, this.password, this.displayName);
                await this.props.onCancelled();
            });
        }
    }

    public componentDidMount() {
            this.run("读取", async () => {
                await this.props.userManager.listGroup();
                !this.isCreatingMode &&
                (this.isLoaded = true,
                this.name = this.user.name,
                this.roles = this.user.isAdmin ? ["admin", "editor"] : ["editor"],
                this.isAdmin = this.user.isAdmin,
                this.groups = this.user.groups.map((group) => group.id) || [],
                this.displayName = this.user.displayName);
            });
    }

    public render() {
        if (this.isUpdatingMode && this.user === null && !this.isLoaded) {
            return null;
        }
        return (
            <Modal
                title={this.isCreatingMode ? "创建用户" : "编辑用户"}
                visible={this.props.visible}
                closable={false}
                footer={null}
                centered={true}
            >
                <Container>
                    <Form onSubmit={(evt) => { evt.preventDefault(); this.handleSubmit(); }}>

                        <FormItem {...FormItemLayout}
                            label="用户名"
                            validateStatus={this.isUsernameCorrect ? "success" : "error"}
                            help="用户名不能为空,不允许带有特殊字符\/:*?/<>|"
                        >
                            <Input
                                autoFocus={true}
                                readOnly={this.isUpdatingMode}
                                disabled={this.isUpdatingMode}
                                pattern={`^[^\\\\/:*?"<>|]+$`}
                                value={this.name}
                                maxLength={20}
                                onChange={(evt) => this.name = evt.target.value} />
                        </FormItem>
                        <FormItem {...FormItemLayout}
                            label="展示名"
                            validateStatus={this.isDisplayName ? "success" : "error"}
                            help="建议使用中文名称"
                        >
                            <Input
                                maxLength={20}
                                pattern={`^[^\\\\/:*?"<>|]+$`}
                                value={this.displayName}
                                onChange={(evt) => this.displayName = evt.target.value} />
                        </FormItem>

                        <FormItem {...FormItemLayout} label="密码"
                            validateStatus={this.isPasswordCorrect ? "success" : "error"}
                            help="密码必须是6位到20位之间的字符">
                            <Input
                                value={this.password}
                                type="password" maxLength={20}
                                onChange={(evt) => this.password = evt.target.value} />
                        </FormItem>

                        <FormItem {...FormItemLayout} label="所在用户组">
                            <Select
                                mode="multiple"
                                style={{ width: "100%" }}
                                placeholder="可以选择多个用户组"
                                value={this.groups}
                                onSearch={(evt) => { fetch(evt); }}
                                onChange={(evt) => { this.groups = evt as string[]; }}
                            >
                                {this.userGroups.map((item) => (
                                    <Option key={item.id}>{item.name}</Option>
                                ))}
                            </Select>
                        </FormItem>

                        <FormItem {...FormItemLayout} label="角色">
                            <Checkbox
                                onChange={this.isCreatingAdmin.bind(this)}
                                checked={this.isAdmin}
                            >管理员</Checkbox>
                        </FormItem>

                        <FormItem style={{ textAlign: "right" }}>
                            <Button onClick={() => this.props.onCancelled()}>取消</Button>
                            <Button type="primary" htmlType="submit" style={{ marginLeft: 8 }}>确认</Button>
                        </FormItem>
                    </Form>
                </Container>
            </Modal >
        );
    }
    public static async show(userManager: UserManager, user: User, mode): Promise<boolean> {

        return new Promise<any>((resolve, reject) => {
            let container = document.createElement("div");
            document.body.appendChild(container);

            let dispose = (success: boolean) => {
                ReactDOM.render(
                    <UserEditModal
                        userManager={userManager}
                        user={user}
                        visible={false}
                        mode={mode}
                        onCancelled={() => { }}
                    />,
                    container);
                setTimeout(() => {
                    ReactDOM.unmountComponentAtNode(container);
                    document.body.removeChild(container);
                }, 300);
                resolve(success);
            };

            ReactDOM.render(
                <UserEditModal
                    userManager={userManager}
                    user={user}
                    visible={true}
                    mode={mode}
                    onCancelled={() => {
                        dispose(false);
                    }}
                />,
                container);
        });
    }
}

const FormItemLayout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 19 },
};
const Container = styled.div`
    .ant-form-item:last-child {
        margin-bottom: 0;
    }
`;
