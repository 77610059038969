import { Button, Card, Col, Form, Icon, Input, List, Modal, Row, Tabs } from "antd";
import _ from "lodash";
import * as mobx from "mobx";
import { observer } from "mobx-react";
import moment from "moment";
import * as React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

import Doc from "../../../models/Doc";
import Node from "../../../models/Node";
import RuleChecker from "../../../utils/RuleChecker";
import BaseComponent from "../../BaseComponent";

import ModalUtils from "./ModalUtils";
import NodeInfoView from "./NodeInfoView";

interface IProps {
    node: Node;
    visible: boolean;
    hideMeta?: boolean;
    onSubmitted?: (name: string) => void;
    onCancelled?: () => void;
    afterClose?: () => void;
}

const FormItem = Form.Item;
const TabPane = Tabs.TabPane;
@observer
export default class NodeRenameModal extends BaseComponent<IProps, any> {

    @mobx.computed
    public get node() { return this.props.node; }

    @mobx.observable
    public doc: Doc = null;
    @mobx.observable
    private newName: string = null;
    @mobx.observable
    private isLoaded: boolean = false;
    @mobx.observable
    private isNewNameCorrect: boolean = true;
    private handleSubmit() {
        // 验证数据的有效性
        this.isNewNameCorrect = RuleChecker.NameRule(this.newName);
        if (this.isNewNameCorrect && this.newName !== null) {
            this.props.onSubmitted(this.newName);
        }
    }
    public componentDidMount() {
        this.run("加载节点信息", async () => {
            if (this.node.type !== "folder") {
                this.doc = await Doc.createById(this.node.id, this.store);
                this.doc.refresh();
            }
            this.isLoaded = true;
        });
    }
    public render() {
        // 读取结束之后，再渲染内容。
        if (!this.isLoaded) {
            return null;
        }
        return (
            <Modal
                afterClose={this.props.afterClose}
                title={ModalUtils.title({
                    text: "重命名 " + this.node.name,
                    type: "form",
                })}
                visible={this.props.visible}
                closable={false}
                width={900}
                footer={null}
                centered={true}
            >
                <Container>
                    <Form onSubmit={(evt) => { evt.preventDefault(); this.handleSubmit(); }}>
                        <NodeInfoView
                            node={this.node}
                            mode="rename"
                            isNewNameCorrect={this.isNewNameCorrect}
                            onChange={(evt) => { this.newName = (evt.target.value).replace(" ", ""); }}
                        ></NodeInfoView>
                        <FormItem style={{ textAlign: "right", marginTop: 20 }}>
                            <Button onClick={() => { this.props.onCancelled(); }} >取消</Button>
                            <Button type="primary" htmlType="submit" style={{ marginLeft: 8 }} >确认</Button>
                        </FormItem>
                    </Form>
                </Container>
            </Modal >
        );
    }

    public static async show(node: Node): Promise<{
        name: string,
        success: boolean,
    }> {
        return new Promise<{ name: string, success: boolean }>((resolve, reject) => {
            let container = document.createElement("div");
            document.body.appendChild(container);
            let dispose = (success: boolean, name: string) => {
                ReactDOM.render(
                    <NodeRenameModal
                        node={node}
                        visible={false}
                        onSubmitted={() => { }}
                        onCancelled={() => { }}
                        afterClose={() => {
                            ReactDOM.unmountComponentAtNode(container);
                            document.body.removeChild(container);
                            resolve({ name, success });
                        }}
                    />,
                    container);
            };

            ReactDOM.render(
                <NodeRenameModal
                    node={node}
                    visible={true}
                    onSubmitted={(name) => { dispose(true, name); }}
                    onCancelled={() => { dispose(false, name); }}
                />,
                container);
        });
    }
}

const Container = styled.div`
    .ant-form-item:last-child {
        margin-bottom: 0;
    }
    .ant-form-explain{
        position:absolute;
    }
    .ant-list{
        height:300px;
        overflow:scroll !important;
    }
`;
const FormItemLayout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 19 },
};