import _ from "lodash";
import * as mobx from "mobx";

import TypedEvent from "../TypedEvent";

import Account from "./Account";
import Bookmarks from "./Bookmarks";
import Config from "./Config";
import Models from "./Models";
import Plugins from "./Plugins";
import Preferences from "./Preferences";
import Service from "./Service";
import { Templates } from "./Templates";
declare const __TIMESTAMP__: Date;

export default class Store {

    public readonly timestamp = __TIMESTAMP__;

    /**
     * 注意，Service 对象不要被 mobx 控制。
     * Service 作为 API 类，是随时可以构建的，不属于 ViewModel 的范畴。
     */
    public readonly service: Service = null;

    /**
     * 收藏夹管理器。
     */
    @mobx.observable
    public readonly bookmarks: Bookmarks = new Bookmarks(this);

    /**
     * 首选项管理器。
     */
    @mobx.observable
    public readonly preferences: Preferences = new Preferences();

    /**
     * 模板管理器。
     */
    @mobx.observable
    public readonly templates: Templates = new Templates(this);

    /**
     * 数据模型管理器。
     */
    @mobx.observable
    public readonly models: Models = new Models(this);

    /**
     * 插件管理器。
     */
    @mobx.observable
    public readonly plugins: Plugins = new Plugins(this);

    /**
     * 当前账户的管理类。
     */
    @mobx.observable
    public readonly account: Account = new Account(this);

    /**
     * 系统配置管理类。
     */
    @mobx.observable
    public readonly config: Config = new Config(this);

    public constructor() {
        this.service = new Service();
    }

    /**
     * 系统初始化。
     */
    public async setup() {
        await this.config.refresh();
        await this.account.tryAutoLogin();
        await this.templates.setup();
        await this.plugins.setup();
        await this.models.setup();
    }

    /**
     * 更新各个组件的数据。会从远程服务器拉取新的数据。
     */
    public async refresh() {
        // await this.templates.refresh();
        await this.models.refresh();
        // await this.userManager.refresh();
        return this;
    }
}