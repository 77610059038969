import * as React from "react";
import styled from "styled-components";

export default class VerticalCenter extends React.PureComponent<any> {

    public render() {
        return (
            <CenteredTable>
                <tbody>
                    <tr><td>{this.props.children}</td></tr>
                </tbody>
            </CenteredTable>
        );
    }
}
const CenteredTable = styled.table`
    width:  100%;
    height: 100%;

    & > tbody > tr > td {
        text-align: center;
        vertical-align: middle;
    }
`;