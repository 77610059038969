import { Button, Input } from "antd";
import * as mobx from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import styled from "styled-components";

import Doc from "../../models/Doc";
import Folder from "../../models/Folder";
import Node from "../../models/Node";
import BaseComponent from "../BaseComponent";
import Hezhi from "../hezhi/Hezhi";

import EventBus from "./EventBus";

interface IProps {
    node: Node;
    eventBus: EventBus;
    folder: Folder;
    onSearch(value: string): void;

}
@observer
export default class Toolbar extends BaseComponent<IProps, any> {

    @mobx.computed
    public get folder() { return this.props.folder; }

    public get item() { return this.$item; }
    @mobx.observable
    public $item: Folder | Doc = null;

    // public componentDidMount() {
    // }

    public render() {
        return (
            <Container>
                <table>
                    <tbody>
                        <tr>
                            <td className="left">
                                {this.folder.canWrite ?
                                    <Button.Group>
                                        <Button type="default" icon="file-add" onClick={() => this.props.eventBus.createFile.emit(this.props.node)}>创建新文档</Button>
                                        <Button type="default" icon="folder-add" onClick={() => this.props.eventBus.createFolder.emit(this.props.node)}>新建文件夹</Button>
                                        <Button type="default" icon="export" onClick={() => this.props.eventBus.importModal.emit(this.props.node)}>导入文档</Button>
                                        <Button type="default" onClick={() => this.props.eventBus.batchMoveModal.emit(null)}>移动到</Button>
                                        <Button type="danger" icon="delete" onClick={() => this.props.eventBus.batchDeleteModal.emit(null)}>删除</Button>
                                    </Button.Group>
                                    :
                                    <div>这个文件夹是只读的。</div>
                                }
                            </td>
                            <td className="right">
                                {!Hezhi.isRunning && (
                                    <Input.Search
                                        placeholder="搜索"
                                        style={{ width: 200 }}
                                        onSearch={(value) => this.props.onSearch(value)}
                                    />
                                )}
                                <Button
                                    type="default" icon="star"
                                    onClick={() => this.props.eventBus.bookmark.emit({})}
                                >收藏夹</Button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Container >
        );
    }

}

const Container = styled.div`
    position: relative;
    height: 100%;

    table {
        height: 100%;
        vertical-align: middle;
    }
    td.left {
        text-align: left;
        .ant-btn {
        }
    }
    td.right {
        text-align: right;
        .ant-btn {
            margin-left: 20px;
        }
    }
`;