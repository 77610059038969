import { Button, Icon, Modal } from "antd";
import * as mobx from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import ReactDOM from "react-dom";
import styled, { createGlobalStyle } from "styled-components";

import Folder from "../../../models/Folder";
import Node from "../../../models/Node";
import BaseComponent from "../../BaseComponent";
import { JsNode, JsTree } from "../../common/JsTree";

interface IProps {
    title: string;
    /**
     * 需要移动的节点。
     */
    node: Node | Node[];
    visible: boolean;
    onCancel(): void;
    onSubmit(nodeId: string): void;
    afterClose?(): void;
}
/**
 * 文件夹视图
 */
@observer
export default class NodeSelectModal extends BaseComponent<IProps, any> {

    @mobx.computed
    public get visible() { return this.props.visible; }

    @mobx.computed
    public get node() { return this.props.node; }

    private treeContainer: HTMLElement = null;

    private tree: JsTree = null;

    @mobx.observable
    private selectedJsNode: JsNode = null;

    /**
     * 文件夹根节点。
     */
    @mobx.observable
    private rootFolder: Folder = null;

    public componentDidMount() {
        window.setTimeout(() => {
            this.run("读取目录信息", async () => {
                await this.refreshRootFolder();
                await this.refreshJsTree();
            });
        }, 0);

        mobx.observe(this, "visible", async () => {
            if (this.visible) {
                alert("load");
            }
        });
    }

    private async submit() {
        let nodeId = this.selectedJsNode.id;
        this.props.onSubmit(nodeId);
    }

    private async cancel() {
        this.props.onCancel();
    }

    /**
     * 读取完整的文档库目录结构。
     */
    private async refreshRootFolder() {
        this.rootFolder = Folder.createById("00000000-0000-0000-0000-000000000010", this.store);
        await this.rootFolder.refresh(1000, 1000, "folder");
    }

    /**
     * 根据目录结构更新 jstree。
     */
    private async refreshJsTree() {
        this.selectedJsNode = null;
        if (this.tree === null) {
            this.tree = new JsTree(this.treeContainer, {
                checkBox: false,
                contextMenu: false,
                keyboard: false,
                dragAndDrop: false,
            });
            this.tree.onNodeSelected = (node: JsNode) => {
                this.selectedJsNode = node;
            };
        }
        let rootNode = this.parseNode(this.rootFolder);
        this.tree.root = rootNode;
        this.tree.refresh();

        this.tree.openAll(rootNode);
    }

    private validateNode(node: Node, folder: Folder, forceDisabled: boolean = false) {
        let isDisabled = false;

        /**
         * 有以下情况之一，那么就禁用这个节点。
         */

        // 不能移动到自己
        if (folder.id === node.id) {
            isDisabled = true;
        }
        // 不能移动到父目录
        if (node.parentId && node.parentId === folder.id) {
            isDisabled = true;
        }

        // 如果这个文件夹没有写的权限
        if (folder.canWrite === false) {
            isDisabled = true;
        }

        if (folder.name === "11111111111111111111") {
            let a = 0;
            a++;
            console.log(folder.usedStrictType, folder.inheritedStrictType);
        }

        if (node.type === "folder") {
            // 目录不能移动到自己和自己的子文件夹下
            if (folder.id === node.id) {
                forceDisabled = true;
            }
        } else {
            // 如果目标节点不是目录，而且这个文件夹是严格模式，而且模式和目标节点的类型不一样。
            if (folder.usedStrictType && folder.inheritedStrictType !== node.type) {
                isDisabled = true;
            }
        }

        isDisabled = isDisabled || forceDisabled;
        return { isDisabled, forceDisabled };
    }

    private parseNode(folder: Folder, forceDisabled: boolean = false) {
        let isDisabled = false;
        if (this.node instanceof Node) {
            let res = this.validateNode(this.node, folder, forceDisabled);
            isDisabled = res.isDisabled;
            forceDisabled = res.forceDisabled;
        } else {
            this.node.forEach((nodeItem: Node) => {
                let res = this.validateNode(nodeItem, folder, forceDisabled);
                isDisabled = res.isDisabled;
                isDisabled = isDisabled || res.isDisabled;
                forceDisabled = res.forceDisabled;
            });
        }

        let node = new JsNode(this.tree)
            .setId(folder.id)
            .setText(folder.name || "根目录")
            .setType("folder")
            .setIcon("fas fa-fw fa-lg fa-folder")
            .setDisabled(isDisabled);

        if (folder.childFolders) {
            for (const childFolder of folder.childFolders) {
                let childNode = this.parseNode(childFolder, forceDisabled);
                if (childNode) {
                    node.children.push(childNode);
                }
            }
        }
        return node;
    }

    public render() {
        return (
            <>
                <GlobalStyle />
                <Modal
                    afterClose={this.props.afterClose}
                    visible={this.props.visible}
                    footer={null}
                    centered={true}
                    wrapClassName="NodeSelectModal"
                    width="800px"
                    closable={true}
                    onCancel={this.cancel.bind(this)}
                    title={this.props.title}
                >
                    <Container>
                        {/* <CloseButton onClick={() => this.props.onClosed()}><Icon type="close" /></CloseButton> */}
                        {/* <p>移动 <b>{this.movingNode && this.movingNode.name}</b> 至：{this.parentNode && this.parentNode.text}</p> */}
                        <TreeContainer ref={(el) => {
                            this.treeContainer = el;
                        }} />
                        <ButtonPanel>
                            <Button
                                type="default"
                                onClick={this.cancel.bind(this)}
                            >取&nbsp;&nbsp;消</Button>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button
                                type="primary"
                                disabled={this.selectedJsNode === null}
                                onClick={this.submit.bind(this)}
                            >确&nbsp;&nbsp;定</Button>
                        </ButtonPanel>
                        <MessagePanel>
                            无法选择的目录，是因为没有权限，或者文档类型不匹配。
                    </MessagePanel>
                    </Container>
                </Modal>
            </>
        );
    }

    public static async selectFolder(node: Node | Node[]): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            let container = document.createElement("div");
            document.body.appendChild(container);

            let dispose = (nodeId: string) => {
                ReactDOM.render(
                    <NodeSelectModal
                        node={node}
                        title="选择移动的位置"
                        visible={false}
                        onCancel={() => { }}
                        onSubmit={() => { }}
                        afterClose={() => {
                            ReactDOM.unmountComponentAtNode(container);
                            document.body.removeChild(container);
                            resolve(nodeId);
                        }}
                    />,
                    container);
            };
            ReactDOM.render(
                <NodeSelectModal
                    node={node}
                    title="选择移动的位置"
                    visible={true}
                    onCancel={() => dispose(null)}
                    onSubmit={(nodeId) => dispose(nodeId)}
                />,
                container);
        });
    }
}
const Container = styled.div`
    position: absolute;
    left:   20px;
    top:    55px;
    right:  20px;
    bottom: 20px;
    overflow: hidden;
`;
const TreeContainer = styled.div`
    position: absolute;
    top:    20px;
    left:   0px;
    right:  0px;
    bottom: 50px;
    border: 1px solid #ececec;
    overflow: auto;
    i.jstree-icon.fa-folder {
        color: #FBCD22;
    }
    i.jstree-icon.fa-file {
        color: #20A0FF;
    }
`;

const ButtonPanel = styled.div`
    position: absolute;
    left:    0;
    right:   0;
    bottom:  0;
    text-align: right;
`;

const MessagePanel = styled.div`
    position: absolute;
    left:    0;
    right:   200px;
    bottom:  0;
    text-align: left;
    line-height: 35px;
`;

const GlobalStyle = createGlobalStyle`
    .NodeSelectModal {
        .ant-modal {
            height: 90%;
        }
        .ant-modal-content {
            height: 100%;
        }
    }
`;