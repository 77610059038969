import { Modal } from "antd";
import classnames from "classnames";
import FileSaver from "file-saver";
import replace from "lodash/replace";
import * as mobx from "mobx";
import { observer } from "mobx-react";
import moment from "moment";
import * as React from "react";
import { arrayMove } from "react-sortable-hoc";
import "react-virtualized/styles.css";
import styled, { createGlobalStyle } from "styled-components";

import Doc from "../../models/Doc";
import Exceptions from "../../models/Exceptions";
import Folder from "../../models/Folder";
import Node from "../../models/Node";
import Watcher from "../../utils/Watcher";
import AuthoredComponent from "../AuthoredComponent";
import QRCodeModal from "../common/QRCodeModal";
import PluginModal from "../plugins/PluginModal";

import BookmarkListDrawer from "./BookmarkListDrawer";
import Breadcrumb from "./Breadcrumb";
import DocCardModeView from "./DocCardModeView";
import DocListModeView from "./DocListModeView";
import EventBus from "./EventBus";
import BatchNodeDeleteModal from "./modals/BatchNodeDeleteModal";
import CreateDocModal from "./modals/DocCreateModal";
import DocListImportModal from "./modals/DocListImportModal";
import FolderCreateModal from "./modals/FolderCreateModal";
import FolderPropertyModal from "./modals/FolderPropertyModal";
import NodeDeleteModal from "./modals/NodeDeleteModal";
import NodeInfoModal from "./modals/NodeInfoModal";
import NodeRenameModal from "./modals/NodeRenameModal";
import NodeSelectModal from "./modals/NodeSelectModal";
import Toolbar from "./Toolbar";

// import BookmarkListView from "./BookmarkListView";

@observer
export default class DocRootView extends AuthoredComponent<any> {

    private readonly eventBus: EventBus = new EventBus();

    private readonly watcher: Watcher<DocRootView> = new Watcher(this);

    @mobx.computed
    public get currentDocumentId(): string { return this.match.params.resource_id || "00000000-0000-0000-0000-000000000010"; }

    /**
     * 当前文档视图的状态 list 或者 table
     */
    @mobx.computed
    public get viewType() { return this.store.preferences.nodeViewMode; }

    @mobx.computed
    private get highlight() {
        let hash = this.props.location.hash;
        if (hash) {
            hash = replace(hash, "#highlight=", "");
        }
        return hash;
    }

    /**
     * 当前选中的节点
     */
    @mobx.observable
    private selectedNode: Node = null;

    @mobx.observable
    private currentFolder: Folder = null;

    @mobx.observable
    private isBookmarkActive: boolean = false;

    @mobx.observable
    private isLoaded: boolean = false;
    @mobx.observable
    private isHighlight: boolean = this.highlight ? true : false;

    public componentDidMount() {
        document.onclick = () => {
            this.selectedNode = null;
        };
        this.watcher
            .watch("currentDocumentId", () => {
                this.refresh();
            })
            .watch("viewType", () => {
                setTimeout(() => {
                    this.scrollToNode();
                }, 0);
            });
        this.refresh();
        this.eventBus.qrcode.on(this.showQrcodeModal.bind(this));
        this.eventBus.rename.on(this.renameNode.bind(this));
        this.eventBus.delete.on(this.tryDeleteNode.bind(this));
        this.eventBus.mark.on(this.markNode.bind(this));
        this.eventBus.copy.on(this.copyNode.bind(this));
        this.eventBus.info.on(this.showInfoModal.bind(this));
        this.eventBus.createFile.on(this.showCreateFileModal.bind(this));
        this.eventBus.createFolder.on(this.tryCreateFolder.bind(this));
        this.eventBus.importModal.on(this.showImportModal.bind(this));
        this.eventBus.setNodeView.on((action) => { this.store.preferences.setNodeViewMode(action); });
        this.eventBus.bookmark.on(() => BookmarkListDrawer.show(this.eventBus));
        this.eventBus.move.on(this.moveNode.bind(this));
        this.eventBus.property.on(this.property.bind(this));
        this.eventBus.download.on(this.download.bind(this));
        this.eventBus.open.on(this.openNode.bind(this));
        this.eventBus.batchDeleteModal.on(this.showBatchDeleteModal.bind(this));
        this.eventBus.batchMoveModal.on(this.showBatchMoveModal.bind(this));

        /**
         * 测试命令激活。
         */
        this.eventBus.test.on(() => {
            this.run("测试", async () => {
            });
        });
    }

    public componentWillUnmount() {
        this.watcher.dispose();
    }

    /**
     * 重写基类的 run，提供出错的提示 Modal。
     */
    protected async run(name: string, command: () => Promise<any>) {
        super.run(name, command, this.onError.bind(this));
    }

    private onError(error: Error) {
        if (Exceptions.isConflict(error)) {
            Modal.error({
                title: `操作失败，名字重复了`,
                content: `不能在同一个层级下，出现同名的文档或者文件夹`,
                centered: true,
            });
        } else {
            Modal.error({
                title: `操作失败`,
                content: `操作失败`,
                centered: true,
            });

            // Modal.info({
            //     title: "加载出错，点击返回首页",
            //     onOk: () => {
            //         this.page.goto("/list");
            //     },
            // })
        }
    }

    /**
     * 更新当前文件夹视图的内容。
     */
    private async refresh() {
        this.run("加载文件夹数据", async () => {
            await this.store.refresh();
            this.currentFolder = Folder.createById(this.currentDocumentId, this.store);
            await this.currentFolder.refresh();
            this.isLoaded = true;
            this.scrollToNode();
        });
    }

    /**
     * 编辑器、查看器打开模式
     */
    private async openNode(args) {
        let node: Node = args.node;
        // 清空当前节点的checked
        this.currentFolder.children.forEach((child) => child.isChecked = false);
        if (node.type === "folder") {
            // 永远在当前窗口打开文件夹
            this.page.goto(`/list/${node.id}`);
        } else {
            // 期望的打开方式
            let mode: "viewer" | "editor";
            mode = args.mode === "view" ? "viewer" : "editor";

            // 如果节点类型不支持预览，那就直接按照编辑器处理
            if (this.store.models.canView(node) === false) {
                mode = "editor";
            }

            if (mode === "viewer") {
                PluginModal.show(node.id);
            } else {
                // 如果没有写的权限，那就不要打开。
                let doc = await Doc.createById(node.id, this.store).refresh();
                if (doc.canWrite) {
                    let path = `/apps/${mode}/${node.id}`;
                    if (this.store.preferences.openInNewWindow) {
                        // 新窗口打开编辑器
                        this.page.open(path);
                    } else {
                        // 当前窗口打开编辑器
                        this.page.goto(path);
                    }
                } else {
                    Modal.error({
                        title: `不能编辑这个文档`,
                        content: `没有权限修改文档。`,
                        centered: true,
                    });
                }
            }
        }
    }

    /**
     * 下载
     */
    private async download(node: Node) {
        // if (node instanceof Doc) {
        // }
        this.run("下载数据", async () => {
            let blob = await node.download();
            let filename: string = `${node.name}.zip`;
            FileSaver.saveAs(blob, filename);
        });
    }

    /**
     * 移动节点。
     */
    private async moveNode(node: Node) {
        let folderId = await NodeSelectModal.selectFolder(node);
        if (folderId) {
            this.run("移动资源", async () => {
                await node.moveTo(folderId);
                await this.refresh();
            });
        }
    }

    public async onSortEnd(index) {
        if (index.oldIndex !== index.newIndex) {
            let sortNode = this.currentFolder.children[index.oldIndex];
            this.run("节点排序", async () => {
                this.currentFolder.children = arrayMove(this.currentFolder.children, index.oldIndex, index.newIndex);

                await sortNode.reorder(index.newIndex);
            });
        }
    }
    private async property(node: Node) {
        if (node instanceof Folder) {
            this.run("加载属性", async () => {
                await node.refresh();
                FolderPropertyModal.show(node);
            });
        }
    }
    private onNodeSelected(node: Node) {
        this.selectedNode = this.selectedNode !== node ? node : null;
    }

    private async showInfoModal(node: Node) {
        this.run("信息显示", async () => {
            // let doc = await Doc.createByNode(node, this.store);
            // await doc.refresh();
            if (node instanceof Doc) {
                await node.refresh();
            }
            NodeInfoModal.show(node);
        });
    }

    /**
     * 创建新文件夹
     */
    private async tryCreateFolder() {
        let isCreated = await FolderCreateModal.show(this.currentFolder);
        if (isCreated) {
            this.run("创建文件夹", async () => {
                await this.currentFolder.createChild("folder", isCreated.name);
                await this.refresh();
            });
        }
    }
    // 创建新文档
    private async showCreateFileModal() {
        console.log(`创建文档方法被调用`);
        let isCreated = await CreateDocModal.show(this.currentFolder);
        if (isCreated) {
            this.run("创建文档", async () => {
                await this.currentFolder.createChild(isCreated.type, isCreated.name);
                await this.refresh();
            });
        }
    }
    /**
     * 导入文档。
     */
    private async showImportModal() {
        let isCreated = await DocListImportModal.show(this.currentFolder);
        isCreated && this.refresh();
    }

    /**
     * 尝试删除节点。
     * @param node 选中的节点
     */
    private async tryDeleteNode(node: Node) {
        let willDelete = await NodeDeleteModal.show(node);
        willDelete && this.run("删除", async () => {
            await node.delete();
            await this.refresh();
        });
    }

    private async renameNode(node: Node) {
        let rename = await NodeRenameModal.show(node);
        rename.success && this.run("修改名字", async () => {
            await node.rename(rename.name);
            await this.refresh();
        });
    }
    private async copyNode(node: Node) {
        this.run("复制", async () => {
            await node.copyTo(this.currentFolder.id, computeSmartDuplicatedName(node.name));
            await this.refresh();
        });
    }

    private async markNode(node: Node) {
        this.run("收藏", async () => {
            console.log(node, `找到这个节点没有`);
            await this.store.bookmarks.createByNode(node);
            console.log(`收藏已经执行了`);
        });

    }

    private async showQrcodeModal(node: Node) {
        let viewerPage = this.store.models.getViewerPage(node);
        if (viewerPage) {
            QRCodeModal.show({ text: viewerPage, imageUrl: node.thumbnail });
        } else {
            console.log(`无法匹配插件：${node.type}`);
        }
    }

    /**
     * 滚向目标节点
     */
    private scrollToNode() {
        let node = document.getElementById(this.highlight);
        if (node) {
            setTimeout(() => {
                node.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "center",
                });
            }, 0);
        }
    }

    /**
     * 选择/取消选择节点
     */
    private toggleCheckNode(node: Node) {
        // console.log("node check", node.isChecked);
        node.isChecked = !node.isChecked;
        // let targetNode = this.currentFolder.children.find((item) => item.id === node.id);
        // if (targetNode) { targetNode.isChecked = !targetNode.isChecked; }
    }
    /**
     * 选择/取消选择所有节点
     */
    private toggleCheckAll() {
        // console.log("To select all");
        let isAllSelected = true;
        this.currentFolder.children.forEach((item) => isAllSelected = isAllSelected && item.isChecked);
        this.currentFolder.children.forEach((item) => item.isChecked = !isAllSelected);
    }
    /**
     * 显示批量删除对话框
     */
    private async showBatchDeleteModal() {
        // 给与删除确认。
        let targetNodes = this.currentFolder.children.filter((child) => child.isChecked);
        let willDelete = await BatchNodeDeleteModal.show(targetNodes);
        willDelete && this.run("批量删除资源", async () => {
            let promises: any[] = [];
            for (let node of targetNodes) {
                promises.push(node.delete());
            }
            await Promise.all(promises);
            await this.refresh();
        });
    }
    /**
     * 显示批量移动对话框
     */
    private async showBatchMoveModal() {
        let targetNodes = this.currentFolder.children.filter((child) => child.isChecked);
        let folderId = await NodeSelectModal.selectFolder(targetNodes);
        if (folderId) {
            this.run("批量移动资源", async () => {
                let promises: any[] = [];
                for (let node of targetNodes) {
                    // await node.moveTo(folderId);
                    promises.push(node.moveTo(folderId));
                }
                await Promise.all(promises);
                await this.refresh();
            });
        }
    }

    public render() {
        // 读取结束之后，再渲染内容。
        if (!this.isLoaded) {
            return null;
        }
        /**
         * 渲染列表视图需要的参数
         */
        const DocViewProps = {
            readonly: (this.currentFolder && !this.currentFolder.canWrite),
            folder: this.currentFolder,
            children: this.currentFolder.children,
            viewType: this.viewType,
            eventBus: this.eventBus,
            selectedNode: this.selectedNode,
            onSortStart: () => this.selectedNode = null,
            onSortEnd: this.onSortEnd.bind(this),
            onNodeSelected: this.onNodeSelected.bind(this),
            highlight: this.highlight,
            onNodeChecked: this.toggleCheckNode.bind(this),
            onNodeCheckAll: this.toggleCheckAll.bind(this),
        };
        return (
            <Container>
                <GlobalStyle />
                <MainContainer className={classnames({ small: this.isBookmarkActive })}>
                    <ToolbarContainer>
                        <Toolbar
                            node={this.selectedNode}
                            eventBus={this.eventBus}
                            folder={this.currentFolder}
                            onSearch={(value) => this.page.goto(`/search?keyword=${encodeURIComponent(value)}`)}
                        />
                    </ToolbarContainer>
                    <BreadcrumbContainer>
                        <Breadcrumb
                            eventBus={this.eventBus}
                            node={this.selectedNode}
                            folder={this.currentFolder}
                        />
                    </BreadcrumbContainer>
                    <ContentContainer onDragStart={() => false} onSelect={() => false}>
                        {
                            // 根据模式渲染不同的视图组件
                            this.viewType === "card" ? <DocCardModeView {...DocViewProps} /> : <DocListModeView {...DocViewProps} />
                        }
                    </ContentContainer>
                </MainContainer>
            </Container>
        );
    }
}

function computeSmartDuplicatedName(sourceName: string) {
    // 获取最后15个字符
    if (sourceName.length > 16) {
        let datetimePart = sourceName.substring(sourceName.length - 15);
        // 尝试转义成日期
        try {
            let m = moment(datetimePart, "YYYYMMDD-HHmmss");
            if (m.isValid()) {
                sourceName = sourceName.substring(0, sourceName.length - 16);
            }
        } catch (err) {
        }
    }

    return sourceName + moment(Date.now()).format("_YYYYMMDD-HHmmss");
}

const Container = styled.div`
    position : absolute;
    top:    0;
    left:   0;
    right:  0;
    bottom: 0;
`;
const ToolbarContainer = styled.div`
    position : absolute;
    top:    0;
    left:   0;
    right:  0;
    height: 50px;
    padding: 0 20px;
    box-shadow: 0 0 5px #ccc;
    z-index: 10;
`;
const BreadcrumbContainer = styled.div`
    position : absolute;
    top:    50px;
    left:   0;
    right:  0;
    height: 50px;
    padding: 0 20px;
    background-color: #f0f0f0;
`;

const ContainerHeight: number = 0;
const ContentContainer = styled.div`
    position : absolute;
    top:    100px;
    left:   0;
    right:  0;
    bottom: ${ContainerHeight}px;
    /* 这里靠下，给菜单留一些空间 */
    /* padding: 10px 10px 110px 10px; */
    padding: 10px;
    background-color: #fff;
    overflow-y: hidden;
    user-select: none;
`;
const MainContainer = styled.div`
    position : absolute;
    top:    0;
    left:   0;
    right:  0;
    bottom: 0;
    &.small {
        right:  200px;
        border-right: 1px solid #ececec;
    }
`;
const GlobalStyle = createGlobalStyle`
    .sortable-active {
        box-shadow: 0 0 6px #ccc;
        background-color: #f0f0f0;
    }
    .xk-table-row {
        cursor: pointer;
        border-bottom: 1px dotted #ececec;
        &:hover {
            background-color: #f1f5fa;
        }
        &.active {
            background-color: #f1f5fa;
        }
    }
    .search-highlight{
        background-color:#fffc9f;
        .blue-check-icon {
            display: block;
        }
        &:hover {
                background-color: #f1f5fa;
        }
    }
`;
