import { Drawer } from "antd";
import * as mobx from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import ReactDOM from "react-dom";
import styled, { createGlobalStyle } from "styled-components";

import Node from "../../models/Node";
import Watcher from "../../utils/Watcher";
import BaseComponent from "../BaseComponent";
import { JsMenu, JsMenuItem, JsNode, JsTree } from "../common/JsTree";

import EventBus from "./EventBus";

interface IProps {
    eventBus: EventBus;
    isVisible: boolean;
    finish: () => void;
}

@observer
export default class BookmarkListDrawer extends BaseComponent<IProps, {}> {

    private readonly watcher: Watcher<BookmarkListDrawer> = new Watcher(this);

    public get bookmarks() { return this.store.bookmarks; }

    @mobx.computed
    public get books() { return this.store.bookmarks.items.slice(); }

    @mobx.computed
    public get isVisible() { return this.props.isVisible; }

    @mobx.observable
    public treeContainer: HTMLElement = null;

    private tree: JsTree = null;

    public componentDidMount() {
        this.watcher
            .watch("books", (evt) => {
                this.refresh();
            })
            .watch("treeContainer", (evt) => {
                if (this.isVisible && this.treeContainer !== null) {
                    this.tree = new JsTree(this.treeContainer, {
                        dots: true,
                        checkBox: false,
                        showRoot: false,
                        contextMenu: true,
                    });

                    this.tree.menu = this.createMenu();
                    this.tree.onNodeMoving = this.moveCheck.bind(this);
                    this.tree.onNodeMoved = this.moveNode.bind(this);
                    this.tree.onNodeRenamed = this.renameNode.bind(this);
                    this.tree.onNodeSelected = this.onNodeSelected.bind(this);
                    this.refresh();
                } else {

                }
            });
    }

    public componentWillUnmount() {
        this.watcher.dispose();
    }

    /**
     * 重新拉取数据。
     */
    private refresh() {
        let books = this.bookmarks.items;
        let rootNode = new JsNode(this.tree)
            .setId("root")
            .setText("root")
            .setType("folder");
        for (const item of books) {
            rootNode.children.push(new JsNode(this.tree)
                .setId(item.id)
                .setText(item.name)
                .setIcon(`fas fa-fw ${item.type === "folder" ? "fa-lg fa-folder" : "fa-file"}`)
                .setType(item.type),
            );
        }
        this.tree.root = rootNode;

        this.tree.refresh();
    }

    private moveCheck(node: JsNode, parent: JsNode) {
        return !parent;
    }

    private moveNode(node: JsNode, parent: JsNode, position: number) {
        this.bookmarks.move(node.id, position);
    }

    private renameNode(node: JsNode, newName: string) {
        this.bookmarks.rename(node.id, newName);
    }

    private onNodeSelected(node: JsNode) {
        this.props.eventBus.open.emit({
            node: {
                id: node.id,
                type: node.type,
            } as any,
            mode: null,
        });
    }

    // 动态创建contextmenu
    private createMenu() {
        let menu = new JsMenu();
        menu.items.push(new JsMenuItem()
            .setText("重命名")
            .setIcon("fas fa-pencil-alt")
            // .setSeparator(true)
            .setOnClicked((node: JsNode) => {
                this.tree.edit(node);
            }));
        menu.items.push(new JsMenuItem()
            .setText("删除收藏")
            .setIcon("far fa-trash-alt")
            .setOnClicked((node: JsNode) => {
                this.bookmarks.deleteById(node.id);
                this.refresh();
            }));
        return menu;
    }

    public render() {
        return (
            <>
                <GlobalStyle />
                <Drawer
                    title="收藏夹"
                    placement="right"
                    width={350}
                    closable={true}
                    maskClosable={true}
                    visible={this.props.isVisible}
                    onClose={this.props.finish}
                >
                    {/* 注意：如果抽屉组件是不可见的，那么 children 都是被销毁的。 */}
                    <TreeContainer ref={(el) => this.treeContainer = el} />
                </Drawer>
            </>
        );
    }

    public static async show(eventBus: EventBus): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            let container = document.createElement("div");
            document.body.appendChild(container);

            let dispose = (success: boolean) => {
                ReactDOM.render(
                    <BookmarkListDrawer
                        eventBus={eventBus}
                        isVisible={false}
                        finish={() => { }}
                    />, container);
                setTimeout(() => {
                    ReactDOM.unmountComponentAtNode(container);
                    document.body.removeChild(container);
                    resolve(success);
                }, 300);
            };

            ReactDOM.render(
                <BookmarkListDrawer
                    eventBus={eventBus}
                    isVisible={true}
                    finish={() => { dispose(false); }}
                />, container);
        });
    }
}
const TreeContainer = styled.div`
    /* position: absolute; */
    left:   0;
    top:    30px;
    right:  0;
    bottom: 0;
    i.jstree-icon.fa-folder {
        color: #FBCD22;
    }
    i.jstree-icon.fa-file {
        color: #20A0FF;
    }
`;
const GlobalStyle = createGlobalStyle`
    .jstree-contextmenu {
        z-index: 10000;
    }
`;