import { Button, Popconfirm, Table, Tag } from "antd";
import * as mobx from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import styled from "styled-components";

import User from "../../models/users/User";
import UserManager from "../../models/users/UserManager";
import BaseComponent from "../BaseComponent";

import UserEditModal from "./UserEditModal";
import UserPermissionsModal from "./UserPermissionsModal";

const { Column } = Table;

interface IProps {
    userManager: UserManager;
}
@observer
export default class UserListView extends BaseComponent<IProps, any> {
    @mobx.computed
    public get users() {
        return this.props.userManager.users || [];
    }
    @mobx.observable
    private userManager: UserManager = this.props.userManager;

    @mobx.observable
    private activeUser: User = null;

    private updateShow() {
        UserEditModal.show(this.userManager, this.activeUser, "update");
    }

    private createShow() {
        UserEditModal.show(this.userManager, this.activeUser, "create");
    }

    private permissionsShow() {
        UserPermissionsModal.show(this.userManager, this.activeUser);
    }

    private async deleteUser(user: User) {
        this.run("删除用户", async () => {
            await user.delete();
        });
    }

    private getAdminCounts() {
        let isAdminCount = 0;
        this.users.map(item => {
            item.isAdmin && isAdminCount++;
        });
        return isAdminCount;
    }

    public render() {
        let counts = this.getAdminCounts();
        return (
            <Container>
                <Toolbar>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <Button
                                        type="primary"
                                        icon="plus"
                                        onClick={async () => this.createShow()}
                                    >
                                        添加
                                    </Button>
                                    <Counter>
                                        共有 {this.users.length} 个用户，其中有 {counts} 个是管理员。
                                    </Counter>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Toolbar>
                <Table
                    scroll={{ y: window.innerHeight - 240 }}
                    dataSource={this.users}
                    rowKey="id"
                    useFixedHeader={true}
                    bordered={true}
                    pagination={false}
                >
                    <Column
                        dataIndex="name"
                        align="center"
                        title="用户名"
                        width={200}
                        render={(_, user: User) => <div>{user.name}</div>}
                    />
                    <Column
                        dataIndex="nickname"
                        align="center"
                        title="显示名"
                        width={200}
                        render={(_, user: User) => (
                            <div>{user.displayName}</div>
                        )}
                    />
                    <Column
                        dataIndex="createAt"
                        align="center"
                        title="创建日期"
                        width={200}
                        render={(_, user: User) => (
                            <code>
                                {user.createdAt.format("YYYY-MM-DD HH:mm:ss")}
                            </code>
                        )}
                    />
                    <Column
                        dataIndex="isAdmin"
                        align="center"
                        title="角色"
                        width={200}
                        render={(_, user: User) =>
                            user.isAdmin ? "管理员" : "用户"
                        }
                    />
                    <Column
                        dataIndex="groups"
                        align="center"
                        title="所属分组"
                        width={200}
                        render={(_, user: User) => {
                            return (
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "left"
                                    }}
                                >
                                    {user.groups.map(item => {
                                        return (
                                            <Tag key={item.id} color="#87d068">
                                                {item.name}
                                            </Tag>
                                        );
                                    })}
                                </div>
                            );
                        }}
                    />

                    <Column
                        dataIndex=""
                        align="center"
                        title="操作"
                        width={200}
                        render={(_, user: User) => (
                            <Actions>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        this.activeUser = user;
                                        this.updateShow();
                                    }}
                                >
                                    编辑
                                </Button>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        this.activeUser = user;
                                        this.permissionsShow();
                                    }}
                                >
                                    权限
                                </Button>
                                <Popconfirm
                                    placement="bottom"
                                    title="确定删除用户?"
                                    okText="确定"
                                    okType="danger"
                                    cancelText="取消"
                                    onConfirm={() => this.deleteUser(user)}
                                >
                                    <Button type="danger">删除</Button>
                                </Popconfirm>
                            </Actions>
                        )}
                    />
                </Table>
            </Container>
        );
    }
}

const Container = styled.div`
    overflow: visible !important;
`;
const Actions = styled.div`
    .ant-btn {
        margin-left: 10px;
        margin-right: 10px;
    }
`;
const Toolbar = styled.div`
    height: 50px;
`;
const Counter = styled.div`
    display: inline-block;
    padding-left: 10px;
    font-size: 13px;
`;
