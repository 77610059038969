import { Button, Col, Row } from "antd";
import * as mobx from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { Prompt, RouteComponentProps } from "react-router-dom";
import styled from "styled-components";

import Doc from "../../models/Doc";
import Node from "../../models/Node";
import AuthoredComponent from "../AuthoredComponent";
import QRCodeModal from "../common/QRCodeModal";

interface IProps extends RouteComponentProps<any> {
    docId: string;
    mode: "view" | "edit";
}
@observer
export default class PluginView extends AuthoredComponent<IProps> {

    @mobx.observable
    private node: Node = null;

    @mobx.computed
    private get mode() { return this.props.mode; }

    @mobx.computed
    private get viewerPageUrl() { return this.store.models.getViewerPage(this.node); }

    @mobx.computed
    private get editorPageUrl() { return this.store.models.getEditorPage(this.node); }

    @mobx.computed
    private get canView() { return this.store.models.canView(this.node); }

    @mobx.observable
    private codeMode: boolean = false;

    private iframeWindow: any = null;

    public get cannodeClose() {
        return this.$cannodeClose;
    }

    @mobx.observable
    private $cannodeClose: boolean = false;

    public componentDidMount() {
        this.run("加载数据", async () => {
            this.node = await Doc.createById(this.props.docId, this.store).refresh();
        });
    }

    private openInNewWindow() {
        window.open(this.mode === "view" ? this.viewerPageUrl : this.editorPageUrl);
    }

    private openInViewerPage() {
        window.open(`/apps/viewer/${this.node.id}`);
    }

    private openInEditorPage() {
        window.open(`/apps/editor/${this.node.id}`);
    }

    private openCode() {
        this.codeMode = !this.codeMode;
    }

    private showQrcodeModal() {
        QRCodeModal.show({ text: this.viewerPageUrl, imageUrl: this.node.thumbnail });
    }
    public tryBack() {
        this.page.gotoDocumentListPage(this.node.parentId);
    }

    public loopCheck() {
        if (this.iframeWindow && this.iframeWindow.canClose) {
            this.$cannodeClose = !this.iframeWindow.canClose();
        }
        setTimeout(() => {
            this.loopCheck();
        }, 50);
    }
    public render() {
        if (this.node === null) {
            return <div>没有数据</div>;
            // return null;
        }

        return (
            <Container>
                {this.mode === "edit" && < Prompt when={this.cannodeClose} message="放弃修改，离开此页面？" />}
                {this.node.data ?
                    <>
                        <Toolbar>
                            <Row style={{ position: "relative", height: "100%" }} type="flex" justify="center" align="middle">
                                <Col span={12} style={{ textAlign: "left" }}>
                                    {/* <Button icon="caret-left" onClick={() => this.page.back()}>返回文件夹</Button> */}
                                    <Button icon="caret-left" onClick={() => this.tryBack()}>返回文件夹</Button>
                                    <Title>{this.node.name}</Title>
                                </Col>
                                <Col span={12} style={{ textAlign: "right" }}>
                                    <Button.Group>
                                        {this.mode === "view" &&
                                            <Button icon="edit" onClick={() => this.openInEditorPage()}>打开编辑器</Button>
                                        }
                                        {this.mode === "edit" && this.canView &&
                                            <>
                                                <Button icon="eye-o" onClick={() => this.openInViewerPage()}>打开查看器</Button>
                                                <Button icon="qrcode" onClick={() => this.showQrcodeModal()}>生成二维码</Button>
                                            </>
                                        }
                                        {this.isDebug && <Button onClick={() => this.openInNewWindow()} icon="export">新窗口打开</Button>}
                                        {/* <Button type={this.codeMode ? "primary" : "default"} icon="code-o" onClick={() => this.openCode()}>程序员模式</Button> */}
                                    </Button.Group>
                                </Col>
                            </Row>
                        </Toolbar>
                        <Content>
                            <iframe onLoad={(e) => {
                                this.iframeWindow = (e.target as HTMLIFrameElement).contentWindow;
                                this.loopCheck();
                            }} src={this.mode === "view" ? this.viewerPageUrl : this.editorPageUrl} />
                            {/* {this.codeMode && <CodeBlock>{JSON.stringify(this.node, null, 4)}</CodeBlock>} */}
                        </Content>
                    </>
                    :
                    <div>
                        没有文档数据，需要重新创建文档。
                    </div>
                }
            </Container>
        );
    }
}
const Container = styled.div`
    position: absolute;
    left:   0;
    top:    0;
    right:  0;
    bottom: 0;
    overflow: hidden;
`;
const Toolbar = styled.div`
    position: absolute;
    left:   0;
    top:    0;
    right:  0;
    height: 50px;
    box-shadow: 0 0 5px #ccc;
    /* border-bottom: 1px solid #ececec; */
    padding: 0 20px;
    z-index: 10;
    button {
        width: 120px;
        margin:  0;
        padding: 0;
        height: auto;
        line-height: 30px;
    }
`;
const Title = styled.span`
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    margin: 0 0 0 20px;
`;
const Content = styled.div`
    position: absolute;
    left:   0;
    right:  0;
    top:    50px;
    bottom: 0;
`;
const CodeBlock = styled.pre`
    position: absolute;
    left:   0;
    top:    0;
    right:  0;
    bottom: 0;
    z-index: 100;
    background: #fff;
    padding: 20px;
    overflow: scroll;
`;