import { Button, Icon, Layout, Menu } from "antd";
import * as mobx from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import styled from "styled-components";

import BaseComponent from "../BaseComponent";

import AccountDrawer from "./AccountDrawer";
import PreferencesDrawer from "./PreferencesDrawer";

const { Sider } = Layout;
interface IProps {
    visible: boolean;
    activePath: string;
    onClicked?(action: string): void;
}

@observer
export default class GlobalBar extends BaseComponent<IProps, any> {

    /**
     * 左侧菜单收缩状态
     */
    @mobx.observable
    private isSiderCollapsed: boolean = false;

    @mobx.observable
    private isPreferencesVisible: boolean = false;

    @mobx.observable
    private isUserProfileVisible: boolean = false;

    @mobx.computed
    public get activePath() { return this.props.activePath; }

    /**
     * 是否是盒知运行环境。
     */
    @mobx.computed
    public get isHezhiFork() { return this.store.config.authBasicProvider !== null; }

    private onMenuItemClicked(menuItemKey: string) {
        if (menuItemKey === "logout") {
            this.logout();
        } else if (menuItemKey.indexOf("http") === 0) {
            window.open(menuItemKey);
        } else {
            if (menuItemKey === "preferences") {
                this.isPreferencesVisible = true;
            } else if (menuItemKey === "personal") {
                this.isUserProfileVisible = true;
            } else {
                this.page.goto(`/${menuItemKey}`);
            }
        }
    }

    private async logout() {
        await this.store.account.logout();
    }

    public render() {
        return (
            (this.props.visible) ? (<Sider style={SiderStyle} width={130} collapsible collapsed={this.isSiderCollapsed} onCollapse={() => {
                this.isSiderCollapsed = !this.isSiderCollapsed;
            }}>
                <Logo>{this.store.account.username}</Logo>
                <Menu
                    theme="dark"
                    mode="inline"
                    selectedKeys={[this.activePath]}
                    // inlineCollapsed={this.isSiderCollapsed}
                    onClick={(item) => this.onMenuItemClicked(item.key)}
                >
                    {/* {(this.store.account.isSuperAdmin || this.store.account.PermissionList.find((e) => e === "data")) &&
                        MenuItems({ key: "list", type: "folder", text: "数据管理" })} */}
                    {MenuItems({ key: "list", type: "folder", text: "数据管理" })}
                    {!this.isHezhiFork && (this.store.account.isSuperAdmin || this.store.account.PermissionList.find((e) => e === "users")) &&
                        MenuItems({ key: "users", type: "team", text: "用户管理" })}
                    {/* {this.isDebug && MenuItems({ key: "templates", type: "table", text: "模板管理" })} */}

                    {/* 活动日志 */}
                    {(this.store.account.isSuperAdmin) &&
                        MenuItems({ key: "activities", type: "database", text: "操作日志" })}
                    {/* {this.isDebug && MenuItems({ key: "plugins", type: "appstore", text: "插件列表" })} */}
                    {/* {this.isDebug && MenuItems({ key: "providers", type: "appstore-o", text: "应用列表" })} */}
                    {this.store.config.menus && this.store.config.menus.length > 0 && <Divider />}
                    {this.store.config.menus && this.store.config.menus.length > 0 &&
                        this.store.config.menus.map((menuItem) => {
                            if ((this.store.account.PermissionList.find((e) => e === menuItem.permission) || this.store.account.isSuperAdmin)) {
                                return MenuItems({ key: menuItem.url, type: menuItem.type || "file", text: menuItem.name })
                            }
                        })
                    }

                    <Divider />
                    {MenuItems({ key: "preferences", type: "setting", text: "偏好设置" })}
                    {!this.isHezhiFork && MenuItems({ key: "personal", type: "user", text: "个人中心" })}

                    <Menu.Item style={{ position: "absolute", bottom: 50 }} key={"logout"}>
                        <Icon type="logout" />
                        <span>退出登录</span>
                    </Menu.Item>

                </Menu>

                {this.store.account.isAuthenticated && (
                    <>
                        <PreferencesDrawer isVisible={this.isPreferencesVisible} finish={() => this.isPreferencesVisible = false} />
                        <AccountDrawer isVisible={this.isUserProfileVisible} finish={() => this.isUserProfileVisible = false} />
                    </>
                )}
            </Sider>) : ("")
        );
    }
}
function MenuItems(props: {
    key: string,
    type: string,
    text: string,
}) {
    return (
        <Menu.Item key={props.key} >
            <Icon type={props.type} />
            <span>{props.text}</span>
        </Menu.Item>
    );
}

const SiderStyle = {
    position: "relative" as "relative",
};
const Logo = styled.div`
    height: 46px;
    line-height: 46px;
    color: #999;
    text-align: center;
`;
const Divider = styled.div`
    height: 20px;
`;