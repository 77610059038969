import Node from "../../models/Node";
import TypedEvent from "../../TypedEvent";

export default class EventBus {

    public readonly info = new TypedEvent<Node>();
    public readonly property = new TypedEvent<Node>();

    public readonly qrcode = new TypedEvent<Node>();
    public readonly copy = new TypedEvent<Node>();
    public readonly rename = new TypedEvent<Node>();
    public readonly delete = new TypedEvent<Node>();
    public readonly mark = new TypedEvent<Node>();
    public readonly move = new TypedEvent<Node>();
    public readonly download = new TypedEvent<Node>();
    public readonly createFile = new TypedEvent<Node>();
    public readonly createFolder = new TypedEvent<Node>();
    public readonly importModal = new TypedEvent<Node>();
    public readonly setNodeView = new TypedEvent<any>();
    public readonly bookmark = new TypedEvent();
    // 尝试打开一个新的链接
    public readonly open = new TypedEvent<{
        node: Node,
        mode: "edit" | "view",
    }>();

    /**
     * 测试命令，留给测试 UI。
     */
    public readonly test = new TypedEvent<Node>();
    // 批量删除文件对话框
    public readonly batchDeleteModal = new TypedEvent<any>();
    // 批量移动文件对话框
    public readonly batchMoveModal = new TypedEvent<any>();
}