import { Tabs } from "antd";
import * as mobx from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import styled from "styled-components";

import UserManager from "../../models/users/UserManager";
import AuthoredComponent from "../AuthoredComponent";

import UserGroupListView from "./UserGroupListView";
import UserListView from "./UserListView";

@observer
export default class UserRootView extends AuthoredComponent<any> {
    /**
     * 用户管理器。
     */
    @mobx.observable
    private userManager: UserManager = new UserManager(this.store);

    @mobx.observable
    private isLoaded: boolean = false;

    public componentDidMount() {
        this.run("读取用户列表", async () => {
            await this.userManager.refresh();
            this.isLoaded = true;
        });
    }

    public render() {
        // 读取结束之后，再渲染内容。
        if (!this.isLoaded) {
            return null;
        }

        return (
            <Container>
                <Tabs animated={false}>
                    <Tabs.TabPane tab="用　户" key="users">
                        <UserListView userManager={this.userManager} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="用户组" key="userGroups">
                        <UserGroupListView userManager={this.userManager} />
                    </Tabs.TabPane>
                </Tabs>
            </Container>
        );
    }
}

const Container = styled.div`
    position: absolute;
    top:    0;
    left:   0;
    right:  0;
    bottom: 0;
    overflow: hidden;
    .ant-tabs {
        position: absolute;
        top:    20px;
        left:   20px;
        right:  20px;
        bottom: 20px;
        .ant-tabs-content {
            position: absolute;
            top:    60px;
            left:   0;
            right:  0;
            bottom: 0;
            /* background-color: red; */
            overflow: auto;
        }
    }
`;