import _ from "lodash";
import * as mobx from "mobx";

import Store from "../Store";

import User from "./User";
import UserGroup from "./UserGroup";

export default class UserManager {

    public readonly store: Store = null;
    public get service() { return this.store.service; }

    @mobx.computed
    public get users() { return this.$users; }
    @mobx.observable
    private $users: User[] = null;

    @mobx.computed
    public get userGroups() { return this.$userGroups; }
    @mobx.observable
    private $userGroups: UserGroup[] = null;

    constructor(store: Store) {
        this.store = store;
    }

    public getUserById(userId: string) {
        return this.users.find((u) => u.id === userId);
    }

    public getUserGroupById(userGroupId: string) {
        return this.userGroups.find((u) => u.id === userGroupId);
    }
    /**
     * 获取用户信息。
     */
    public async listUser() {
        let result = await this.service.listUsers();

        let items: any;
        if (result.children) {
            items = result.children;
        } else {
            items = result.items;
        }

        this.$users = [];
        for (const dataObject of items) {
            this.users.push(User.createByDataObject(this, dataObject));
        }
    }
    /**
     * 获取用户组信息。
     */
    public async listGroup() {
        let result = await this.service.listUserGroups();

        if (result.items) {
            result = result.items;
        }

        this.$userGroups = [];
        for (const dataObject of result) {
            let userGroup = UserGroup.createByDataObject(this, dataObject);
            this.userGroups.push(userGroup);
        }
    }
    public async refresh() {

        {
            let result = await this.service.listUserGroups();

            if (result.items) {
                result = result.items;
            }

            this.$userGroups = [];
            for (const dataObject of result) {
                let userGroup = UserGroup.createByDataObject(this, dataObject);
                this.userGroups.push(userGroup);
            }
        }

        {
            let result = await this.service.listUsers();
            let items: any;
            if (result.children) {
                items = result.children;
            } else {
                items = result.items;
            }

            this.$users = [];
            for (const dataObject of items) {
                this.users.push(User.createByDataObject(this, dataObject));
            }
        }
    }

    public async createUser(
        name: string,
        password: string,
        roles: string[],
        groups: string[],
        displayName?: string,
    ): Promise<User> {

        let data = await this.service.createUser(name, password, roles, groups, displayName);

        let user = User.createByDataObject(this, data);

        await this.refresh();

        return user;
    }

    public async createUserGroup(name: string): Promise<UserGroup> {
        let data = await this.service.createUserGroup(name);
        let groups = UserGroup.createByDataObject(this, data);
        await this.refresh();
        return groups;
    }
}
