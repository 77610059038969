import { Checkbox, Col, Row } from "antd";
import $ from "jquery";
import * as mobx from "mobx";
import { observer } from "mobx-react";
import moment from "moment";
import * as React from "react";
import ReactDOM from "react-dom";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { Column, defaultTableRowRenderer, Table } from "react-virtualized";

import Folder from "../../models/Folder";
import Node from "../../models/Node";
import BaseComponent from "../BaseComponent";

import ContextMenu from "./ContextMenu";
import EventBus from "./EventBus";

/**
 * 列表模式排序组件
 * react-sortable-hoc + react-virtualized
 * https://github.com/bvaughn/react-virtualized
 * react-virtualized 使用常规html元素模拟table排版，所以可以配合sortable完成table的拖拽排序效果
 */
const ColumnItems = [
    {
        label: "文件",
        key: "name",
    }, {
        label: "创建时间",
        key: "createdAt",
    }, {
        label: "修改时间",
        key: "updatedAt",
    }, {
        label: "属性",
        key: "type",
    },
];
const SortableTable = SortableContainer((props: any) =>
    <Table
        // onColumnClick={(data) => props.onColumnClick(data)}
        rowRenderer={(itemProps) => <SortableTableRow disabled={props.readonly} {...itemProps} />}
        {...props} />,
);
const SortableTableRow = SortableElement((props: any) => {
    return defaultTableRowRenderer(props);
});

interface IProps {
    onSortStart(node: Node): void;
    onSortEnd: void;
    onNodeSelected(node: Node): void;
    onNodeChecked(node: Node): void;
    onNodeCheckAll(): void;
    eventBus: EventBus;
    selectedNode: Node;
    viewType: string;
    children: Node[];
    folder: Folder;
    readonly: boolean;
    highlight: string;
}

@observer
export default class DocListModeView extends BaseComponent<IProps, any> {

    private currentColumnKey: string = "";
    private sortableTable: Table = null;
    @mobx.observable
    private tableWidth: number = 1000;
    @mobx.observable
    private tableHeight: number = 600;

    // 列表模式下，菜单的位置
    @mobx.observable
    private menuX: number = 0;
    @mobx.observable
    private menuY: number = 0;
    @mobx.computed
    public get highlight() {
        return this.props.highlight;
    }

    @mobx.computed
    public get children() {
        return this.props.children.slice();
    }

    @mobx.computed
    public get readonly() {
        return this.props.readonly;
    }
    @mobx.computed
    private get isAllSelected() {
        let flag = true;
        this.children.forEach((item) => {
            flag = flag && item.isChecked;
        });
        return flag;
    }

    public componentDidMount() {
        let parent = ReactDOM.findDOMNode(this).parentElement;
        this.tableHeight = $(parent).height();
        this.tableWidth = $(parent).width();
        $(window).on("resize", () => {
            this.tableHeight = $(parent).height();
            this.tableWidth = $(parent).width();
        });
    }
    public UNSAFE_componentWillReceiveProps(props) {
        console.log("will props", this.props.children);
    }

    private iconUrl(type: string) {
        return this.store.models.getIconUrl(type);
    }

    private openNode(node: Node) {
        this.props.eventBus.open.emit({
            node: node,
            mode: null,
        });
    }

    private showContextMenu(data) {
        this.menuX = data.event.clientX;
        this.menuY = data.event.clientY;
        data.event.nativeEvent.stopImmediatePropagation();
        data.event.nativeEvent.preventDefault();
        this.props.onNodeSelected(data.rowData);
    }
    /**
     * 根据cell type,返回不同的模板
     */
    public switchCell(cellData: any) {
        let node: Node = cellData.rowData;
        let type = cellData.dataKey;
        let result;
        switch (type) {
            case "name":
                result = (
                    <Row id={node.id} gutter={15} type="flex" justify="start" align="middle">
                        <Col span={8}>
                            <img onMouseDown={(evt) => evt.nativeEvent.preventDefault()} src={node.thumbnail} style={{ width: 30, marginRight: 15 }} onError={(evt) => evt.currentTarget.src = "/assets/img/thumbnail-empty.png"} />
                            <span>{node.name}</span>
                        </Col>
                        <Col span={16} style={{ height: "100%" }}>
                        </Col>
                    </Row>
                );
                break;
            case "type":
                result = (
                    <img onMouseDown={(evt) => evt.nativeEvent.preventDefault()} src={this.iconUrl(node.type)} style={{ width: 30, borderRadius: "50%" }} />
                );
                break;
            case "createdAt":
            case "updatedAt":
                result = (
                    <span>{moment(node[type]).format("YYYY-MM-DD HH:mm:ss")}</span>
                );
                break;
            case "isChecked":
                console.log("isChecked", node[type], node.isChecked);
                result = (
                    <Checkbox checked={node[type]} />
                );
                break;
        }
        return result;
    }
    public render() {
        console.log(this.children);
        return (
            <>
                <SortableTable ref={(el) => { this.sortableTable = el; console.log(el); }}
                    // 指定目标容器,修复滚动条滚动最后，无法重新排序的问题
                    getContainer={() => document.getElementsByClassName("ReactVirtualized__Grid ReactVirtualized__Table__Grid")[0]}
                    readonly={this.readonly}
                    axis={"y"} pressDelay={0} distance={5} helperClass={"sortable-active"} onSortEnd={this.props.onSortEnd} onSortStart={this.props.onSortStart}
                    headerHeight={80}
                    height={this.tableHeight}
                    rowHeight={60}
                    width={this.tableWidth}
                    rowCount={this.children.length}
                    rowClassName={(data) => {
                        if (this.children[data.index] && ((this.children[data.index] as Node).id === this.highlight)) {
                            return "search-highlight";
                        }
                        if (data.index === -1) {
                            return "";
                        } else {
                            return (this.props.selectedNode === this.children[data.index]) ? "xk-table-row active" : "xk-table-row";
                        }
                    }}
                    rowGetter={({ index }) => this.children[index]}
                    onRowClick={(data) => {
                        console.log(data);
                        // this.openNode(data.rowData);
                        if (this.currentColumnKey === "isChecked") {
                            this.props.onNodeChecked(data.rowData);
                            this.forceUpdate();
                        } else {
                            this.openNode(data.rowData);
                        }
                    }}
                    onHeaderClick={(data) => {
                        // console.log("onHeaderClick", data);
                        if (data.dataKey === "isChecked") {
                            this.props.onNodeCheckAll();
                            this.forceUpdate();
                        }
                    }}
                    onColumnClick={(data) => {
                        // console.log("columnClick", data);
                        this.currentColumnKey = data.dataKey;
                    }}
                    onRowRightClick={(data) => {
                        this.showContextMenu(data);
                    }}
                >
                    <Column
                        key="check"
                        dataKey="isChecked"
                        label="全选"
                        width={80}

                        headerRenderer={() => {
                            return (
                                <span className="ReactVirtualized__Table__headerTruncatedText">
                                    <Checkbox checked={this.isAllSelected}>全选</Checkbox>
                                </span>
                            );
                        }}
                        cellRenderer={(cellData) => {
                            return this.switchCell(cellData);
                        }}
                    />
                    {ColumnItems.map((item) => <Column
                        key={item.key}
                        dataKey={item.key}
                        label={item.label}
                        flexGrow={item.key === "name" ? 1 : 0}
                        width={item.key === "type" ? 80 : 200}

                        cellRenderer={(cellData) => {
                            return this.switchCell(cellData);
                        }}
                    />)}
                </SortableTable>
                {this.props.selectedNode && this.props.folder &&
                    <ContextMenu
                        readonly={!this.props.folder.canWrite}
                        x={this.menuX} y={this.menuY}
                        node={this.props.selectedNode}
                        active={this.props.selectedNode !== null}
                        eventBus={this.props.eventBus}
                        viewType={this.props.viewType}
                    />
                }
            </>
        );
    }
}