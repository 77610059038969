import { Button, Col, Form, Icon, Modal, Row, Select, Upload } from "antd";
import _ from "lodash";
import * as mobx from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

import Folder from "../../../models/Folder";
import BaseComponent from "../../BaseComponent";

import ModalUtils from "./ModalUtils";

const Dragger = Upload.Dragger;
const FormItem = Form.Item;

interface IProps {
    visible: boolean;
    folder: Folder;
    onSubmitted?: () => void;
    onCancelled?: () => void;
    afterClose?: () => void;
}
@observer
export default class DocListImportModal extends BaseComponent<IProps, any> {

    private get folder() { return this.props.folder; }

    /**
     * 导入的文档类型
     */
    @mobx.observable
    private docType: string = null;

    @mobx.observable
    private uploading: boolean = false;

    @mobx.observable
    private duplicateType: string = "coverage";

    @mobx.observable
    private fileList: any[] = [];

    public componentDidMount() {
        if (this.folder.usedStrictType) {
            this.docType = this.folder.inheritedStrictType;
        }
    }

    private async uploadFiles() {
        this.run("导入文档", async () => {
            this.uploading = true;
            for (const file of this.fileList) {
                file.status = "uploading";
                this.fileList = this.fileList;
            }
            for (const file of this.fileList) {
                try {
                    let docId = await this.createDoc(_.trimEnd(file.name, ".zip"));
                    if (docId) {
                        await this.store.service.updateNodeDataByZip(docId, file.originFileObj);
                    }
                    file.status = "done";
                    this.fileList = this.fileList;
                } catch (error) {
                    console.log(error);
                }
            }
            this.fileList = [];
            this.uploading = false;
            this.props.onSubmitted();
        });
    }

    /**
     * 创建一个空白文档。
     */
    private async createDoc(name: string): Promise<string> {
        try {
            let docId: string = await this.folder.createChild(this.docType, name);

            return docId;
        } catch (error) {
            console.log(error);
            return null;
        }
    }

    public render() {
        const props = {
            accept: ".zip",
            name: "file",
            multiple: true,
            fileList: this.fileList,
            onChange: (info) => {
                if (status !== "uploading") {
                    this.fileList = info.fileList;
                    // console.log(info.file, info.fileList);
                }
                // if (status === "done") {
                //     message.success(`${info.file.name} file uploaded successfully.`);
                // } else if (status === "error") {
                //     message.error(`${info.file.name} file upload failed.`);
                // }
            },
            beforeUpload: (file, fileList) => {
                // console.log(file, fileList);
                return false;
            },
        };
        return (
            <Modal
                afterClose={this.props.afterClose}
                closable={false}
                visible={this.props.visible}
                width="800px"
                title={ModalUtils.title({
                    text: "导入文档",
                    type: "export",
                })}
                maskClosable={false}
                keyboard={false}
                centered={true}
                onCancel={() => this.props.onCancelled && this.props.onCancelled()}
                // footer={
                //     [
                //         <Button key="cancel" onClick={() => {
                //             this.fileList = [];
                //             this.props.onCancelled && this.props.onCancelled();
                //         }}>取消</Button>,

                //         <Button loading={this.uploading} key="upload" disabled={this.docType === null || this.fileList.length === 0}
                //             type="primary"
                //             onClick={() => this.uploadFiles()}>导入</Button>,
                //     ]
                // }
                footer={null}
            >
                <Container>
                    {/* <Row gutter={5} type="flex" justify="space-around" align="middle" style={{ height: 36, marginBottom: 15 }}>
                        <Col span={6}><span>重复文档策略：</span></Col>
                        <Col span={18}>
                            <Radio.Group value={this.duplicateType} onChange={(evt) => this.duplicateType = evt.target.value}>
                                <Radio value={"coverage"}>覆盖</Radio>
                                <Radio value={"skip"}>忽略</Radio>
                                <Radio value={"keep"}>保留</Radio>
                            </Radio.Group>
                        </Col>
                    </Row> */}
                    <Form>
                        <FormItem>
                            <Row gutter={5} type="flex" justify="space-around" align="middle" style={{ height: 36, marginBottom: 15 }}>
                                <Col span={3}><span>类型：</span></Col>
                                <Col span={21}>
                                    <Select
                                        placeholder="选择一个文档类型"
                                        style={{ width: "200px" }}
                                        defaultValue={this.docType}
                                        disabled={this.folder.usedStrictType}
                                        onChange={(val) => this.docType = val.toString()}>
                                        <Select.OptGroup key="dynamic" label="表单类型">
                                            {this.store.models.templateModels.map((model) =>
                                                <Select.Option key={model.type} value={model.type}>
                                                    {model.name}
                                                </Select.Option>)}
                                        </Select.OptGroup>
                                        <Select.OptGroup key="static" label="特定类型">
                                            {this.store.models.pluginModels.map((model) =>
                                                <Select.Option key={model.type} value={model.type}>
                                                    {model.name}
                                                </Select.Option>)}
                                        </Select.OptGroup>
                                    </Select></Col>
                            </Row>
                        </FormItem>
                        <FormItem>
                            <Dragger {...props}>
                                <p className="ant-upload-drag-icon">
                                    <Icon type="inbox" />
                                </p>
                                <p className="ant-upload-text">点击或拖拽文件至此区域</p>
                                <p className="ant-upload-hint">仅支持zip包的上传</p>
                            </Dragger>
                        </FormItem>
                        <FormItem style={{ textAlign: "right", marginTop: 20 }}>
                            <Button onClick={() => {
                                this.fileList = [];
                                this.props.onCancelled && this.props.onCancelled();
                            }}
                            >取消</Button>
                            <Button
                                style={{ marginLeft: 8 }}
                                disabled={this.docType === null || this.fileList.length === 0}
                                loading={this.uploading}
                                type="primary"
                                onClick={() => this.uploadFiles()}
                            >导入</Button>
                        </FormItem>
                    </Form>
                </Container>
                {/* <ActionContainer>
                    <Button size="large" onClick={() => {
                        this.fileList = [];
                        this.props.onCancelled && this.props.onCancelled();
                    }}
                    >取消</Button>
                    <Button
                        disabled={this.docType === null || this.fileList.length === 0}
                        loading={this.uploading}
                        type="primary"
                        size="large"
                        onClick={() => this.uploadFiles()}
                    >导入</Button>
                </ActionContainer> */}
            </Modal >
        );
    }

    public static async show(folder: Folder): Promise<boolean> {
        return new Promise<any>((resolve, reject) => {
            let container = document.createElement("div");
            document.body.appendChild(container);

            let dispose = (success: boolean) => {
                ReactDOM.render(
                    <DocListImportModal
                        visible={false}
                        folder={folder}
                        onSubmitted={() => { }}
                        onCancelled={() => { }}
                        afterClose={() => {
                            ReactDOM.unmountComponentAtNode(container);
                            document.body.removeChild(container);
                            resolve(success);
                        }}
                    />,
                    container);
            };

            ReactDOM.render(
                <DocListImportModal
                    visible={true}
                    folder={folder}
                    onSubmitted={() => { dispose(true); }}
                    onCancelled={() => { dispose(false); }}
                />,
                container);
        });
    }
}

const Container = styled.div`
    /* padding: 20px 0 20px 0; */
    .ant-form-item:last-child {
        margin-bottom: 0;
    }
`;

// const ActionContainer = styled.div`
//     margin-top: 20px;
//     text-align: center;
//     button{
//         margin: auto 10px;
//     }
// `;