import * as mobx from "mobx";

export default class Watcher<T> {

    private instance: T = null;

    private disposers: any[] = [];

    public constructor(instance: T) {
        this.instance = instance;
    }

    public watch(key: keyof T, callback: (change: mobx.IValueDidChange<any>) => void) {
        let disposer = mobx.observe(this.instance, key, (change) => {
            callback(change);
        });
        this.disposers.push(disposer);

        return this;
    }

    public dispose() {
        for (let disposer of this.disposers) {
            disposer();
        }
    }
}