import { Button, Modal } from "antd";
import * as mobx from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import ReactDOM from "react-dom";
import styled, { createGlobalStyle } from "styled-components";

import Doc from "../../models/Doc";
import BaseComponent from "../BaseComponent";

interface IProps {
    visiable: boolean;
    nodeId: string;
    onCancelled?: () => void;
    afterClose?: () => void;
}
/**
 * 在 Modal 中调用 Plugin 的查看器页面。
 */
@observer
export default class PluginModal extends BaseComponent<IProps, any> {

    @mobx.computed
    private get node() { return this.$node; }
    @mobx.observable
    private $node: Doc = null;

    @mobx.computed
    private get viewerPageUrl() { return this.store.models.getViewerPage(this.node); }

    private iframeWindow: any = null;

    public componentDidMount() {
        this.run("加载数据", async () => {
            this.$node = await Doc.createById(this.props.nodeId, this.store).refresh();
        });
    }

    public tryBack() {
        this.page.gotoDocumentListPage(this.node.parentId);
    }

    public get cannodeClose() {
        return this.$cannodeClose;
    }
    @mobx.observable
    private $cannodeClose: boolean = false;

    public loopCheck() {
        if (this.iframeWindow && this.iframeWindow.canClose) {
            this.$cannodeClose = !this.iframeWindow.canClose();
        }
        setTimeout(() => {
            this.loopCheck();
        }, 50);
    }
    public render() {
        if (this.node === null) {
            return <div>没有数据</div>;
            // return null;
        }

        return (
            <>
                <GlobalStyle />
                <Modal
                    wrapClassName="plugin-modal"
                    width={"90%"}
                    footer={null}
                    visible={this.props.visiable}
                    maskClosable={false}
                    centered={true}
                    title={this.$node.name}
                    onCancel={() => {
                        this.props.onCancelled();
                    }}
                    afterClose={() => {
                        this.props.afterClose();
                    }}
                >
                    <Container>
                        <Content>
                            <iframe onLoad={(e) => {
                                this.iframeWindow = (e.target as HTMLIFrameElement).contentWindow;
                                this.loopCheck();
                            }} src={this.viewerPageUrl} />
                            {/* {this.codeMode && <CodeBlock>{JSON.stringify(this.node, null, 4)}</CodeBlock>} */}
                        </Content>
                    </Container>
                </Modal>
            </>
        );
    }
    public static async show(nodeId) {
        return new Promise<any>((resolve, reject) => {
            let container = document.createElement("div");
            document.body.appendChild(container);

            let dispose = (success: boolean) => {
                ReactDOM.render(
                    <PluginModal
                        nodeId={nodeId}
                        visiable={false}
                        onCancelled={() => { }}
                        afterClose={() => {
                            ReactDOM.unmountComponentAtNode(container);
                            document.body.removeChild(container);
                            resolve(success);
                        }}
                    />,
                    container);
            };

            ReactDOM.render(
                <PluginModal
                    nodeId={nodeId}
                    visiable={true}
                    onCancelled={() => {
                        dispose(false);
                    }}
                />,
                container);
        });
    }
}
const Container = styled.div`
    /* position: absolute; */
    /* height:900px; */
    left:   0;
    top:    0;
    right:  0;
    bottom: 0;
    overflow: hidden;
`;
const Content = styled.div`
    position: absolute;
    left:   0;
    right:  0;
    top:    50px;
    bottom: 0;
`;
const GlobalStyle = createGlobalStyle`
    .plugin-modal {
        .ant-modal {
            height: 90%;
        }
        .ant-modal-content {
            height: 100%;
        }
    }
`;
