import { Breadcrumb } from "antd";
import * as mobx from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import Folder from "../../models/Folder";
import Node from "../../models/Node";
import BaseComponent from "../BaseComponent";

import EventBus from "./EventBus";
const FontAwesomeIcon = (props) => <i className={`${props.icon} fas fa-fw fa-lg`} />;

interface IProps {
    node?: Node;
    eventBus: EventBus;
    folder: Folder;
    // onClicked(action: string): void;
}
@observer
export default class extends BaseComponent<IProps, any> {

    @mobx.computed
    public get folder() { return this.props.folder; }

    @mobx.computed
    public get viewType() { return this.store.preferences.nodeViewMode; }

    private setCard() {
        this.props.eventBus.setNodeView.emit("card");
    }
    private setList() {
        this.props.eventBus.setNodeView.emit("list");
    }

    public render() {
        if (!this.folder) {
            return null;
        }
        return (
            <Container>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <Breadcrumb style={{ display: "inline-block" }}>
                                    {this.folder.parents.map((parent) =>
                                        <Breadcrumb.Item key={parent.id}>
                                            <Link to={parent.id === "00000000-0000-0000-0000-000000000010" ? "/list" : `/list/${parent.id}`}> {parent.name} </Link>
                                        </Breadcrumb.Item>,
                                    )}
                                    {this.folder &&
                                        <Breadcrumb.Item> {this.folder.name} </Breadcrumb.Item>
                                    }
                                </Breadcrumb>
                                <Counter>
                                    本层共有 {this.folder.folderCount ? `${this.folder.folderCount} 个文件夹，` : ""}{this.folder.docCount} 个文档。
                                    {this.folder.usedStrictType && `该文件夹已开启严格模式！只允许创建 ${this.store.models.getName(this.folder.inheritedStrictType)} 类型的文档。`}
                                </Counter>
                            </td>
                            <td className="right">
                                <ButtonItem isActive={this.viewType === "card"} onClick={() => this.setCard()} icon="fa-th-large" />
                                <ButtonItem isActive={this.viewType === "list"} onClick={() => this.setList()} icon="fa-list" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </Container>
        );
    }
}

const ButtonItem = (props: {
    icon?: string;
    isActive?: boolean;
    onClick?: () => void;
    children?: any;
}) => {
    return <A className={`${props.isActive ? "active" : ""}`} onClick={() => props.onClick && props.onClick()}>
        {/* {props.icon && <FontAwesomeIcon icon={["fal", props.icon] as any} fixedWidth size="lg" />} */}
        {props.icon && <FontAwesomeIcon icon={props.icon} fixedWidth size="lg" />}
        {props.children}
    </A>;
};
const A = styled.a`
    position: relative;
    display: inline-block;
    padding: 10px;
    color: #444;
    &.active {
        color: #1890ff;
    }
    &:hover {}
`;

const Container = styled.div`
    position: relative;
    margin: 0;
    border-bottom: 1px solid #ececec;
    height: 100%;

    .ant-breadcrumb-link {
        font-weight: bold;
    }

    table {
        height: 100%;
        vertical-align: middle;
    }
    td.left {
        text-align: left;
    }
    td.right {
        text-align: right;
    }
`;
const Counter = styled.div`
    display: inline-block;
    padding-left: 10px;
    font-size: 12px;
`;
