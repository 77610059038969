import _ from "lodash";
import * as mobx from "mobx";

import Store from "./Store";

export default class ActivityManager {

    public readonly store: Store = null;
    public get service() { return this.store.service; }

    public get query() { return this.$query; }
    @mobx.observable
    private $query: IQuery = null;

    public get skip() { return this.$skip; }
    @mobx.observable
    private $skip: number = 0;

    public get take() { return this.$take; }
    @mobx.observable
    private $take: number = 50;

    public get total() { return this.$total; }
    @mobx.observable
    private $total: number = 50;

    public get activities() { return this.$activities; }
    @mobx.observable
    private $activities: IActivity[] = [];

    constructor(store: Store) {
        this.store = store;
    }

    /**
     * 设置查询条件。
     */
    public setQuery(query: IQuery) {
        this.$query = query;

        return this;
    }

    /**
     * 设置翻页。
     */
    public setPager(skip: number, take: number) {
        this.$skip = skip;
        this.$take = take;

        return this;
    }

    /**
     * 导出成 Excel 表文件。
     */
    public async export() {
        return await this.service.download("/apis/resources/00000000-0000-0000-0000-000000000100/export", this.buildRequestParams());
    }

    public async refresh() {

        let data = await this.service.listActivities(this.buildRequestParams());

        this.$activities = [];

        let items: any;
        let total: number;
        if (data.children) {
            items = data.children;
            total = data.children.total;
        } else {
            items = data.items;
            total = data.total;
        }

        for (let node of items) {
            let dto = node.meta ? node.meta : node;
            let activity: IActivity = {
                id: node.id,
                user: {
                    id: dto.user_id,
                    name: dto.user_name || dto.username,
                },
                date: node.updated_at || dto.time,
                action: (dto.action as string).toLowerCase() as ActionTypes,
                node: {
                    id: dto.target || dto.target_id,
                    type: dto.target_type,
                    name: dto.target_name,
                },
            };
            this.activities.push(activity);
        }
        this.$total = total;
    }

    private buildRequestParams() {
        let queryObject = {
            created_at: {
                $gte: (this.query && this.query.minDate) || null,
                $lte: (this.query && this.query.maxDate) || null,
            },
            action: (this.query && this.query.action) || null,
            user: (this.query && this.query.userId) || null,
        };
        return {
            skip: this.skip,
            take: this.take,
            query: JSON.stringify(queryObject),
        };
    }
}

type ActionTypes = "create" | "update" | "delete";

export interface IActivity {
    id: string;
    user: {
        id: string;
        name: string;
    };
    date: string;
    action: ActionTypes;
    node: {
        id: string;
        type: string;
        name: string;
    };
}

export interface IQuery {

    /**
     * 开始时间：例如 "2018-06-22"，会从 22 号的零点开始计算。
     */
    minDate?: string;

    /**
     * 截止时间：例如 "2018-06-23"，会从 23 号的零点开始计算。
     */
    maxDate?: string;

    /**
     * 操作类型。不填表示所有类型。
     */
    action?: ActionTypes;

    userId?: string;
}