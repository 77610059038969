import { Button, Form, Icon, Input, Modal, Select } from "antd";
import _ from "lodash";
import * as mobx from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

import Folder from "../../../models/Folder";
import RuleChecker from "../../../utils/RuleChecker";
import BaseComponent from "../../BaseComponent";

import ModalUtils from "./ModalUtils";

const FormItem = Form.Item;

interface IProps {
    folder: Folder;
    visible: boolean;
    onSubmitted?: (result: IResult) => void;
    onCancelled?: () => void;
    afterClose?: () => void;
}
@observer
export default class DocCreateModal extends BaseComponent<IProps, any> {

    @mobx.observable
    private currentType: string = null;

    @mobx.computed
    public get folder() { return this.props.folder; }

    @mobx.observable
    private newName: string = "";

    @mobx.observable
    private isNameCorrect: boolean = true;

    @mobx.observable
    private docType: string = null;

    @mobx.computed
    private get strictMode() { return this.folder.usedStrictType; }

    @mobx.observable
    private isDocType: boolean = true;

    @mobx.computed
    public get currentFolder(): Folder { return this.props.folder; }

    public componentDidMount() {
        if (this.folder.usedStrictType) {
            this.currentType = this.folder.inheritedStrictType;
        }
    }

    private renderOptions() {
        let list: any[] = [];

        list.push(
            <Select.OptGroup key="dynamic" label="表单类型">
                {this.store.models.templateModels.map((model) =>
                    <Select.Option key={model.type} value={model.type}>
                        {model.name}
                    </Select.Option>)}
            </Select.OptGroup>);
        list.push(
            <Select.OptGroup key="static" label="特定类型">
                {this.store.models.pluginModels.map((model) =>
                    <Select.Option key={model.type} value={model.type}>
                        {model.name}
                    </Select.Option>)}
            </Select.OptGroup>);

        return list;
    }

    private handleSubmit() {

        this.isNameCorrect = RuleChecker.NameRule(this.newName);

        if (this.isNameCorrect && this.newName !== null) {
            if (this.docType === null) {
                this.isDocType = false;
                return this;
            } else {
                // this.run("创建文档", async () => {
                // await this.currentFolder.createChild(this.docType, this.newName);
                this.props.onSubmitted({
                    name: this.newName,
                    type: this.docType,
                    // });
                });
            }

        }
    }

    public render() {
        return (
            <Modal
                afterClose={() => { this.props.afterClose(); }}
                title={ModalUtils.title({
                    text: "新建文档",
                    type: "file-add",
                })}
                visible={this.props.visible}
                closable={false}
                width={900}
                footer={null}
                centered={true}
            >
                <Container >
                    <Form onSubmit={(evt) => { evt.preventDefault(); this.handleSubmit(); }}>
                        <FormItem {...FormItemLayout}
                            label="名字"
                            help="名字不能为空，且不能包含 \ / : * ? < > | ! 符号，长度为8-16位字符"
                            validateStatus={this.isNameCorrect ? "success" : "error"}
                        >
                            <Input
                                autoFocus={true}
                                value={this.newName}
                                onChange={(evt) => {
                                    this.newName = evt.target.value.replace(" ", "");
                                }} />
                        </FormItem>
                        <FormItem {...FormItemLayout}
                            label="类型"
                            help={this.strictMode ? "文件夹已开启严格模式，限定创建类型。" : "必须选择一个文档类型。"}
                            validateStatus={this.isDocType ? "success" : "error"}
                        >
                            <Select
                                placeholder="选择一个文档类型"
                                style={{ width: "100%" }}
                                disabled={this.strictMode}
                                defaultValue={this.docType = this.currentType}
                                onChange={(val) => this.docType = val.toString()}
                            >
                                {this.renderOptions()}
                            </Select>
                        </FormItem>
                        <FormItem style={{ textAlign: "right", marginTop: 20 }}>
                            <Button onClick={() => { this.props.onCancelled(); }} >取消</Button>
                            <Button type="primary" htmlType="submit" style={{ marginLeft: 8 }} >确认</Button>
                        </FormItem>
                    </Form>
                </Container >
            </Modal >
        );
    }

    public static async show(folder: Folder): Promise<IResult> {
        return new Promise<any>((resolve, reject) => {
            let container = document.createElement("div");
            document.body.appendChild(container);

            let dispose = (result: IResult) => {
                ReactDOM.render(
                    <DocCreateModal
                        visible={false}
                        folder={folder}
                        onSubmitted={() => { }}
                        onCancelled={() => { }}
                        afterClose={() => {
                            ReactDOM.unmountComponentAtNode(container);
                            document.body.removeChild(container);
                            resolve(result);
                        }}
                    />,
                    container);

            };

            ReactDOM.render(
                <DocCreateModal
                    visible={true}
                    folder={folder}
                    onSubmitted={(result) => { dispose(result); }}
                    onCancelled={() => { dispose(null); }}
                />,
                container);
        });
    }
}

interface IResult {
    name: string;
    type: string;
}

const Container = styled.div`
    .ant-form-item:last-child {
        margin-bottom: 0;
    }
`;
const FormItemLayout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 22 },
};
