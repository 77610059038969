import { Avatar, Button, Input, List } from "antd";
import * as mobx from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { RouteComponentProps } from "react-router";
import styled from "styled-components";

import Doc from "../../models/Doc";
import Folder from "../../models/Folder";
import AuthoredComponent from "../AuthoredComponent";

interface IProps extends RouteComponentProps<{}> {
}
/**
 * 1. 搜索框输入关键字，点击搜索之后，触发路由变化。
 * 2. 监听路由变化（包括初始值），然后获取关键字。
 * 3. 使用关键字查询结果。
 * 4. 展示搜索结果。
 */
@observer
export default class NodeSearchView extends AuthoredComponent<IProps> {

    /**
     * 当前的关键字，来自 QueryString。
     */
    @mobx.computed
    public get keyword() {
        let query = new URLSearchParams(this.props.location.search);
        let value = query.get("keyword");
        return value || "";
    }

    /**
     * 搜索框的输入文本。
     */
    @mobx.observable
    private inputKeyword: string = "";

    /**
     * 搜索到的文件夹。
     */
    @mobx.observable
    public folders: Folder[] = [];

    /**
     * 搜索到的文档。
     */
    @mobx.observable
    public docs: Doc[] = [];
    @mobx.observable
    private isLoaded: boolean = false;
    public componentDidMount() {
        this.refresh();
        mobx.observe(this, "keyword", () => {
            this.refresh();
        });
    }

    private refresh() {
        this.inputKeyword = this.keyword;
        this.run("搜索", async () => {
            this.folders = [];
            this.docs = [];
            let result = await this.store.service.searchNodes({
                keyword: this.keyword,
                skip: 0,
                take: 1000,
            });
            for (const node of result.items) {
                let item: Folder | Doc = null;
                if (node.type === "folder") {
                    item = Folder.createByDataObject(node, this.store);
                    this.folders.push(item);
                } else {
                    item = Doc.createByDataObject(node, this.store);
                    this.docs.push(item);
                }
            }
            this.isLoaded = true;
        });
    }

    private updateUrlQuery(keyword: string) {
        this.page.goto(`/search?keyword=${encodeURIComponent(keyword)}`);

        return this;
    }

    public render() {
        // 读取结束之后，再渲染内容。
        if (!this.isLoaded) {
            return null;
        }
        return (
            <>
                <ToolbarContainer>
                    <table>
                        <tbody>
                            <tr>
                                <td className="left">
                                    <Button type="default" icon="arrow-left" onClick={() => { this.page.back(); }}>返&nbsp;回</Button>
                                </td>
                                <td className="right">
                                    {this.isDebug && (
                                        <Input.Search
                                            placeholder="搜索"
                                            style={{ width: 200 }}
                                            value={this.inputKeyword}
                                            onChange={(evt) => this.inputKeyword = evt.target.value}
                                            onSearch={(value) => this.updateUrlQuery(value)}
                                        />
                                    )}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </ToolbarContainer>
                <BreadcrumbContainer>
                        <Counter>
                            {(this.folders.length + this.docs.length) === 0 ? <span>没有搜索到内容。</span>
                                : <span>搜索结果共有：
                                    {this.folders.length > 0 && `${this.folders.length && ` ${(this.folders.length)} `}个文件夹  `}
                                    {this.docs.length > 0 && `${this.docs.length && ` ${(this.docs.length)} `}个文档`}
                                    。</span>
                            }
                        </Counter>
                </BreadcrumbContainer>
                <ContentContainer>
                        {this.folders.length > 0 && (
                            <>
                                <List
                                    className="list"
                                    itemLayout="horizontal"
                                    dataSource={this.folders}
                                    renderItem={(item: Folder) => (
                                        <List.Item>
                                            <List.Item.Meta
                                                avatar={<Avatar src={item.node && item.node.thumbnail} />}
                                                title={<a href={`/list/${item.parents[item.parents.length - 1].id}#highlight=${item.id}`}>{item.name}</a>}
                                                description={item.parents.map((i) => {
                                                    return i.name + " / ";
                                                })}
                                            />
                                        </List.Item>
                                    )}
                                />
                            </>
                        )}
                        {this.docs.length > 0 && (
                            <>
                                <List
                                    className="list"
                                    itemLayout="horizontal"
                                    dataSource={this.docs}
                                    renderItem={(item: Doc) => (
                                        <List.Item>
                                            <List.Item.Meta
                                                avatar={<Avatar src={item.thumbnail} />}
                                                title={< a href={`/list/${item.parents[item.parents.length - 1].id}#highlight=${item.id}`}>{item.name}</a>}
                                                description={item.parents.map((i) => {
                                                    return i.name + " / ";
                                                })}
                                            />
                                        </List.Item>
                                    )}
                                />
                            </>
                        )}

                </ContentContainer>
            </>
        );
    }
}
const ToolbarContainer = styled.div`
    position : absolute;
    top:    0;
    left:   0;
    right:  0;
    height: 50px;
    padding: 0 20px;
    box-shadow: 0 0 5px #ccc;
    z-index: 10;
    td.right {
        text-align: right;
        .ant-btn {
            margin-left: 20px;
        }
    }
`;
const ContentContainer = styled.div`
    position : absolute;
    top:    100px;
    left:   0;
    right:  0;
    height:90%;
    padding: 0 20px;
    background-color: #fff;
    overflow-y: auto;
    user-select: none;
`;

const Counter = styled.div`
    display: inline-block;
    padding-left: 10px;
    padding-top:15px;
    font-size: 14px;
    color: black;
`;
const BreadcrumbContainer = styled.div`
    position : absolute;
    top:    50px;
    left:   0;
    right:  0;
    height: 50px;
    padding: 0 20px;
    background-color: #f0f0f0;
`;