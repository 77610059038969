import * as mobx from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import * as ReactDOM from "react-dom";

import OpenFileModal from "./OpenFileModal";

export default class Switcher extends React.Component<any, any> {

    public render() {
        let queryParam = new URLSearchParams(window.location.search);
        let id = queryParam.get("id");
        let component: any = null;
        switch (id) {
            case "OpenFileModal":
            component = <OpenFileModal />;
            break;
        }

        return component;
    }
}