import v8n from "v8n";

/**
 * **不能为空，且不能包含 \ / : * ? < > | ! 符号，长度为8-16位字符
 */
export default class RuleChecker {
    public static NameRule(str: string) {
        return v8n().pattern(/^[^\\\\/:*?"<>|!]+$/).string().minLength(1).maxLength(20).test(str);
    }
    public static PasswordRule(str: string) {
        return v8n().pattern(/^[^\\\\/:*?"<>|!]+$/).string().minLength(6).maxLength(20).test(str);
    }
}