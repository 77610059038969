import { Drawer, Form, Radio, Tabs } from "antd";
import _ from "lodash";
import * as mobx from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import styled from "styled-components";

import BaseComponent from "../BaseComponent";

interface IProps {
    isVisible: boolean;
    finish(): void;
}
const TabPane = Tabs.TabPane;
const FormItem = Form.Item;

@observer
export default class PreferencesDrawer extends BaseComponent<IProps, any> {

    @mobx.computed
    public get preferences() { return this.store.preferences; }

    public async componentDidMount() {
        // this.preferences.showMetaCode;
    }

    public render() {
        const radioStyle = {
            display: "block",
            height: "30px",
            lineHeight: "30px",
        };
        return (
            <div>
                <Drawer
                    title="偏好设置"
                    width={600}
                    placement="right"
                    closable={true}
                    onClose={this.props.finish}
                    maskClosable={true}
                    visible={this.props.isVisible}
                >
                    <Container>
                        <Tabs defaultActiveKey="1" animated={false}>
                            <TabPane tab="操作性选项" key="1">
                                <Form>
                                    <FormItem label="新窗口打开">
                                        <Radio.Group
                                            onChange={(evt) => this.preferences.setOpenInNewWindow(evt.target.value)}
                                            value={this.preferences.openInNewWindow}
                                        >
                                            <Radio value={true} style={radioStyle}>新窗口打开</Radio>
                                            <Radio value={false} style={radioStyle}>当前窗口打开</Radio>
                                        </Radio.Group>
                                    </FormItem>
                                </Form>
                                {/* <Form>
                                    <FormItem label="默认打开方式">
                                        <Radio.Group
                                            style={radioStyle}
                                            onChange={(evt) => this.preferences.setEditWindow(evt.target.value)}
                                            value={this.preferences.editWindow}
                                        >
                                            <Radio value={true} style={radioStyle}>编辑器模式</Radio>
                                            <Radio value={false} style={radioStyle}>查看器模式</Radio>
                                        </Radio.Group>
                                    </FormItem>
                                </Form> */}
                            </TabPane>
                            <TabPane tab="系统开发选项" key="2">
                                <FormItem label="显示文档节点 Meta 信息">
                                    <Radio.Group
                                        style={radioStyle}
                                        onChange={(evt) => this.preferences.setShowMetaCode(evt.target.value)}
                                        value={this.preferences.showMetaCode}
                                    >
                                        <Radio value={true} style={radioStyle}>显示 Meta</Radio>
                                        <Radio value={false} style={radioStyle}>不要显示</Radio>
                                    </Radio.Group>
                                </FormItem>
                            </TabPane>
                            <TabPane tab="强迫症选项" key="3">
                                <FormItem label="显示文档类型图标">
                                    <Radio.Group
                                        style={radioStyle}
                                        onChange={(evt) => this.preferences.setShowNodeIcon(evt.target.value)}
                                        value={this.preferences.showNodeIcon}
                                    >
                                        <Radio value={true} style={radioStyle}>显示图标</Radio>
                                        <Radio value={false} style={radioStyle}>不要显示</Radio>
                                    </Radio.Group>
                                </FormItem>
                            </TabPane>
                        </Tabs>
                    </Container>
                </Drawer>
            </div>
        );
    }
}
const TableSectionTr = (props: any) => <tr><td colSpan={2} style={{ padding: "20px 0 0 0" }}><h2>{props.children}</h2></td></tr>;
const Container = styled.div`
    table {
        table-layout: fixed;
        height: auto;
        td {
            padding: 5px 0;
        }
        td:first-child {
            width: 200px;
        }
    }
`;