import * as mobx from "mobx";
import { RouteComponentProps } from "react-router-dom";

export default class Page {

    @mobx.computed
    public get routerProps() { return this.$routerProps; }
    public set routerProps(val) { this.$routerProps = val; }
    @mobx.observable
    private $routerProps: RouteComponentProps<any> = null;

    public goto(url: string) {
        this.routerProps.history.push(url);

        return;
    }

    public open(url: string) {
        window.open(url);

        return;
    }

    public gotoDocumentListPage(nodeId: string) {
        let url = `/list/${nodeId}`;
        this.goto(url);

        return;
    }

    public gotoViewerPage(nodeId: string) {
        let url = `/apps/viewer/${nodeId}`;
        this.goto(url);

        return;
    }

    public gotoEditorPage(nodeId: string) {
        let url = `/apps/editor/${nodeId}`;
        this.goto(url);

        return;
    }

    public back() {
        if (this.routerProps.history.length === 1) {
            this.routerProps.history.push("/list");
        } else {
            this.routerProps.history.goBack();
        }

        return;
    }
}