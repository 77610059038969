import { Button, Form, Icon, Input, message } from "antd";
import * as mobx from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

import RuleChecker from "../../utils/RuleChecker";
import VerticalCenter from "../common/VerticalCenter";
import RouterBase from "../RouterBase";

@observer
class WelcomeView extends RouterBase<any> {

    @mobx.observable
    private username: string = "";

    @mobx.observable
    private password: string = "";

    @mobx.computed
    public get loginType() {
        return this.store.preferences.loginType;
    }

    public componentDidMount() {
        // this.loginType;
        if (this.store.account.isAuthenticated) {
            this.redirectToDefault();
        }
    }

    private submit() {
        console.log(this.password);
        if (!RuleChecker.NameRule(this.username)) {
            message.warning("请输入有效的用户名！");
            return;
        }
        // if (!RuleChecker.PasswordRule(this.password)) {
        //     message.warning("请输入有效的密码！");
        //     return;
        // }
        this.run("登录", async () => {
            try {
                await this.store.account.login(this.username, this.password);
                this.redirectToDefault();
            } catch (e) {
                message.warning("账号或密码错误！");
            } finally {

            }
        });
    }

    private redirectToDefault() {
        let query = new URLSearchParams(this.location.search);
        let callback = query.get("callback") || "/list";

        console.log(`redirectToDefault`, callback);
        this.history.push(callback);
    }

    public render() {
        return (
            <Container>
                <VerticalCenter>
                    <Form onSubmit={(evt) => {
                        evt.preventDefault();
                        evt.stopPropagation();
                        this.submit();
                    }}>
                        {/* <img src="http://www.xuantech.cn/assets/img/about/company.png" alt="" /> */}
                        {
                            this.store.config && <h1>{this.store.config.title}</h1>
                        }
                        <Form.Item>
                            <Input size="large" value={this.username} onChange={(val) => this.username = val.target.value} prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)", fontSize: 16 }} />} placeholder="用户名" />
                        </Form.Item>
                        <Form.Item>
                            <Input size="large" value={this.password} onChange={(val) => this.password = val.target.value} prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)", fontSize: 16 }} />} type="password" placeholder="密码" />
                        </Form.Item>
                        {/* <Form.Item style={{ textAlign: "left" }}>
                            <ButtonGroup>
                                <Button size="small" type={this.loginType === "default" ? "primary" : "default"}>默认登录</Button >
                                <Button size="small">邮箱登录</Button >
                                <Button size="small">盒知登录</Button >
                                <Button size="small">第三方登录</Button >
                            </ButtonGroup>
                        </Form.Item> */}
                        <Form.Item>
                            <Button style={{ display: "block", width: "100%" }} type="primary" size="large" htmlType="submit">登　录</Button>
                        </Form.Item>
                    </Form>
                </VerticalCenter>
            </Container>
        );
    }
}
export default withRouter(WelcomeView);

const Container = styled.div`
    position: fixed;
    left:   0;
    top:    0;
    right:  0;
    bottom: 0;
    background-color: #ececec;
    overflow: hidden;
    z-index: 1000;
    /* background-image: url(assets/img/login_bg.png); */
    background-size: 100px;
    background-repeat: repeat;
    h1 {
        margin-bottom: 50px;
    }
    button.ant-btn-sm{
        line-height:1.5;
    }
    span.ant-input-affix-wrapper .ant-input:not(:first-child) {
        padding-left: 35px;
    }
    form {
        box-shadow: 0 2px 2px 0    rgba(0, 0, 0, 0.24),
                    0 3px 1px -2px rgba(0, 0, 0, 0.30),
                    0 1px 5px 0    rgba(0, 0, 0, 0.22);
        height: auto;
        width:  600px;
        padding: 50px 50px;
        margin: 0 auto;
        background-color: #fff;
        border-radius: 0;
    }
`;