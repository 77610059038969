import { Button, DatePicker, Select, Table } from "antd";
import _ from "lodash";
import * as mobx from "mobx";
import { observer } from "mobx-react";
import moment from "moment";
import * as React from "react";
import styled from "styled-components";

import { IQuery } from "../../models/ActivityManager";
import User from "../../models/users/User";
import BaseComponent from "../BaseComponent";

type ActionTypes = "create" | "update" | "delete";

const Option = Select.Option;

interface IProps {
    // 操作人
    users: User[];
    onSubmit?: (filter: IQuery) => void;
    onExport?: (filter: IQuery) => void;
}
@observer
export default class Toolbar extends BaseComponent<IProps, any> {
    /**
     * antd时间控件显示时需要的值
     */
    @mobx.observable
    private minDate: moment.Moment = null;
    @mobx.observable
    private maxDate: moment.Moment = null;

    /**
     * 操作类型
     */
    @mobx.observable
    private action: ActionTypes = null;

    /**
     * 操作人
     */
    @mobx.observable
    private userId: string = null;

    @mobx.computed
    private get users() { return this.props.users || []; }

    private getDisabledMinDate(minDate) {
        if (!this.maxDate || !minDate) {
            return false;
        }
        return minDate.valueOf() > this.maxDate.valueOf();
    }

    private getDisabledMaxDate(maxDate) {
        if (!this.minDate || !maxDate) {
            return false;
        }
        return maxDate.valueOf() <= this.minDate.valueOf();
    }

    /**
     * 开始时间
     */
    private onMinDateChanged(date: moment.Moment) {
        this.minDate = date;
    }

    /**
     * 结束时间
     */
    private onMaxDateChanged(date: moment.Moment) {
        this.maxDate = date;
    }

    /**
     * 筛选
     */
    private search() {
        this.props.onSubmit && this.props.onSubmit(this.buildQuery());
    }

    /**
     * 导出
     */
    private export() {
        this.props.onExport && this.props.onExport(this.buildQuery());
    }

    private buildQuery() {
        return {
            minDate: this.minDate && this.minDate.format("YYYY-MM-DD"),
            maxDate: this.maxDate && this.maxDate.format("YYYY-MM-DD"),
            action: this.action ? this.action as ActionTypes : null,
            userId: this.userId === "all" ? null : this.userId,
        };
    }

    /**
     * 操作人
     */
    public onUserSelected(userId: string) {
        this.userId = userId;
    }
    /**
     * 操作类型
     */
    private onActionChanged(action) {
        this.action = action;
    }

    public async componentDidMount() {
        this.search();
    }

    public render() {
        return (
            <Container>

                <SearchName>
                    <span>操作人：</span>
                    <Select
                        showSearch
                        style={{ width: 200 }}
                        placeholder="操作人"
                        optionFilterProp="children"
                        onChange={(evt) => this.onUserSelected(evt as string)}
                        filterOption={(input, option) => (option.props.children as any).toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                        <Option key="all" value="all">全部</Option>
                        <Option key="00000000-0000-0000-0000-000000000000" value="00000000-0000-0000-0000-000000000000">超级管理员</Option>
                        {this.users.map((user) => (
                            <Option key={user.id} value={user.id}>{user.name}</Option>
                        ))}
                    </Select>
                </SearchName>

                <SearchDate>
                    <span>日期：</span>
                    <DatePicker
                        disabledDate={this.getDisabledMinDate.bind(this)}
                        format="YYYY-MM-DD"
                        value={this.minDate}
                        placeholder="开始日期"
                        onChange={(date: moment.Moment) => this.onMinDateChanged(date)}
                    />
                    <DatePicker
                        disabledDate={this.getDisabledMaxDate.bind(this)}
                        format="YYYY-MM-DD"
                        value={this.maxDate}
                        placeholder="截止日期"
                        onChange={(date: moment.Moment) => this.onMaxDateChanged(date)}
                    />
                </SearchDate>

                <SearchType>
                    <span>操作类型：</span>
                    <Select defaultValue="" style={{ width: 120 }} onChange={this.onActionChanged.bind(this)}>
                        <Option value="">全部</Option>
                        <Option value="create">创建</Option>
                        <Option value="delete">删除</Option>
                        <Option value="update">更新</Option>
                    </Select>
                </SearchType>

                <Button type="primary" onClick={this.search.bind(this)}>筛选</Button>

                {this.isDebug && <Button style={{ float: "right" }} type="primary" onClick={this.export.bind(this)}>导出</Button>}

            </Container>
        );
    }
}

const Container = styled.div`
    width: 100%;
    display: inline-block;
    /* position: fixed; */
    top:0;
    padding: 0 0 20px 0;
    /* z-index:100; */
`;
const SearchType = styled.div`
    display: inline;
    margin-right: 20px;
`;
const SearchName = styled.div`
    display: inline;
    margin-right: 20px;
`;
const SearchDate = styled.div`
    display: inline;
    margin-right: 20px;
    & > .ant-calendar-picker {
        padding-right: 5px;
    }
`;