import $ from "jquery";
import "jstree";
import "jstree/dist/themes/default/style.css";
import _ from "lodash";
import { createGlobalStyle } from "styled-components";

interface ITreeOptions {
    keyboard?: boolean;
    dragAndDrop?: boolean;
    contextMenu?: boolean;
    checkBox?: boolean;
    dots?: boolean;
    icons?: boolean;
    showRoot?: boolean;
    types?: any;
}
export class JsTree {
    /**
     * 使用自定义的图标文件。
     */
    public static useCustomImage(imagePath: string) {
        let GlobalStyle = createGlobalStyle`
            .jstree.jstree-default {
                .jstree-node,
                .jstree-icon {
                    background-image: url(${imagePath});
                    background-size: 320px 96px;
                    background-color: transparent;
                }
                .jstree-icon {
                    background-repeat: no-repeat;
                }
                .jstree-last,
                .jstree-themeicon-custom {
                    background-image: none;
                }
            }
            #jstree-dnd.jstree-default i.jstree-ok,
            #jstree-dnd.jstree-default i.jstree-er,
            #jstree-dnd.jstree-default-small i.jstree-ok,
            #jstree-dnd.jstree-default-small i.jstree-er,
            #jstree-dnd.jstree-default-large i.jstree-ok,
            #jstree-dnd.jstree-default-large i.jstree-er {
                    background-image: url(${imagePath});
                    background-size: 320px 96px;
                    background-repeat: no-repeat;
                    background-color: transparent;
            }
        `;
        return GlobalStyle;
    }

    /**
     * 当前的选项。调用 refresh 的时候会以此为参数。
     */
    public get options(): ITreeOptions { return this.$options; }
    private $options: ITreeOptions = {
        keyboard: true,
        dragAndDrop: true,
        contextMenu: true,
        checkBox: true,
        dots: true,
        icons: true,
        showRoot: true,
    };

    private container: HTMLElement = null;

    private $native: any;
    public get native() {
        return this.$native;
    }

    public get onNodeSelected() { return this.$onNodeSelected; }
    public set onNodeSelected(value) { this.$onNodeSelected = value; }
    private $onNodeSelected: (node: JsNode) => void = null;

    public get onCheckChanged() { return this.$onCheckChanged; }
    public set onCheckChanged(value) { this.$onCheckChanged = value; }
    private $onCheckChanged: (node: JsNode, checked: boolean) => void = null;

    public get onNodeMoving() { return this.$onNodeMoving; }
    public set onNodeMoving(value) { this.$onNodeMoving = value; }
    private $onNodeMoving: (node: JsNode, parent: JsNode) => boolean = null;

    public get onNodeMoved() { return this.$onNodeMoved; }
    public set onNodeMoved(value) { this.$onNodeMoved = value; }
    private $onNodeMoved: (node: JsNode, parent: JsNode, position: number) => void = null;

    public get onNodeRenamed() { return this.$onNodeRenamed; }
    public set onNodeRenamed(value) { this.$onNodeRenamed = value; }
    private $onNodeRenamed: (node: JsNode, name: string) => void = null;

    public get root() { return this.$root; }
    public set root(value) { this.$root = value; }
    private $root: JsNode = null;

    public get menu() { return this.$menu; }
    public set menu(value) { this.$menu = value; }
    private $menu: JsMenu = null;

    public get types() { return this.$types; }
    public set types(value) { this.$types = value; }
    private $types: JsTypes = null;

    public constructor(dom: HTMLElement, options: ITreeOptions = null) {
        if (options) {
            _.forEach(options, (value, key) => {
                this.options[key] = value;
            });
        }
        this.container = dom;
        this.create(dom);
    }

    private create(container: HTMLElement) {
        this.$native = ($(container) as any).jstree({
            contextmenu: this.options.contextMenu ? {
                select_node: false,
                show_at_node: true,
                items: (node) => {
                    return this.menu && this.menu.toJson(node.data());
                },
            } : null,
            core: {
                keyboard: this.options.keyboard ? {} : null,
                data: null,
                themes: { dots: this.options.dots, icons: this.options.icons },
                check_callback: (operation: string, node: any, nodeParent: any) => {
                    // 拖拽检查 如果符合条件，就允许拖拽
                    if (operation === "move_node") {
                        // if (node.data.type === "option") {
                        //     if (!nodeParent.data) {
                        //         return false;
                        //     }
                        //     if (nodeParent.data.type === "option") {
                        //         return false;
                        //     }
                        //     if (node.parent !== nodeParent.id) {
                        //         return false;
                        //     }
                        // } else {
                        //     if (nodeParent.id !== "#") {
                        //         return false;
                        //     }
                        // }
                        let jsnode = node.data ? node.data() : null;
                        let parentnode = nodeParent.data ? nodeParent.data() : null;
                        return this.onNodeMoving && this.onNodeMoving(jsnode, parentnode);

                    }
                    return true;
                },
                dblclick_toggle: false,
                multiple: false,
            },
            checkbox: this.options.checkBox ? {
                // "visible": false,
                three_state: true,
                whole_node: false,
                // "keep_selected_style": false,
                cascade: "undetermined",
                tie_selection: false,
                cascade_to_disabled: true,
                cascade_to_hidden: false,
            } : null,
            dnd: this.options.dragAndDrop ? {
                copy: false,
                drag_selection: false,
                is_draggable: true,
                check_while_dragging: true,
            } : null,
            plugins: this.filterPlugins(),
            types: this.options.types || {},
        })
            .on("move_node.jstree", (evt: any, res: any) => {
                let jsnode = res.node.data();
                let parent = this.$native.get_node(res.parent);
                this.onNodeMoved && this.onNodeMoved(jsnode, parent.data ? parent.data() : null, res.position);
            })
            .on("rename_node.jstree", (evt: any, res: any) => {
                if (res.text === res.old) {
                    return;
                }
                let name = res.text.trim();
                let jsnode = res.node.data();
                this.onNodeRenamed && this.onNodeRenamed(jsnode, name);
            })
            .on("check_node.jstree", (evt: any, res: any) => {
                let jsnode = res.node.data();
                this.onCheckChanged && this.onCheckChanged(jsnode, true);
            })
            .on("uncheck_node.jstree", (evt: any, res: any) => {
                let jsnode = res.node.data();
                this.onCheckChanged && this.onCheckChanged(jsnode, false);
            })
            .on("changed.jstree", (evt: any, res: any) => {
                switch (res.action) {
                    case "select_node":
                        let jsnode = res.node.data();
                        this.onNodeSelected && this.onNodeSelected(jsnode);
                        break;
                }
            })
            .jstree(true);

        // 覆盖原始的 checkbox icon
        // this.overrideCheckboxIcons();

        // $(this.container).addClass("jstree-container-override")
        //     // .on("ready.jstree click", this.overrideCheckboxIcons.bind(this))
        //     .on("check_node.jstree", this.overrideCheckboxIcons.bind(this))
        //     .on("uncheck_node.jstree", this.overrideCheckboxIcons.bind(this))
        //     .on("redraw.jstree", this.overrideCheckboxIcons.bind(this))
        //     .on("redraw.jstree", this.overrideCheckboxIcons.bind(this))
        //     .on("open_node.jstree after_close.jstree", this.overrideCheckboxIcons.bind(this))
        //     ;
    }

    /**
     * 使用 FontAwesome 覆盖原始的 checkbox 图标
     */
    private overrideCheckboxIcons() {

        setTimeout(() => {
            $("a.jstree-anchor > i.jstree-checkbox")
                .removeClass("fa-check-square fa-square fa-times-square")
                .addClass("fa-lg fa-fw");

            // 选中状态
            $("a.jstree-anchor.jstree-checked > i.jstree-checkbox")
                .addClass("far fa-check-square");

            // 半选中状态
            $("a.jstree-anchor:not(.jstree-checked) > i.jstree-checkbox.jstree-undetermined")
                .addClass("far fa-lg fa-fw fa-times-square");

            // 未选中状态
            $("a.jstree-anchor:not(.jstree-checked) > i.jstree-checkbox:not(.jstree-undetermined)")
                .addClass("far fa-square");

        }, 100);
    }

    public refresh() {
        if (this.root === null) {
            console.warn("JSTREE", "根节点不能为空!");
            return;
        }
        let jsdata = this.root.toJson();
        if (!this.$options.showRoot) {
            jsdata = jsdata.children;
        }
        this.native.settings.core.data = jsdata;

        // 消除重载树时的选择回声
        let selectNode = this.native.get_selected();
        this.native.deselect_all();

        let state = this.native.get_state();
        if (this.$options.checkBox) {
            let checkbox = this.getCheckedNode();
            state.checkbox = checkbox;
        }
        this.native.refresh();
        this.native.set_state(state);

        this.native.select_node(selectNode, true);

        return this;
    }

    private getCheckedNode(): string[] {
        let checked = [];
        this.root.visit((node) => {
            if (node.children.length === 0 && node.checked) {
                checked.push(node.id);
            }
        });
        return checked;
    }

    /**
     * 选择一个节点。
     * @param node 节点，填 null 表示清空选择。
     */
    public selectNode(node: JsNode = null) {
        this.native.deselect_all();
        if (node) {
            this.native.select_node({
                id: node.id,
            });
        }
    }

    /**
     * 选择一个节点。
     * @param nodeId 节点 ID
     */
    public selectNodeById(nodeId: string) {
        this.native.deselect_all();
        this.native.select_node({
            id: nodeId,
        });
    }

    /**
     * 重新封装jstree edit方法
     * @param node
     */
    public edit(node: JsNode) {
        this.native.edit({
            id: node.id,
        });
    }

    /**
     * 展开当前节点的子节点。
     * https://www.jstree.com/api/#/?f=open_node(obj%20[,%20callback,%20animation])
     * @param node 当前节点。
     */
    public open(node: JsNode) {
        this.native.open_node({
            id: node.id,
        });
    }

    /**
     * 把当前节点下所有的子节点展开。
     * https://www.jstree.com/api/#/?f=open_all([obj,%20animation,%20original_obj])
     * @param node 当前节点
     */
    public openAll(node: JsNode, animation: number = 0) {
        this.native.open_all({
            id: node.id,
        });
    }

    /**
     * 自动滚动到选中的节点位置。当树的内容很多的时候非常有用。
     */
    public focusNodeById(nodeId: string) {
        this.native.get_node(nodeId, true).children(".jstree-anchor").focus();
    }

    private filterPlugins() {
        let plugins = ["types"];
        if (this.options.contextMenu) {
            plugins.push("contextmenu");
        }
        if (this.options.dragAndDrop) {
            plugins.push("dnd");
        }
        if (this.options.checkBox) {
            plugins.push("checkbox");
        }
        return plugins;
    }
}

export class JsNode {

    constructor(jstree: JsTree) {
        this.$tree = jstree;
    }

    public visit(cb: (node: JsNode) => void) {
        cb(this);
        for (const item of this.children) {
            item.visit(cb);
        }
    }

    public async visitAsync(cb: (node: JsNode) => Promise<any>) {
        await cb(this);
        for (const item of this.children) {
            await item.visit(cb);
        }
    }

    public get tree() { return this.$tree; }
    private $tree: JsTree = null;

    public get id() { return this.$id; }
    private $id: string = null;

    public get text() { return this.$text; }
    private $text: string = null;

    public get icon() { return this.$icon; }
    private $icon: string = null;

    public get type() { return this.$type; }
    private $type: string = null;

    public get parent() { return this.$parent; }
    private $parent: JsNode = null;

    public get children() { return this.$children; }
    private $children: JsNode[] = [];

    public get checked() { return this.$checked; }
    private $checked: boolean = null;

    public get disabled() { return this.$disabled; }
    private $disabled: boolean = null;
    /**
     * 私有存储
     */
    public get data() { return this.$data; }
    private $data: any = null;
    public setData(value: any) {
        this.$data = value;
        return this;
    }

    public setText(value: string) {
        this.$text = value;
        return this;
    }
    public setIcon(value: string) {
        this.$icon = value;
        return this;
    }
    public setId(value: string) {
        this.$id = value;
        return this;
    }
    public setType(value: string) {
        this.$type = value;
        return this;
    }
    public setChecked(value: boolean) {
        this.$checked = value;
        return this;
    }

    public setDisabled(value: boolean) {
        this.$disabled = value;
        return this;
    }
    public setParent(value: JsNode) {
        this.$parent = value;
        return this;
    }

    public toJson() {
        let treeNode: any = {
            text: this.text,
            id: this.id,
            // 需要用函数包装一下JsNode，类似于vue的data
            data: () => {
                return this;
            },
            state: {
                checked: this.checked,
                disabled: this.disabled,
            },
            type: this.type,
            icon: this.icon,
            children: [],
        };
        // children.push(treeNode);
        for (const nodeItem of this.children) {
            let childJson = nodeItem.toJson();
            treeNode.children.push(childJson);
            // this.parseNode(nodeItem, treeNode.children);
        }
        return treeNode;
    }
}

export class JsMenu {

    public get items() { return this.$items; }
    private $items: JsMenuItem[] = [];

    public toJson(node: JsNode) {
        let data = {};
        for (let item of this.items) {
            if (item.onRequired === null || item.onRequired(node)) {
                data[item.text] = item.toJson(node);
            }
        }
        return data;
    }
}

export class JsMenuItem {
    constructor() {

    }

    public get onClicked() { return this.$onClicked; }
    private $onClicked: (node: JsNode) => void = null;

    public get onRequired() { return this.$onRequired; }
    private $onRequired: (node: JsNode) => boolean = null;

    public get text() { return this.$text; }
    private $text: string = null;

    public get icon() { return this.$icon; }
    private $icon: string = null;

    public get id() { return this.$id; }
    private $id: string = null;

    public get separator() { return this.$separator; }
    private $separator: boolean = false;

    public setText(value: string) {
        this.$text = value;
        return this;
    }
    public setIcon(value: string) {
        this.$icon = value;
        return this;
    }
    public setSeparator(value: boolean) {
        this.$separator = value;
        return this;
    }

    public setOnRequired(value: (node: JsNode) => boolean) {
        this.$onRequired = value;
        return this;
    }

    public setOnClicked(value: (node: JsNode) => void) {
        this.$onClicked = value;
        return this;
    }
    public toJson(node: JsNode) {
        return {
            label: this.text,
            separator_after: this.separator,
            icon: this.icon,
            action: (data: any) => {
                this.onClicked && this.onClicked(node);
            },
        };
    }
}

export class JsTypes {

    private readonly types: Map<string, string> = new Map();

    private iconSuffix: string = "fw";

    public add(type: string, icon: string) {
        this.types.set(type, icon);

        return this;
    }

    public setIconSuffix(value: string) {
        this.iconSuffix = value;

        return this;
    }

    public toJson() {
        let json: any = {};
        this.types.forEach((value, key) => {
            json[key] = {
                icon: `${value} ${this.iconSuffix}`,
            };
        });
        return json;
    }
}