import { Button, Checkbox, Form, Input, Modal, Select, Tag, Tree } from "antd";
import * as mobx from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";
import v8n from "v8n";

import User from "../../models/users/User";
import UserManager from "../../models/users/UserManager";
import BaseComponent from "../BaseComponent";

const FormItem = Form.Item;
const Option = Select.Option;
const { TreeNode } = Tree;

interface IProps {
    isShow: boolean;
    userManager: UserManager;
    user?: User;
    onCancelled(): void;
}

@observer
export default class UserPermissionsModal extends BaseComponent<IProps, any> {
    @mobx.observable
    private treeData: any[] = [];

    @mobx.observable
    private autoExpandParent: boolean = true;

    @mobx.observable
    private selectedKeys: string[] = [];

    @mobx.computed
    private get treeKeys() { return this.$treeKeys.slice() }
    @mobx.observable
    private $treeKeys: string[] = [];

    @mobx.observable
    private currentKeys: string[] = [];

    @mobx.observable
    private halfCheckedKeys: string[] = [];

    @mobx.observable
    private alreadySet: boolean = false;

    @mobx.observable
    private currentUser: User = null;

    @mobx.computed
    private get checkedKeys() {
        return this.$checkedKeys.slice();
    }
    @mobx.observable
    private $checkedKeys: string[] = [];

    @mobx.observable
    private mode: "create" | "update" = "create";

    public onCheck(checkedKeys, e) {
        this.$checkedKeys = checkedKeys.checked;
        console.log(checkedKeys, `没有选中？？？`, e, `有啥`);
    }

    public onSelect(selectedKeys, info) {
        this.selectedKeys = selectedKeys;
    }

    public async setPermission(id: string, permission: string[]) {
        let permissions = permission.concat(this.halfCheckedKeys)
        let res = await this.store.service.setPermission(id, permissions);
        return res;
    }

    public async updatePermission(id: string, permission: string[]) {
        let permissions = permission.concat(this.halfCheckedKeys)
        let res = await this.store.service.updatePermission(id, permissions);
        return res;
    }

    public submitPermission(id: string, permission: string[]) {
        console.log(id, permission, this.mode, `有更改吗`);
        if (this.mode === "create") {
            this.setPermission(id, permission);
        } else {
            this.updatePermission(id, permission);
        }
        this.props.onCancelled();
    }



    public async componentDidMount() {
        this.treeData = await this.store.service.loadPermission();
        this.currentUser = this.props.user;
        let res
        try {
            res = await this.store.service.getPermissionList(
                this.currentUser.id
            );
            this.$checkedKeys = res.menus;
            this.alreadySet = true;
        } catch (error) {
            this.$checkedKeys = [];
            console.log(error);
        }
        if (this.checkedKeys.length > 0) {
            this.mode = "update";
        } else if (this.alreadySet) {
            this.mode = "update";
        } else {
            this.mode = "create";
        }
    }

    public render() {
        return (
            <Modal
                title={`编辑权限(${this.currentUser && this.currentUser.displayName})`}
                visible={this.props.isShow}
                closable={false}
                footer={null}
                centered={true}
            >
                <Container>
                    <Form>
                        <FormItem>
                            <div>拥有权限:</div>
                            <Tree
                                checkable
                                // defaultCheckedKeys={this.defaultCheckedKeys}
                                checkStrictly={true}
                                autoExpandParent={this.autoExpandParent}
                                onCheck={(checkedKeys, e) => { this.onCheck(checkedKeys, e); }}
                                checkedKeys={this.checkedKeys}
                                onSelect={(e, info) => this.onSelect(e, info)}
                                selectedKeys={this.selectedKeys}
                                treeData={this.treeData}
                            />
                        </FormItem>
                        <FormItem style={{ textAlign: "right" }}>
                            <Button onClick={() => this.props.onCancelled()}>
                                取消
                            </Button>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{ marginLeft: 8 }}
                                onClick={() => { this.submitPermission(this.currentUser.id, this.checkedKeys); }}
                            >
                                确认
                            </Button>
                        </FormItem>
                    </Form>
                </Container>
            </Modal>
        );
    }
    public static async show(
        userManager: UserManager,
        user: User
    ): Promise<boolean> {
        return new Promise<any>((resolve, reject) => {
            let container = document.createElement("div");
            document.body.appendChild(container);

            let dispose = (success: boolean) => {
                ReactDOM.render(
                    <UserPermissionsModal
                        userManager={userManager}
                        user={user}
                        isShow={false}
                        onCancelled={() => { }}
                    />,
                    container
                );
                setTimeout(() => {
                    ReactDOM.unmountComponentAtNode(container);
                    document.body.removeChild(container);
                }, 300);
                resolve(success);
            };

            ReactDOM.render(
                <UserPermissionsModal
                    userManager={userManager}
                    user={user}
                    isShow={true}
                    onCancelled={() => {
                        dispose(false);
                    }}
                />,
                container
            );
        });
    }
}

const FormItemLayout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 19 }
};
const Container = styled.div`
    .ant-form-item:last-child {
        margin-bottom: 0;
    }
`;
