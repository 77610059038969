import _ from "lodash";
import * as mobx from "mobx";

import Doc from "./Doc";
import FolderRef from "./FolderRef";
import Node from "./Node";
import * as REST from "./Service";
import Store from "./Store";
import User from "./users/User";
import UserGroup from "./users/UserGroup";
import UserManager from "./users/UserManager";

/**
 * 文件夹类。文件夹权限，文件夹严格模式等操作的封装。
 */
export default class Folder extends Node {

    public static STRICT_TYPES_ALL = "ALL";
    public static STRICT_TYPES_INHERITED = "INHERITED";

    public readonly store: Store = null;

    /**
     * 路径。
     */
    public get path() { return this.$path; }
    @mobx.observable
    private $path: string = null;

    /**
     * 父文件夹。根目录是文档库。
     */
    public get parents() { return this.$parents; }
    @mobx.observable
    private $parents: FolderRef[] = [];

    /**
     * 子文件和子文件夹。
     */
    @mobx.computed
    public get children() { return this.$children && this.$children.filter((item) => item.canRead); }
    public set children(value: Node[]) { this.$children = value; }
    @mobx.observable
    private $children: Node[] = [];

    /**
     * 子文件夹。
     */
    public get childFolders() { return this.$childFolders; }
    @mobx.observable
    private $childFolders: Folder[] = [];

    // /**
    //  * 当前用户是否有查看的权限。
    //  */
    // public get canRead() { return this.$canRead; }
    // @mobx.observable
    // private $canRead: boolean = null;

    // /**
    //  * 当前用户是否有编辑的权限。
    //  */
    // public get canWrite() { return this.$canWrite; }
    // @mobx.observable
    // private $canWrite: boolean = null;

    /**
     * 严格模式的类型。如果不是严格模式，这里是 null。
     * 严格模式是指，文件夹里面的文档，都必须是同一个类型的。
     */
    @mobx.computed
    public get strictType() { return this.$strictType || Folder.STRICT_TYPES_INHERITED; }
    @mobx.observable
    private $strictType: string = null;

    /**
     * 判断这个文件夹是否启用了严格模式。
     */
    @mobx.computed
    public get usedStrictType() {
        let inheritedType = this.inheritedStrictType;
        if (inheritedType === Folder.STRICT_TYPES_ALL) {
            return false;
        }
        if (inheritedType === Folder.STRICT_TYPES_INHERITED) {
            return false;
        }
        return true;
    }

    /**
     * 获取继承的严格模式。
     */
    @mobx.computed
    public get inheritedStrictType(): string {
        if (this.strictType !== Folder.STRICT_TYPES_INHERITED) {
            return this.strictType;
        }
        let inheritedType: string = null;
        this.visitParents((parent) => {
            if (inheritedType === null && parent.strictType !== Folder.STRICT_TYPES_INHERITED) {
                inheritedType = parent.strictType;
            }
        });
        inheritedType = inheritedType || Folder.STRICT_TYPES_INHERITED;

        return inheritedType;
    }

    /**
     * 可以编辑这个文件夹内容的用户。【只有管理员才能有这个属性】
     */
    public get editors() { return this.$editors; }
    @mobx.observable
    private $editors: User[] = null;

    /**
     * 可以编辑这个文件夹内容的用户组。【只有管理员才能有这个属性】
     */
    public get editorGroups() { return this.$editorGroups; }
    @mobx.observable
    private $editorGroups: UserGroup[] = null;

    /**
     * 该目录的用户权限列表。【只有管理员才能有这个属性】
     */
    public get folderPermissions() { return this.$folderPermissions; }
    @mobx.observable
    private $folderPermissions: IFolderPermission[] = null;

    public get node() { return this.$node; }
    @mobx.observable
    private $node: Node = null;

    public get folderCount() { return this.$folderCount; }
    @mobx.observable
    private $folderCount: number = null;

    public get docCount() { return this.$docCount; }
    @mobx.observable
    private $docCount: number = null;

    private constructor(store: Store, id: string) {
        super(store, id);
        this.store = store;
    }

    /**
     * 遍历父节点。从下到上。
     */
    private visitParents(callback: (parent: FolderRef) => void) {
        for (let i = (this.parents.length - 1); i >= 0; i--) {
            let parent = this.parents[i];
            callback(parent);
        }
    }

    /**
     * 拉取并更新视图数据。
     */
    public async refresh(depth: number = 1, take: number = 1000, type: string = null) {
        // 读取文件夹基础数据。
        {
            let result = await this.service.readFolder(this.id, depth, take, type);
            let nodeDetail = result as REST.INodeDetail;
            this.setByDataObject(nodeDetail);
        }

        // 读取权限信息。只有管理员角色才能获取。
        if (this.store.account.isAdmin) {
            this.$editors = [];
            this.$editorGroups = [];
            this.$folderPermissions = [];
            let result = await this.service.listFolderPermissions(this.id);

            let userManager = new UserManager(this.store);

            await userManager.refresh();

            for (const dataObject of result) {
                let canWrite = dataObject.permissions ? dataObject.permissions.indexOf("write") >= 0 : false;
                let editorId = dataObject.user_id as string;
                // 忽略只有 read 属性的用户或用户组。
                if (canWrite) {
                    let editor = userManager.getUserById(editorId);
                    let editorGroup = userManager.getUserGroupById(editorId);
                    editor && this.editors.push(editor);
                    editorGroup && this.editorGroups.push(editorGroup);
                }
                this.folderPermissions.push({
                    userId: dataObject.user_id,
                    permissions: dataObject.permissions,
                });
            }
        }
        return this;
    }

    public setByDataObject(nodeObject: REST.INodeDetail) {
        super.setByDataObject(nodeObject);
        // this.$canWrite = null;

        this.$parents = FolderRef.createParents(nodeObject);

        this.$path = this.parents.map((p) => p.name).concat([this.name]).join("/");

        if (nodeObject.children) {

            this.listChildren(nodeObject);
        }

        this.refreshCounts();
        // 看当前用户的权限。
        // this.$canRead = nodeObject.permissions && nodeObject.permissions.indexOf("read") >= 0;
        // this.$canWrite = nodeObject.permissions && nodeObject.permissions.indexOf("write") >= 0;
        if (nodeObject.meta) {
            this.$strictType = nodeObject.meta.strict_type || null;
        }

        return this;
    }

    /**
     * 创建当前文件夹的子节点。
     * @returns 新的节点 ID。
     */
    public async createChild(type: string, name: string): Promise<string> {
        if (type !== "folder" && this.usedStrictType && this.inheritedStrictType !== type) {
            throw new Error(`文件夹开启了严格模式，只能创建文件夹，或者固定类型的文档。`);
        }
        let res = await this.store.service.createNode({
            name: name,
            type: type,
            parent: this.id,
        });

        return res.id;
    }

    /**
     * 更新文件夹参数。
     */
    public async update1(options: {
        strictType?: string,
        editorIds?: string[],
        editorGroupIds?: string[],
        readableUserIds?: string[],
    }) {

        let request: any = {};

        if (options.strictType) {
            request.meta = {
                strict_type: options.strictType,
            };
        }

        await this.service.updateFolder(this.id, request);

        this.$strictType = options.strictType;

        if (this.store.account.isAdmin && (options.editorIds || options.editorGroupIds || options.readableUserIds)) {
            let permissions = [];
            if (options.editorIds || options.editorGroupIds) {
                _.concat(options.editorIds, options.editorGroupIds).forEach((id) => {
                    permissions.push({
                        resource_id: this.id,
                        user_id: id,
                        permissions: ["read", "write"],
                    });
                });
            }
            if (options.readableUserIds) {
                options.readableUserIds.forEach((id) => {
                    permissions.push({
                        resource_id: this.id,
                        user_id: id,
                        permissions: ["read"],
                    });
                });
            }
            _.uniqBy(permissions, "user_id");
            // 设置权限
            await this.service.updateFolderPower(this.id, permissions);

            await this.refresh();
        }

        return this;
    }
    public async update(options: {
        strictType?: string,
        editorIds?: IFolderPermission[],
    }) {

        let request: any = {};

        if (options.strictType) {
            request.meta = {
                strict_type: options.strictType,
            };
        }

        await this.service.updateFolder(this.id, request);

        this.$strictType = options.strictType;

        if (this.store.account.isAdmin && (options.editorIds)) {
            let permissions = [];
            if (options.editorIds) {
                options.editorIds.forEach((item) => {
                    if (item.permissions && item.permissions.length > 0) {
                        permissions.push({
                            resource_id: this.id,
                            user_id: item.userId,
                            permissions: item.permissions,
                        });
                    }
                });
            }
            // 设置权限
            await this.service.updateFolderPower(this.id, permissions);

            await this.refresh();
        }

        return this;
    }

    private refreshCounts() {
        this.$folderCount = 0;
        this.$docCount = 0;

        for (const node of this.children) {
            if (node.type === "folder") {
                this.$folderCount++;
            } else {
                this.$docCount++;
            }
        }

        return this;
    }

    private listChildren(nodeObject: REST.INodeDetail) {
        let childObjects: any[] = nodeObject.children.items;
        let children: Node[] = [];
        this.$childFolders = [];

        if (childObjects !== null) {
            for (let childObject of childObjects) {
                childObject.parent = this.id;
                // 创建文件夹
                if (childObject.type === "folder") {
                    let childFolder = new Folder(this.store, childObject.id).setByDataObject(childObject);

                    /**
                     * 对于使用深度遍历返回的节点，是没有 parents 字段的，需要自己拼装一下。
                     * 把当前节点的 parents，再加上节点本身，就是子目录的 parents。
                     */
                    childFolder.$parents = _.clone(this.parents);
                    childFolder.parents.push(FolderRef.createByDataObject(nodeObject));
                    // 影响到路径也要重新拼装。
                    childFolder.$path = childFolder.parents.map((p) => p.name).concat([childFolder.name]).join("/");

                    this.childFolders.push(childFolder);
                    children.push(childFolder);
                } else {
                    // 创建文档
                    let childDoc = Doc.createByDataObject(childObject, this.store);
                    children.push(childDoc);
                }
            }
        }

        this.$children = children;
    }

    public static createByDataObject(nodeObject: REST.INodeDetail, store: Store) {
        let folder = new Folder(store, nodeObject.id);
        folder.setByDataObject(nodeObject);

        return folder;
    }

    public static createById(nodeId: string, store: Store) {
        let folder = new Folder(store, nodeId);

        return folder;
    }
}

export interface IFolderPermission {
    userId: string;
    permissions: string[];
}