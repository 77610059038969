import { Button, Form, Input, Modal, Popconfirm, Table } from "antd";
import * as mobx from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import "react-virtualized/styles.css";
import styled from "styled-components";
import v8n from "v8n";

import AuthoredComponent from "../AuthoredComponent";

const FormItem = Form.Item;

@observer
export default class TemplateManagementView extends AuthoredComponent<any> {

    @mobx.observable
    private tempModelName: string = "";
    @mobx.observable
    private isNameCorrect: boolean = true;

    @mobx.observable
    private nodeId: string = null;
    @mobx.observable
    private iscreateModel: boolean = false;

    public get columns(): IColumn[] { return this.$columns.slice(); }
    @mobx.observable
    private $columns: IColumn[] = [];

    public get dataList(): IData[] { return this.$dataList.slice(); }
    @mobx.observable
    private $dataList: IData[] = [];

    @mobx.computed
    private get templateNodes() {
        return this.store.templates.nodes;
    }

    public componentDidMount() {
        this.buildColumns();
        this.run("加载数据模板", async () => {

            await this.store.refresh();

            this.buildDataList();
        });
    }

    public componentWillUnmount() {
    }

    private buildColumns() {
        this.$columns.push({
            title: "ID",
            dataIndex: "id",
            key: "id",
            align: "center",
            render: (text) => <code>{text}</code>,
        });
        this.$columns.push({
            title: "",
            dataIndex: "thumbnail",
            key: "thumbnail",
            // rowKey: "id",
            align: "center",
            render: (thumbnail) => <Thumbnail src={thumbnail} />,
        });
        this.$columns.push({
            title: "名称",
            dataIndex: "name",
            key: "name",
            align: "center",
            render: (text) => <span>{text}</span>,
        });
        this.$columns.push({
            title: "创建时间",
            dataIndex: "created",
            key: "created",
            align: "center",
            render: (text) => <code>{text}</code>,
        });
        this.$columns.push({
            title: "更新时间",
            dataIndex: "updated",
            key: "updated",
            align: "center",
            render: (text) => <code>{text}</code>,
        });
        this.$columns.push({
            title: "操作",
            key: "action",
            align: "center",
            render: (text, record: IData) => (
                <>
                    <Button type="primary" style={{ marginRight: 15 }} onClick={() => {
                        this.page.goto(`/apps/editor/${record.id}`);
                    }}>
                        编辑
                    </Button>
                    <Button type="primary" style={{ marginRight: 15 }} onClick={() => {
                        this.tempModelName = record.name;
                        this.isNameCorrect = true;
                        this.nodeId = record.id;
                        this.iscreateModel = true;
                        // this.createModel(record.id);
                    }}>
                        重命名
                    </Button>
                    <Popconfirm placement="bottom" title={`确定删除模板 ${record.name}？`} okText="确定" cancelText="取消" okType="danger" onConfirm={async () => {
                        await this.store.templates.delete(record.id);
                        this.buildDataList();
                    }}>
                        <Button type="danger">删除</Button>
                    </Popconfirm>
                </>

            ),
        });
    }

    private buildDataList() {
        this.$dataList = [];
        for (const node of this.templateNodes) {
            this.$dataList.push({
                id: node.id,
                name: node.name,
                thumbnail: node.thumbnail,
                created: node.createdAtText,
                updated: node.updatedAtText,
            });
        }
    }
    private submit = async () => {
        // 验证数据的有效性
        if (this.isNameCorrect) {
            this.isNameCorrect = v8n().string().minLength(1).maxLength(20).test(this.tempModelName);
        } else {
            this.isNameCorrect = true;
        }
        if (await this.isNameCorrect) {
            await (this.nodeId ? this.store.templates.update(this.nodeId, this.tempModelName) : this.store.templates.create(this.tempModelName));
            await this.buildDataList();
            this.iscreateModel = false;
        }
    }

    public render() {
        return (
            <Container>
                <Toolbar>
                    <Button type="primary" onClick={() => {
                        this.tempModelName = "";
                        this.isNameCorrect = true;
                        this.iscreateModel = true;
                    }}> 新建模板 </Button>
                </Toolbar>
                <Table columns={this.columns} dataSource={this.dataList} rowKey="id" pagination={false} />
                <Modal
                    title={this.nodeId ? "重命名" : "创建模板"}
                    visible={this.iscreateModel}
                    footer={null}
                    closable={false}
                >
                    <ContainerModel>
                        <Form onSubmit={(evt) => { evt.preventDefault(); this.submit(); }}>
                            <FormItem {...formItemLayout}
                                validateStatus={this.isNameCorrect ? "success" : "error"}
                                help="名称不能为空,且不能包含 \ / : * ? < > | 符号 "
                                label="名称">
                                <Input
                                    value={this.tempModelName}
                                    onChange={(evt) => this.tempModelName = evt.target.value} />
                            </FormItem>
                            <FormItem style={{ textAlign: "right" }}>
                                <Button onClick={() => { this.iscreateModel = false; }}>取消</Button>
                                <Button type="primary" htmlType="submit" style={{ marginLeft: 8 }}>确认</Button>
                            </FormItem>

                        </Form>
                    </ContainerModel>
                </Modal>
            </Container>

        );
    }
}
const formItemLayout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 21 },
};
const Container = styled.div`
            position: absolute;
            left:   0;
            top:    0;
            right:  0;
            bottom: 0;
            padding: 20px;
            overflow: hidden;
    }
        `;
const ContainerModel = styled.div`
        .ant-form-item:last-child {
            margin-bottom: 0;
        }
`;
const Toolbar = styled.div`
            position: relative;
            margin-bottom: 20px;
        `;

const Thumbnail = styled.img`
            width:  36px;
            height: 36px;
            border-radius: 50%;
            /* border: 3px solid #fff; */
            box-shadow: 0 0 3px #666;
        `;
interface IColumn {
    title: string;
    dataIndex?: string;
    key: string;
    align: "left" | "right" | "center";
    render?: (text, record) => any;
}
interface IData {
    id: string;
    name: string;
    thumbnail: string;
    created: string;
    updated: string;
}
const ModalContainer = styled.div`
    padding: 20px 0 0 0;
    img {
        display: block;
        width: 256px;
        margin-top: 20px;
            }
    table {
        width: 100%;
        td {
        line - height: 35px;
        }
    }
`;