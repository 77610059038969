import { Button, Popconfirm, Table } from "antd";
import * as mobx from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import styled from "styled-components";

import UserGroup from "../../models/users/UserGroup";
import UserManager from "../../models/users/UserManager";
import BaseComponent from "../BaseComponent";

import UserGroupEditModal from "./UserGroupEditModal";
import UserGroupPermissionsModal from "./UserGroupPermissionsModal";
const { Column } = Table;

interface IProps {
    userManager: UserManager;
}
@observer
export default class UserGroupListView extends BaseComponent<IProps, any> {

    @mobx.computed
    public get userGroups() {
        return this.props.userManager.userGroups || [];
    }
    @mobx.observable
    private userManager: UserManager = this.props.userManager;

    @mobx.observable
    private currentUserGroup: UserGroup = null;

    private updateShow() {
        UserGroupEditModal.show(this.userManager, this.currentUserGroup, "update");
    }

    private createShow() {
        UserGroupEditModal.show(this.userManager, null, "create");
    }

    public permissionShow() {
        UserGroupPermissionsModal.show(this.userManager, this.currentUserGroup)
    }

    public render() {
        return (
            <Container>
                <Toolbar>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <Button type="primary" icon="plus" onClick={() => this.createShow()}>添加</Button>
                                    <Counter>共有 {this.userGroups.length} 个用户组。</Counter>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Toolbar>
                <Table scroll={{ y: window.innerHeight - 240 }} dataSource={this.userGroups} rowKey="id" useFixedHeader={true} bordered={true} pagination={false}>
                    <Column dataIndex="name" align="center" title="名字" width={200} render={(_, user: UserGroup) => <div>{user.name}</div>} />
                    <Column dataIndex="createAt" align="center" title="创建日期" width={200} render={(_, user: UserGroup) => <code>{user.createdAt.format("YYYY-MM-DD HH:mm:ss")}</code>} />

                    <Column dataIndex="" align="center" title="操作" width={200} render={(_, user: UserGroup) => (
                        <Actions>
                            <Button type="primary" onClick={() => {
                                this.currentUserGroup = user;
                                this.updateShow();
                            }}>编辑</Button>
                            <Button
                                type="primary"
                                onClick={() => {
                                    this.currentUserGroup = user;
                                    this.permissionShow();
                                }}
                            >
                                权限
                            </Button>
                            <Popconfirm placement="bottom" title="确定删除用户?" okText="确定" cancelText="取消" okType="danger" onConfirm={async () => {
                                await user.delete();
                            }}>
                                <Button type="danger">删除</Button>
                            </Popconfirm>
                        </Actions>
                    )} />
                </Table>
            </Container>
        );
    }
}

const Container = styled.div`
`;

const Toolbar = styled.div`
    height: 50px;
`;

const Actions = styled.div`
    .ant-btn {
        margin-left:10px;
        margin-right: 10px;
    }
`;
const Counter = styled.div`
    display: inline-block;
    padding-left: 10px;
    font-size: 13px;
`;