import kjua from "kjua";

export default class QRCodeGenerator {
    public static async generate(link: string, opts): Promise<string> {
        opts = opts || {};
        return new Promise<string>((resolve, rejecct) => {
            let mode = opts.image || opts.imageUrl ? "image" : "plain";
            let size = opts.size || 1000;
            let qrParams = {
                // render method: 'canvas' or 'image'
                render: "image",

                // render pixel-perfect lines
                crisp: true,

                // minimum version: 1..40
                minVersion: 1,

                // error correction level: 'L', 'M', 'Q' or 'H'
                ecLevel: "H",

                // size in pixel
                size: size,

                // pixel-ratio, null for devicePixelRatio
                ratio: null,

                // code color
                fill: "#333",

                // background color
                back: "#fff",

                // content
                text: link,

                // roundend corners in pc: 0..100
                rounded: 100,

                // quiet zone in modules
                quiet: 0,

                // modes: 'plain', 'label' or 'image'
                mode: mode,

                // label/image size and pos in pc: 0..100
                mSize: 30,
                mPosX: 50,
                mPosY: 50,

                // label
                label: "",
                fontname: "sans",
                fontcolor: "#333",

                // image element
                image: opts.image || null,
            };
            if (!opts.image && opts.imageUrl) {
                let img = new Image();
                img.onload = () => {
                    qrParams.image = img;
                    let qrEL: HTMLImageElement = null;
                    try {
                        qrEL = kjua(qrParams);
                    } catch (error) {
                        qrParams.mode = "plain";
                        qrEL = kjua(qrParams);
                    }
                    QRCodeGenerator.toBlobUrl(qrEL).then((blobUrl) => {
                        resolve(blobUrl);
                    });
                };
                img.onerror = (err) => {
                    qrParams.mode = "plain";
                    let qrEL: HTMLImageElement = kjua(qrParams);
                    QRCodeGenerator.toBlobUrl(qrEL).then((blobUrl) => {
                        resolve(blobUrl);
                    });
                };
                img.src = opts.imageUrl;
            } else {
                let qrEL: HTMLImageElement = kjua(qrParams);
                QRCodeGenerator.toBlobUrl(qrEL).then((blobUrl) => {
                    resolve(blobUrl);
                });
            }
        });
    }

    private static async toBlobUrl(img: HTMLImageElement): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            if (!img) {
                reject();
            }
            img.onload = () => {
                let canvas = document.createElement("canvas");
                canvas.width = img.width;
                canvas.height = img.height;
                let context = canvas.getContext("2d");
                context.drawImage(img, 0, 0);
                canvas.toBlob((blob: Blob) => {
                    if (!blob) {
                        reject();
                        return;
                    }
                    canvas.remove();
                    let blobUrl = URL.createObjectURL(blob);
                    resolve(blobUrl);
                });
            };
        });
    }
}
