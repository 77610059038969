import { Col, Row } from "antd";
import * as mobx from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import styled, { injectGlobal } from "styled-components";

import Folder from "../../models/Folder";
import Node from "../../models/Node";
import BaseComponent from "../BaseComponent";

import ContextMenu from "./ContextMenu";
import EventBus from "./EventBus";
interface IProps {
    eventBus: EventBus;
    node?: Node;
    gutter?: number;
    onSelect(): void;
    selectedNode?: Node;
    viewType: string;
    folder: Folder;
    highlight?: string;
    onCheck(): void;
}

@observer
export default class DocItemView extends BaseComponent<IProps, any> {

    @mobx.computed
    public get type() { return this.props.node.type; }

    @mobx.computed
    public get node() { return this.props.node; }

    @mobx.computed
    public get folder(): Folder {
        return this.node instanceof Folder ? this.node : null;
    }

    @mobx.computed
    public get selectedNode() { return this.props.selectedNode; }

    @mobx.computed
    public get active() { return this.node === this.selectedNode; }

    @mobx.computed
    public get isSearchNode() { return this.highlight === this.node.id; }

    @mobx.computed
    public get iconUrl() {
        return this.store.models.getIconUrl(this.type);
    }

    @mobx.computed
    public get highlight() {
        return this.props.highlight;
    }

    public componentDidMount() {
    }
    private openNode() {
        this.props.eventBus.open.emit({
            node: this.node,
            mode: null,
        });
    }

    public render() {
        const menuProps = {
            node: this.node,
            active: this.active,
            viewType: this.props.viewType,
            eventBus: this.props.eventBus,
        };
        return (
            <Col id={this.node.id}
                // xs={24} sm={12} md={8} lg={6} xl={4} xxl={3}
                span={4}
                style={{ padding: `0px`, maxWidth: 250, minWidth: 200 }}>
                <ItemCard
                    className={this.classnames({ "active": this.active, "search-highlight": this.isSearchNode, "checked": this.node.isChecked })}
                    title={this.node.name}>
                    <ItemLink onClick={() => this.openNode()} className="item-content" onContextMenu={(evt) => {
                        evt.nativeEvent.stopImmediatePropagation();
                        evt.nativeEvent.preventDefault();
                        this.props.onSelect();
                    }}>
                        <ItemThumbnail
                            src={this.node.thumbnail}
                            className={this.classnames({
                                transparent: this.type !== "folder",
                            })}
                            onMouseDown={(evt) => evt.nativeEvent.preventDefault()}
                            onError={(evt) => evt.currentTarget.src = "/assets/img/thumbnail-empty.png"}
                        />
                        <ItemName><p>{this.node.name}</p></ItemName>
                        {this.store.preferences.showNodeIcon &&
                            (this.node instanceof Folder ?
                                this.node.usedStrictType &&
                                <ItemType
                                    className="center"
                                    src={this.store.models.getIconUrl(this.folder.inheritedStrictType)}
                                    onMouseDown={(evt) => evt.nativeEvent.preventDefault()}
                                />
                                :
                                <ItemType
                                    src={this.iconUrl}
                                    onMouseDown={(evt) => evt.nativeEvent.preventDefault()}
                                />
                            )
                        }
                        {/* {!this.node.canWrite &&
                            <LockIcon className={this.classnames({ center: this.node instanceof Folder })}></LockIcon>
                        } */}
                    </ItemLink>
                    <ContextMenuIcon className="blue-check-icon" href="javascript:;" onClick={(evt) => {
                        evt.nativeEvent.stopImmediatePropagation();
                        this.props.onSelect();
                    }}>
                        <i className="fas fa-fw fa-cog" />
                    </ContextMenuIcon>
                    <CheckBoxIcon className="" href="javascript:;" onClick={(evt) => {
                        evt.nativeEvent.stopImmediatePropagation();
                        this.props.onCheck();
                    }}>
                        {this.node.isChecked && <i className="fas fa-fw fa-check" />}
                    </CheckBoxIcon>

                    {/* 右键菜单 */}
                    <ContextMenu readonly={this.props.folder && !this.props.folder.canWrite} {...menuProps} />

                </ItemCard>
            </Col>);
    }
}
const ItemCard = styled.div`
    width: 100%;
    text-align: center;
    padding: 20px 10px 10px 10px;
    border-radius: 0;
    position: relative;
    &.active{
        background-color: #f1f5fa;
        .blue-check-icon {
            display: block;
        }
    }
    &.checked{
        background-color: #f1f5fa;
    }
    &:hover {
        background-color: #f1f5fa;
        .blue-check-icon {
            display: block;
        }
    }
    .item-content {
        height: 100%;
    }
    .blue-check-icon {
        display: none;
    }
`;
const ContextMenuIcon = styled.a`
    position: absolute;
    display: block;
    margin:  0;
    top:   ${8 + 5}px;
    right: 5px;
    width:  24px;
    height: 24px;
    font-size: 16px;
    text-align: center;
    color: #20a0ff;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 1px #666;
    cursor: pointer;
    i {
        line-height: 24px;
    }
`;
const CheckBoxIcon = styled.a`
    position: absolute;
    display: block;
    margin:  0;
    top:   ${8 + 5}px;
    left: 5px;
    width:  24px;
    height: 24px;
    font-size: 16px;
    text-align: center;
    color: #20a0ff;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 1px #666;
    cursor: pointer;
    i {
        line-height: 24px;
    }
`;
const ItemThumbnail = styled.img`
    display: block;
    margin: 0 auto;
    max-width: 100%;
    border-radius: 0;
    &.transparent {
        background-image: url(/assets/img/transparent.jpg);
    }
`;
const ItemName = styled.div`
    padding: 10px 0 0 0;
    line-height: 1;
    color: #000;
    p{
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin:0;
    }
`;
const ItemType = styled.img`
    position: absolute;
    right:  10px;
    bottom: 40px;
    width:  32px;
    height: 32px;
    &.center {
        width:  32px;
        height: 32px;
        left:   50%;
        right:  auto;
        top:    52%;
        bottom: auto;
        transform: translate(-16px, -16px);
        border-radius: 50%;
        /* box-shadow: 0 0 3px #444; */
    }
`;
const LockIcon = styled.div`
    position: absolute;
    left:   10px;
    bottom: 40px;
    width:  32px;
    height: 32px;
    background-image: url(assets/img/lock.png);
    background-size: 32px 32px;
    &.center {
        background-size: 24px 24px;
        width:  30px;
        height: 30px;
        left:   50%;
        right:  auto;
        top:    60%;
        bottom: auto;
        border-radius: 50%;
        transform: translate(-15px, -15px);
        border: 3px solid #fff;
    }
`;
const ItemLink = styled.a`
    display:inline-block;
    width:100%;
`;