import _ from "lodash";
import * as mobx from "mobx";
import moment from "moment";

import * as REST from "./Service";
import Store from "./Store";

export default class Node {

    public readonly service: REST.Service;

    /**
     * 资源 ID。全局唯一，不可修改。
     */
    public get id() { return this.$id; }
    @mobx.observable
    private $id: string = null;

    /**
     * 资源类型，直接对应插件的 code。
     */
    public get type() { return this.$type; }
    @mobx.observable
    private $type: string = null;

    /**
     * 资源的名字。可以重复。
     */
    public get name() { return this.$name; }
    @mobx.observable
    private $name: string = null;

    // /**
    //  * 资源的手工排序的顺序。
    //  */
    // @mobx.computed
    // public get order() { return this.$order; }
    // @mobx.observable
    // private $order: number = null;

    /**
     * 资源的缩略图。大小不固定。
     */
    public get thumbnail() { return this.$thumbnail; }
    @mobx.observable
    private $thumbnail: string = null;

    public get createdAt() { return this.$createdAt; }
    @mobx.observable
    private $createdAt: Date = null;

    public get updatedAt() { return this.$updatedAt; }
    @mobx.observable
    private $updatedAt: Date = null;

    @mobx.computed
    public get createdAtText() { return moment(this.createdAt).format("YYYY-MM-DD HH:mm:ss"); }

    @mobx.computed
    public get updatedAtText() { return moment(this.updatedAt).format("YYYY-MM-DD HH:mm:ss"); }

    /**
     * 父资源的 ID。
     */
    public get parentId() { return this.$parentId; }
    @mobx.observable
    private $parentId: string = null;

    /**
     * 资源元数据，用于检索。
     */
    public get meta() { return this.$meta; }
    private $meta: any = null;

    /**
     * 资源挂载的数据。一般是 URL。
     */
    public get data() { return this.$data; }
    @mobx.observable
    private $data: string = null;

    /**
     * 当前用户是否有查看的权限。
     */
    public get canRead() { return this.$canRead; }
    @mobx.observable
    private $canRead: boolean = null;

    /**
     * 当前用户是否有编辑的权限。
     */
    public get canWrite() { return this.$canWrite; }
    @mobx.observable
    private $canWrite: boolean = null;

    /**
     * 当前节点是否被勾选。
     */
    @mobx.computed
    public get isChecked() { return this.$isChecked; }
    public set isChecked(value) { this.$isChecked = value; }
    @mobx.observable
    private $isChecked: boolean = false;

    protected constructor({ service }: { service: REST.Service }, id: string) {
        this.$id = id;
        this.service = service;
    }

    /**
     * 删除这个节点。
     */
    public async delete() {
        await this.service.deleteNode(this.id);

        return this;
    }

    /**
     * 复制这个节点。
     */
    public async copyTo(parentId: string, newName: string) {
        await this.service.createNode({
            type: this.type,
            name: newName,
            parent: parentId,
            source: this.id,
        });

        return this;
    }

    /**
     * 移动这个节点到新的文件夹
     */
    public async moveTo(parentId: string) {
        if (parentId === this.parentId) {
            // 不用移动
        }
        let data = await this.service.updateNode(this.id, {
            parent: parentId,
        });

        this.setByDataObject(data);

        return this;
    }

    /**
     * 重命名这个节点。
     * @param newName 新的名字。
     */
    public async rename(newName: string) {
        if (newName === this.name) {
            // 不用改名
            return;
        }
        let data = await this.service.updateNode(this.id, {
            name: newName,
        });

        this.setByDataObject(data);

        return this;
    }

    /**
     * 给节点一个新的顺序。
     * @param newName 新的名字。
     */
    public async reorder(newOrder: number) {
        // if (newOrder === this.order) {
        //     return;
        // }
        let data = await this.service.updateNode(this.id, {
            order: newOrder,
        });

        this.setByDataObject(data);

        return this;
    }

    /**
     * 下载这个节点的数据 zip。
     */
    public async download(): Promise<Blob> {
        return await this.service.readNodeZip(this.id);
    }

    public setByDataObject(dataObject: REST.INode) {
        this.$id = dataObject.id;
        this.$type = dataObject.type || null;
        this.$name = dataObject.name || null;
        this.$thumbnail = this.getThumbnailUrl(dataObject.thumbnail);
        this.$parentId = dataObject.parent || null;
        this.$meta = dataObject.meta || {};
        this.$data = dataObject.data || null;

        this.$createdAt = dataObject.created_at ? moment(dataObject.created_at).toDate() : null;
        this.$updatedAt = dataObject.updated_at ? moment(dataObject.updated_at).toDate() : null;

        /**
         * 检查权限。
         */
        let nodeDetailObject = dataObject as REST.INodeDetail;
        if (nodeDetailObject.permissions) {
            this.$canRead = nodeDetailObject.permissions.indexOf("read") >= 0;
            this.$canWrite = nodeDetailObject.permissions.indexOf("write") >= 0;
        }

        return this;
    }

    private getThumbnailUrl(thumbnail: string) {
        if (thumbnail) {
            if (thumbnail.startsWith("http://") || thumbnail.startsWith("https://")) {
            } else {
                thumbnail = _.trim(thumbnail, "/\\");
                thumbnail = `/apis/assets/${thumbnail}`;
            }
            return thumbnail;
        } else {
            return (this.type === "folder" ?
                "/assets/img/thumbnail-folder-inverse.png" :
                "/assets/img/thumbnail-empty.png");
        }
    }

    protected static createById(nodeId: string, store: Store): Node {
        let node = new Node(store, nodeId);
        // node.$id = nodeId;

        return node;
    }

    public async refresh() {
        let data = await this.service.readNodeById(this.id);

        this.setByDataObject(data);

        return this;
    }
}