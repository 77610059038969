import { Button, Col, Row } from "antd";
import * as mobx from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { Prompt, RouteComponentProps } from "react-router-dom";
import styled from "styled-components";

import AuthoredComponent from "../AuthoredComponent";

interface IProps extends RouteComponentProps<any> {
    url: string;
}

@observer
export default class ExternalView extends AuthoredComponent<IProps> {
    public componentDidMount() {

    }

    public render() {
        return (
            <Container>
                <iframe src={this.props.url} />
            </Container>
        );
    }
}
const Container = styled.div`
    position: absolute;
    left:   0;
    top:    0;
    right:  0;
    bottom: 0;
    overflow: hidden;
    border-left:1px solid #ececec;
`;
const Toolbar = styled.div`
    position: absolute;
    left:   0;
    top:    0;
    right:  0;
    height: 50px;
    box-shadow: 0 0 5px #ccc;
    /* border-bottom: 1px solid #ececec; */
    padding: 0 20px;
    z-index: 10;
    button {
        width: 120px;
        margin:  0;
        padding: 0;
        height: auto;
        line-height: 30px;
    }
`;
const Title = styled.span`
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    margin: 0 0 0 20px;
`;
const Content = styled.div`
    position: absolute;
    left:   0;
    right:  0;
    top:    50px;
    bottom: 0;
`;
const CodeBlock = styled.pre`
    position: absolute;
    left:   0;
    top:    0;
    right:  0;
    bottom: 0;
    z-index: 100;
    background: #fff;
    padding: 20px;
    overflow: scroll;
`;