import _ from "lodash";
import * as mobx from "mobx";
import moment from "moment";

import DocRef from "./DocRef";
import FolderRef from "./FolderRef";
import Node from "./Node";
import * as REST from "./Service";
import Store from "./Store";
import User from "./users/User";
import UserGroup from "./users/UserGroup";
import UserManager from "./users/UserManager";

/**
 * 文档节点的封装类。
 *
 * Document 是关键字，所以用 Doc。
 * 另外，不用 DocNode 作为名字的原因是，Folder 也是 Node，任何资源都是 Node，就没必要再加一个 Node 作为类名了。
 */
export default class Doc extends Node {

    private readonly store: Store = null;
    // private get service() { return this.store.service; }

    // /**
    //  * 文档的ID。
    //  */
    // public get id() { return this.$id; }
    // @mobx.observable
    // private $id: string = null;

    // /**
    //  * 文档的名字。
    //  */
    // public get name() { return this.$name; }
    // @mobx.observable
    // private $name: string = null;

    /**
     * 路径。
     */
    public get path() { return this.$path; }
    @mobx.observable
    private $path: string = null;

    // /**
    //  * 文档的类型。
    //  */
    // public get type() { return this.$type; }
    // @mobx.observable
    // private $type: string = null;

    // public get node() { return this.$node; }
    // @mobx.observable
    // private $node: Node = null;

    /**
     * 这个文档引用了哪些文档。
     */
    public get references() { return this.$references; }
    @mobx.observable
    private $references: DocRef[] = [];

    /**
     * 这个文档被哪些文档引用。
     */
    public get beReferenced() { return this.$beReferenced; }
    @mobx.observable
    private $beReferenced: DocRef[] = [];

    /**
     * 可以编辑这个文档内容的用户。【只有管理员才能有这个属性】
     */
    public get editors() { return this.$editors; }
    @mobx.observable
    private $editors: User[] = null;

    /**
     * 可以编辑这个文档内容的用户组。【只有管理员才能有这个属性】
     */
    public get editorGroups() { return this.$editorGroups; }

    @mobx.observable
    private $editorGroups: UserGroup[] = null;

    /**
     * 父文件夹。根目录是文档库。
     */
    public get parents() { return this.$parents; }
    @mobx.observable
    private $parents: FolderRef[] = [];

    constructor(store: Store, id: string) {
        super(store, id);
        this.store = store;
    }

    public setByDataObject(nodeDetail: REST.INodeDetail) {
        super.setByDataObject(nodeDetail);
        // this.$id = nodeDetail.id;
        // this.$name = nodeDetail.name;
        // this.$type = nodeDetail.type;
        this.$parents = FolderRef.createParents(nodeDetail);

        this.$path = this.parents.map((p) => p.name).concat([this.name]).join("/");

        // this.$node = new Node(this.store).setByDataObject(nodeDetail);
        // this.$node = this;
        // 看当前用户的权限。
        // if (nodeDetail.permissions) {
        //     this.$canRead = nodeDetail.permissions.indexOf("read") >= 0;
        //     this.$canWrite = nodeDetail.permissions.indexOf("write") >= 0;
        // } else {
        //     this.$canRead = false;
        //     this.$canWrite = false;
        // }
        if (nodeDetail.references) {
            this.$references = nodeDetail.references.map((dataObject) => DocRef.createByDataObject(dataObject));
        }

        return this;
    }

    /**
     * 拉取并更新视图数据。
     */
    public async refresh() {
        // 读取文档基础数据。
        {
            let result = await this.service.readDoc(this.id);
            let nodeDetail = result as REST.INodeDetail;

            this.setByDataObject(nodeDetail);
        }

        // 读取这个文档被引用的信息
        {
            let result = await this.service.listDocBeReferenced(this.id);

            this.$beReferenced = result.items.map((dataObject) => DocRef.createByDataObject(dataObject));
        }

        // 读取权限信息。只有管理员角色才能获取。
        if (this.store.account.isAdmin) {
            this.$editors = [];
            this.$editorGroups = [];
            let result = await this.service.readDocPermissions(this.id);

            let userManager = new UserManager(this.store);
            await userManager.refresh();

            for (const dataObject of result) {
                let canWrite = dataObject.permissions ? dataObject.permissions.indexOf("write") >= 0 : false;
                let editorId = dataObject.user_id as string;
                // 忽略只有 read 属性的用户或用户组。
                if (canWrite) {
                    let editor = userManager.getUserById(editorId);
                    let editorGroup = userManager.getUserGroupById(editorId);
                    editor && this.editors.push(editor);
                    editorGroup && this.editorGroups.push(editorGroup);
                }
            }
        }

        return this;
    }

    /**
     * 更新文档参数。
     */
    public async update(
        // 引用的子文档，如果清空引用关系，传 []，不要传 null。
        references?: string[],
    ) {

        let request: any = {};

        request.references = references;

        await this.service.updateDoc(this.id, request);

        await this.refresh();

        return this;
    }

    public static createById(id: string, store: Store) {
        let doc = new Doc(store, id);
        // doc.$id = id;

        return doc;
    }

    public static createByDataObject(nodeObject: REST.INodeDetail, store: Store) {
        let doc = new Doc(store, nodeObject.id);
        // doc.$id = nodeObject.id;
        doc.setByDataObject(nodeObject);

        return doc;
    }
}