import * as mobx from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import styled from "styled-components";

import Node from "../../models/Node";
import BaseComponent from "../BaseComponent";
import EventBus from "../documents/EventBus";

const FontAwesomeIcon = (props) => <i className={`${props.icon} fa-fw`} />;
interface IProps {
    eventBus: EventBus;
    node?: Node;
    active?: boolean;
    viewType: string;
    x?: number;
    y?: number;
    readonly: boolean;
}
@observer
export default class ContextMenu extends BaseComponent<IProps, any> {

    @mobx.computed
    public get type() { return this.props.node.type; }

    @mobx.computed
    public get node() { return this.props.node; }

    @mobx.computed
    public get active() { return this.props.active; }

    @mobx.computed
    public get eventBus() { return this.props.eventBus; }

    // 列表和卡片模式下，菜单的样式有区别
    @mobx.computed
    public get menuStyle() {
        if (this.props.viewType === "list") {
            let height = window.innerHeight;
            let menuHeight = this.type === "folder" ? 210 : 365;
            if (this.props.readonly) {
                menuHeight = this.type === "folder" ? 80 : 180;
            }
            return {
                position: "fixed" as "fixed",
                top: menuHeight + this.props.y > height ? this.props.y - menuHeight : this.props.y ,
                left: this.props.x + 5,
                width: 118,
            };
        }
        return {};
    }

    private openEditorPage() {
        this.eventBus.open.emit({
            node: this.node,
            mode: "edit",
        });
    }

    private openViewer() {
        this.eventBus.open.emit({
            node: this.node,
            mode: "view",
        });
    }

    public render() {
        return (
            <Container className={this.active ? "active" : null} style={this.menuStyle}>
                <ul className="context-menu">
                    {this.isDebug &&
                        <MenuItem visible={this.type !== "folder"} text="测试" icon="fa-vial" onClick={() => this.eventBus.test.emit(this.node)} />
                    }
                    {!this.props.readonly &&
                        <MenuItem visible={this.type !== "folder"} text="编辑" icon="fa-edit" onClick={() => this.openEditorPage()} />
                    }

                    {/* 只有支持 view 模式的节点类型，才能查看 */}
                    {this.store.models.canView(this.node) &&
                        <MenuItem visible={this.type !== "folder"} text="查看" icon="fa-eye" onClick={() => this.openViewer()} />
                    }

                    {this.type === "folder" && this.store.account.isSuperAdmin &&
                        <MenuItem text="属性" icon="fa-edit" onClick={() => this.eventBus.property.emit(this.node)} />
                    }
                    <MenuItem text="信息" icon="fa-info-circle" onClick={() => this.eventBus.info.emit(this.node)} />

                    {/* 只有支持 view 模式的节点类型，才能有二维码 */}
                    {this.store.models.canView(this.node) &&
                        <MenuItem visible={this.type !== "folder"} text="二维码" icon="fa-qrcode" onClick={() => this.eventBus.qrcode.emit(this.node)} />
                    }

                    <MenuSplit />
                    <MenuItem text="收藏" icon="fa-thumbtack" onClick={() => this.eventBus.mark.emit(this.node)} />

                    {!this.props.readonly &&
                        <>
                            <MenuItem visible={this.type !== "folder"} text="复制" icon="fa-copy" onClick={() => this.eventBus.copy.emit(this.node)} />
                            <MenuItem text="移动" icon="fa-arrows-alt" onClick={() => this.eventBus.move.emit(this.node)} />
                            <MenuItem text="重命名" icon="fa-pencil-alt" onClick={() => this.eventBus.rename.emit(this.node)} />
                        </>
                    }
                    <MenuItem text="下载" visible={this.type !== "folder"} icon="fa-download" onClick={() => this.eventBus.download.emit(this.node)} />
                    {!this.props.readonly &&
                        <>
                        <MenuSplit />
                        <MenuItem text="删除" icon="fa-trash-alt" onClick={() => this.eventBus.delete.emit(this.node)} />
                        </>
                    }
                </ul>
                {
                    this.props.viewType === "card" && <>
                        <div className="arrow outer" />
                        <div className="arrow inner" />
                    </>
                }
            </Container>
        );
    }
}

const MenuItem = (props: {
    text: string,
    icon: string,
    visible?: boolean,
    onClick: () => void,
}) => {
    if (props.visible === false) {
        return null;
    }
    return <li>
        <a onClick={() => props.onClick()}>
            {/* <FontAwesomeIcon icon={["fal", props.icon] as any} fixedWidth />　<span>{props.text}</span> */}
            <FontAwesomeIcon icon={`${props.icon} fal fa-fw`}/>　<span>{props.text}</span>
        </a>
    </li>;
};

const MenuSplit = () => <li className="split" />;

const Container = styled.div`
    display:  none;
    position: absolute;
    right: 1px;
    top:   44px;
    min-width: 130px;
    background: #fff;
    /* border: 1px solid #ccc; */
    z-index: 999;
    box-shadow: 0 2px 2px 0    rgba(0, 0, 0, 0.24),
                0 3px 1px -2px rgba(0, 0, 0, 0.30),
                0 1px 5px 0    rgba(0, 0, 0, 0.22);
    &.active {
        display:block;
    }
    & > ul.context-menu {
        text-align: left;
        margin: 0;
        padding: 5px 0;
        list-style: none;
        font-size: 14px;
        li {
            a {
                display: block;
                color: #000;
                padding: 5px 10px 5px 20px;
                i {
                    margin-right: 0px;
                }
            }
            &:hover {
                background-color: #ececec;
            }
            &.split {
                margin: 3px 0;
                border-bottom: 1px solid #eee;
            }
        }
    }
    .arrow {
        content: " ";
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-color: transparent;
        border-top: 0;
        right: 8px;
    }
    .outer {
        top: -8px;
        border-width: 8px;
        border-bottom-color: rgba(0, 0, 0, 0.2);
    }
    .inner {
        top: -7px;
        border-width: 8px;
        border-bottom-color: #fff;
    }
`;