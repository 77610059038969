import _ from "lodash";
import * as mobx from "mobx";

import Node from "./Node";
import Store from "./Store";

export class Templates {

    public readonly store: Store = null;
    public get service() { return this.store.service; }

    /**
     * 全部模板的节点数据定义。
     */
    @mobx.computed
    public get nodes() { return this.$nodes; }
    @mobx.observable
    private $nodes: Node[] = [];

    constructor(store: Store) {
        this.store = store;
    }

    public async setup() {
        await this.list();
    }

    public async refresh() {
        await this.list();
    }

    public getById(id: string) {
        return _.find(this.nodes, (n) => n.id === id);
    }

    /**
     * 创建一个新的模板。
     * @param name 模板名字，不能重复
     */
    public async create(name: string) {
        await this.service.createNode({
            parent: "00000000-0000-0000-0000-000000000030",
            type: "template",
            name: name,
        });
        await this.list();
    }

    /**
     * 删除一个模板。
     * @param nodeId 模板ID
     */
    public async delete(nodeId: string) {
        await this.service.deleteNode(nodeId);
        await this.list();
    }

    /**
     * 修改模板的名字。
     * @param nodeId 模板ID
     * @param name 模板新的名字
     */
    public async update(nodeId: string, name: string) {
        await this.service.updateNode(nodeId, {
            name: name,
        });
        await this.list();
    }

    /**
     * 刷新模板节点列表。
     */
    private async list() {
        // let nodeDetail = await Node.createById("00000000-0000-0000-0000-000000000030", this.store).refresh();
        // this.$nodes = nodeDetail.children;
    }

}