import { message } from "antd";
import classnames from "classnames";
import * as React from "react";

import Exceptions from "../models/Exceptions";
import Store from "../models/Store";

import Page from "./Page";
import Task from "./Task";

export default abstract class BaseComponent<P, S> extends React.Component<P, S> {

    protected get store() { return (window as any).store as Store; }

    protected get task() { return (window as any).task as Task; }

    protected get page() { return (window as any).page as Page; }

    protected get isDebug() { return (window as any).isDebug as boolean; }

    protected get classnames() { return classnames; }

    /**
     * 执行一个长时间的任务。成功失败应该都有提示。
     * @param commandName 任务名字
     * @param command 任务函数
     */
    protected async run(commandName: string, command: () => Promise<any>, errorHandler: (error: any) => void = null) {
        try {
            await this.task.run(commandName, command);
        } catch (error) {
            // 对于全局错误，需要特殊处理
            if (Exceptions.isUnauthorized(error)) {
                this.store.account.logout();
                let path = `/${window.location.pathname === "/" ? "" : "?callback=" + window.location.pathname}`;
                this.page.goto(path);
                // alert(path);
            } else {
                if (errorHandler) {
                    // 如果调用方自己处理，那就回调。
                    errorHandler(error);
                } else {
                    // 如果调用方不处理，那就默认发出错误。
                    let errorMessage = `${commandName}出错`;
                    // 如果出错，一定要提示用户，不能什么也不说
                    message.warning(errorMessage);
                }
            }
        }
    }
}