import { Layout } from "antd";
import * as mobx from "mobx";
import { observer } from "mobx-react";
import * as React from "react";
import { Helmet } from "react-helmet";
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from "react-router-dom";
import styled from "styled-components";

import ActivityListView from "./activities/ActivityListView";
import BaseComponent from "./BaseComponent";
import Cover from "./common/Cover";
import GlobalBar from "./content/GlobalBar";
import WelcomeView from "./content/WelcomeView";
import DocRootView from "./documents/DocRootView";
import NodeSearchView from "./documents/NodeSearchView";
import Hezhi from "./hezhi/Hezhi";
import HezhiWelcomeView from "./hezhi/HezhiWelcomeView";
import ExternalView from "./plugins/ExternalView";
import PluginView from "./plugins/PluginView";
import TemplateManagementView from "./templates/TemplateManagementView";
import UserManagementView from "./users/UserRootView";

const { Content } = Layout;
export default withRouter((props) => <Application {...props} />);

interface IProps extends RouteComponentProps<any> {
}
@observer
class Application extends BaseComponent<IProps, any> {

    @mobx.observable
    private isReady: boolean = false;

    @mobx.observable
    private title: string = null;

    @mobx.observable
    private $isShow: boolean = true;

    @mobx.computed
    public get isShow() {

        if (this.props.location.pathname.startsWith("/apps/editor")) {
            return this.$isShow = false;
        } else {
            return this.$isShow = true;
        }
    }

    constructor(props) {
        super(props);

        this.page.routerProps = props;
    }

    public UNSAFE_componentWillReceiveProps(nextProps: IProps) {
        this.page.routerProps = nextProps;
    }

    public async componentDidMount() {
        this.isReady = true;
        this.title = this.store.config.title;
    }

    private getActivePath(): string {
        let pathsubs = this.props.location.pathname.split("/");
        if (pathsubs && pathsubs.length > 1) {
            if (pathsubs[1] === "external") {
                return `${pathsubs[1]}${window.location.search}`;
            }
            return pathsubs[1];
        } else {
            return "list";
        }
    }

    public render() {
        return (
            <Layout style={LayoutStyle}>
                <Helmet>
                    <title>{this.title}</title>
                </Helmet>
                <Cover />
                {this.isReady &&
                    <>
                        <GlobalBar activePath={this.getActivePath()} visible={this.isShow} />
                        <Content style={{ position: "relative" }}>
                            <RouteContainer>
                                <Switch>
                                    {/* 用户登录 */}
                                    <Route exact path="/" component={Hezhi.isRunning ? HezhiWelcomeView : WelcomeView} />

                                    {/* 需要登录才能生效的部分 */}
                                    <Route exact path="/list" component={DocRootView} />
                                    <Route exact path="/list/:resource_id" component={DocRootView} />

                                    <Route exact path="/search" component={NodeSearchView} />

                                    {(this.store.account.isSuperAdmin) && <Route exact path="/activities" component={ActivityListView} />}
                                    {this.store.account.isSuperAdmin && <Route exact path="/templates" component={TemplateManagementView} />}
                                    {(this.store.account.isSuperAdmin || this.store.account.PermissionList.find((e) => e === "users")) && <Route exact path="/users" component={UserManagementView} />}
                                    <Route exact path="/apps/:mode/:resource_id" render={(props) => {
                                        let mode: any = props.match.params.mode === "viewer" ? "view" : "edit";
                                        let nodeId = props.match.params.resource_id;
                                        return <PluginView mode={mode} docId={nodeId} {...props} />;
                                        // return <PluginView mode={mode} {...props} />;
                                    }} />
                                    <Route exact path="/external" render={(props) => {
                                        console.log(props.match, props);
                                        let query = new URLSearchParams(props.location.search);
                                        let url: string = query.get("path") || "/list";
                                        return <ExternalView url={url}{...props} />;
                                    }} />
                                    {/* NotFound */}
                                    <Route render={() => {
                                        console.log(`redirect from ${window.location.href}`);
                                        return <Redirect to={"/"} />;
                                    }} />
                                </Switch>
                            </RouteContainer>
                        </Content>
                    </>
                }
            </Layout>
        );
    }
}

const PrivateRoute = ({ component: Component, isAuthed, ...rest }) => {
    return (<Route {...rest} render={(props) => isAuthed ?
        <Component {...props} />
        :
        <Redirect
            to={{
                pathname: "/",
                state: { from: props.location },
            }}
        />
    } />);
};
const LayoutStyle = {
    position: "relative" as "relative",
    width: "100%",
    height: "100%",
    backgroundColor: "#ececec",
};
const RouteContainer = styled.div`
    position: absolute;
    left:   0;
    top:    0px;
    right:  0;
    bottom: 0;
    background-color: #fff;
    overflow: hidden;
`;