import { Card, Col, Form, Icon, Input, List, Modal, Row, Tabs } from "antd";
import FormItem from "antd/lib/form/FormItem";
import _ from "lodash";
import * as mobx from "mobx";
import { observer } from "mobx-react";
import moment from "moment";
import * as React from "react";
import styled from "styled-components";

import Doc from "../../../models/Doc";
import DocRef from "../../../models/DocRef";
import Folder from "../../../models/Folder";
import Node from "../../../models/Node";
import BaseComponent from "../../BaseComponent";

const TabPane = Tabs.TabPane;

interface IProps {
    node: Node;
    // doc: Doc;
    // delete?: boolean;
    hideMeta?: boolean;
    mode?: string;
    isNewNameCorrect?: boolean;
    onChange?: (evt) => void;
}
@observer
export default class NodeInfoView extends BaseComponent<IProps, any> {

    @mobx.computed
    public get node() { return this.props.node; }

    @mobx.computed
    public get doc(): Doc { return this.props.node instanceof Doc ? this.props.node : null; }

    @mobx.computed
    public get referencesCount() {
        if (this.doc && this.doc.references) {
            return this.doc.references.length;
        }
        return 0;
    }

    @mobx.computed
    public get beReferencedCount() {
        if (this.doc && this.doc.beReferenced) {
            return this.doc.beReferenced.length;
        }
        return 0;
    }

    /**
     * 引用的文档总数。（引用+被引用）
     */
    @mobx.computed
    public get totalReferencingCount() {
        return this.referencesCount + this.beReferencedCount;
    }

    /**
     * 当前文件节点的预览链接
     */
    @mobx.computed
    public get link() {
        let url = window.location.href;
        let arr = url.split("/");
        let host = arr[0] + "//" + arr[2];
        return `${host}${this.store.models.getViewerPage(this.node)}`;
    }

    public render() {
        return (
            <Tabs defaultActiveKey={this.doc && this.doc.beReferenced.length && this.props.mode === "delete" ? "2" : "1"} animated={false}>
                <TabPane tab={<span><Icon type="file-text" />基础信息</span>} key="1">
                    <table style={{ tableLayout: "fixed" }}>
                        <tbody>
                            <tr>
                                <td style={{ width: "400px" }}>
                                    <table style={{ tableLayout: "fixed" }}>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: "80px" }}>ID：</td>
                                                <td><code>{this.node.id}</code></td>
                                            </tr>
                                            <tr>
                                                <td>名字：</td>
                                                {this.props.mode === "rename" ?
                                                    (<td>
                                                        <FormItem {...FormItemLayout}
                                                            validateStatus={this.props.isNewNameCorrect ? "success" : "error"}
                                                            help="名字不能包含 \ / : * ? < > | ! 符号"
                                                        >
                                                            <Input
                                                                pattern={`^[^\\\\/:*?"<>|!]+$`}
                                                                defaultValue={this.props.node.name}
                                                                autoFocus={true}
                                                                maxLength={20}
                                                                onChange={this.props.onChange} />
                                                        </FormItem>
                                                    </td>)
                                                    :
                                                    (<td>{this.props.node.name}</td>)
                                                }
                                            </tr>
                                            <tr>
                                                <td>类型：</td>
                                                <td>
                                                    {this.store.models.getName(this.node.type)}
                                                    {this.node instanceof Folder && this.node.usedStrictType && `　限定类型【${this.store.models.getName(this.node.inheritedStrictType)}】`}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>创建时间：</td>
                                                <td><code>{moment(this.node.createdAt).format("YYYY-MM-DD HH:mm:ss")}</code></td>
                                            </tr>
                                            <tr>
                                                <td>修改时间：</td>
                                                <td><code>{moment(this.node.updatedAt).format("YYYY-MM-DD HH:mm:ss")}</code></td>
                                            </tr>
                                            {this.node.data &&
                                                <tr>
                                                    <td>数据源：</td>
                                                    <td><Input defaultValue={this.node.data} readOnly={true} disabled={true}
                                                        style={{
                                                            fontFamily: "Consolas, Menlo, Courier, monospace",
                                                            border: "none",
                                                        }} /></td>
                                                </tr>
                                            }
                                            {this.node.data &&
                                                <tr>
                                                    <td>预览链接：</td>
                                                    <td><Input defaultValue={this.link} readOnly={true} disabled={true}
                                                        style={{
                                                            fontFamily: "Consolas, Menlo, Courier, monospace",
                                                            border: "none",
                                                        }} /></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </td>
                                {/* 缩略图 */}
                                <td style={{ textAlign: "right", width: "320px" }}><img style={{}} src={this.node.thumbnail} /></td>
                            </tr>
                            {this.node.meta && !this.props.hideMeta && this.store.preferences.showMetaCode &&
                                <tr>
                                    <td colSpan={2} style={{ paddingTop: "20px" }}>
                                        <Input.TextArea defaultValue={JSON.stringify(this.node.meta, null, 4)} style={{
                                            display: "block",
                                            fontFamily: "Monaco, Consolas, 'Courier New', 'Microsoft Yahei', 'Simsun', monospace",
                                            lineHeight: "1.5",
                                            resize: "none",
                                            backgroundColor: "#f0f0f0",
                                            fontSize: "12px",
                                            whiteSpace: "pre",
                                            padding: "10px",
                                        }} autosize={{ minRows: 0, maxRows: 10 }} readOnly={true} />
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </TabPane>

                {this.node.type !== "folder" &&
                    <TabPane
                        disabled={this.totalReferencingCount === 0}
                        tab={<span><Icon type="global" />引用关系</span>} key="2">
                        <Row gutter={16}>
                            <Col span={12}>
                                <Card title={`引用的文档（${this.referencesCount}）`} bordered={false}>
                                    {this.referencesCount > 0 ?
                                        <List
                                            bordered={false}
                                            split={false}
                                            itemLayout="horizontal"
                                            dataSource={this.doc.references}
                                            renderItem={(docRef: DocRef) => (
                                                <List.Item>
                                                    <List.Item.Meta
                                                        // avatar={<Avatar src={node.data + "/" + item.thumbnail} />}
                                                        title={<a href={`/list/${docRef.parent && docRef.parent.id}`}>{docRef.name}</a>}
                                                        description={docRef.parents.map((i) => {
                                                            return i.name + " / ";
                                                        })}
                                                    />
                                                </List.Item>
                                            )}
                                        /> : <NoRefsMessage>没有引用</NoRefsMessage>
                                    }

                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card title={`被其他文档引用（${this.beReferencedCount}）`} bordered={false}>
                                    {this.beReferencedCount > 0 ?
                                        <List
                                            bordered={false}
                                            split={false}
                                            itemLayout="horizontal"
                                            dataSource={this.doc.beReferenced}
                                            renderItem={(docRef: DocRef) => (
                                                <List.Item>
                                                    <List.Item.Meta
                                                        title={<a href={`/list/${docRef.parent && docRef.parent.id}`}>{docRef.name}</a>}
                                                        description={docRef.parents.map((name) => {
                                                            return name.name + " / ";
                                                        })}
                                                    />
                                                </List.Item>
                                            )}
                                        /> : <NoRefsMessage>没有被引用</NoRefsMessage>
                                    }
                                </Card>
                            </Col>
                        </Row>
                    </TabPane>
                }
            </Tabs>
        );
    }
}
const NoRefsMessage = styled.span`
    color: rgba(0, 0, 0, 0.45);
`;
const FormItemLayout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 19 },
};