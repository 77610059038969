import _ from "lodash";
import * as mobx from "mobx";

import Store from "./Store";
/**
 * 系统的数据模型管理类。
 * 对 Template 和 Plugin 进行了封装处理
 */
export default class Plugins {

    public readonly store: Store = null;
    public get service() { return this.store.service; }

    @mobx.observable
    private readonly plugins: Map<string, IPlugin> = new Map();

    @mobx.observable
    private assetPlugins: IPlugin[] = [];

    @mobx.observable
    private readonly templatePlugin: IPlugin = null;

    @mobx.observable
    private readonly builderPlugin: IPlugin = null;

    constructor(store: Store) {
        this.store = store;
        this.templatePlugin = {
            type: "template",
            name: "模板编辑器",
            canView: false,
        };
        this.builderPlugin = {
            type: "hulk",
            name: "文档编辑器",
            canView: false,
        };
    }

    public async setup() {
        this.assetPlugins = _.concat(this.assetPlugins, this.store.config.plugins.map((pluginObject) => {
            return {
                type: pluginObject.type,
                name: pluginObject.name,
                canView: pluginObject.modes ? pluginObject.modes.indexOf("view") >= 0 : true,
            };
        }));
    }

    public getByType(type: string) {
        return this.assetPlugins.find((p) => p.type === type);
    }

    public forEach(callback: (plugin: IPlugin) => void) {
        this.assetPlugins.forEach(callback);
    }
}

interface IPlugin {
    type: string;
    name: string;
    canView: boolean;
}