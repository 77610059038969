import _ from "lodash";
import * as mobx from "mobx";

import Node from "./Node";
import Store from "./Store";
/**
 * 系统的数据模型管理类。
 * 对 Template 和 Plugin 进行了封装处理
 */
export default class Models {

    public readonly store: Store = null;
    public get service() { return this.store.service; }

    @mobx.observable
    private readonly models: Map<string, IModel> = new Map();

    @mobx.observable
    public readonly templateModels: IModel[] = [];

    @mobx.observable
    public readonly pluginModels: IModel[] = [];

    constructor(store: Store) {
        this.store = store;
    }

    public setup() {
        this.pluginModels.splice(0);
        this.store.plugins.forEach((plugin) => {
            let model = {
                type: plugin.type,
                name: plugin.name,
                icon: `/plugins/${plugin.type}/logo.png`,
            };
            this.models.set(model.type, model);
            this.pluginModels.push(model);
        });
    }

    public async refresh() {
        this.templateModels.splice(0);
        this.store.templates.nodes.forEach((node) => {
            let model = {
                type: node.id,
                name: node.name,
                icon: node.thumbnail || `/plugins/hulk/logo.png`,
            };
            this.models.set(model.type, model);
            this.templateModels.push(model);
        });
    }

    public getIconUrl(type: string) {
        if (type === "folder") {
            return `/assets/img/thumbnail-folder-inverse.png`;
        }
        let model = this.models.get(type);
        if (model) {
            return model.icon;
        }
        return `/assets/img/thumbnail-empty.png`;
    }

    public getName(type: string) {
        if (type === "folder") {
            return `文件夹`;
        }
        if (type === "user") {
            return `用户`;
        }
        if (type === "usergroup") {
            return `用户组`;
        }
        let model = this.models.get(type);
        if (model) {
            return model.name;
        }
        return `其他类型`;
    }

    /**
     * 是否有查看模式。
     * @param node 节点
     */
    public canView(node: Node) {
        let plugin = this.store.plugins.getByType(node.type);
        if (plugin) {
            return plugin.canView;
        } else {
            return true;
        }
    }

    public getViewerPage(node: Node) {
        if (node.type === "template") {
            return this.getPage("template", "view", node.data);
        }
        let plugin = this.store.plugins.getByType(node.type);
        if (plugin) {
            return this.getPage(node.type, "view", node.data);
        }
        return this.getPage("hulk", "view", node.data);
    }

    public getEditorPage(node: Node) {
        if (node.type === "template") {
            return this.getPage("template", "edit", node.data);
        }
        let plugin = this.store.plugins.getByType(node.type);
        if (plugin) {
            return this.getPage(node.type, "edit", node.data);
        }
        let template = this.store.templates.getById(node.type);
        return `${this.getPage("hulk", "edit", node.data)}&template=${encodeURIComponent(template.data)}`;
    }

    private getPage(code: string, mode: string, data: string) {
        if (data === null || data === undefined) {
            return null;
        }
        data = this.getUrlParam(data);
        return `/plugins/${code}/index.html?mode=${mode}&path=${data}`;
    }

    private getUrlParam(sourceUrl: string) {
        if (sourceUrl.startsWith("http://") || sourceUrl.startsWith("https://")) {
        } else {
            sourceUrl = _.trim(sourceUrl, "/\\");
            // sourceUrl = `/apis/assets/${sourceUrl}`;
        }
        sourceUrl = encodeURIComponent(sourceUrl);
        return sourceUrl;
    }
}

interface IModel {
    type: string;
    name: string;
    icon: string;
}