import _ from "lodash";
import * as mobx from "mobx";

import * as REST from "./Service";

export default class FolderRef {

    public get id() { return this.$id; }
    @mobx.observable
    private $id: string = null;

    /**
     * 文件夹的名字。
     */
    public get name() { return this.$name; }
    @mobx.observable
    private $name: string = null;

    /**
     * 严格模式的类型。如果不是严格模式，这里是 null。
     * 严格模式是指，文件夹里面的文档，都必须是同一个类型的。
     */
    public get strictType() { return this.$strictType; }
    @mobx.observable
    private $strictType: string = null;

    public get isRoot() { return this.id === "00000000-0000-0000-0000-000000000010"; }

    private constructor() { }

    public static createByDataObject(dataObject: any): FolderRef {
        let parentFolder = new FolderRef();
        parentFolder.$id = dataObject.id;
        parentFolder.$name = dataObject.name;
        if (dataObject.meta) {
            parentFolder.$strictType = dataObject.meta.strict_type || null;
        }

        // if (parentFolder.isRoot) {
        //     parentFolder.$name = "根目录";
        // }

        return parentFolder;
    }

    /**
     * 创建父节点列表
     * @param nodeDetail 数据对象
     */
    public static createParents(nodeDetail: REST.INodeDetail): FolderRef[] {
        // API 获取的 parents 是从子到父，和 UI 需要的相反，所以倒序一下。
        let parents = nodeDetail.parents ? nodeDetail.parents.map((parent) => {
            return FolderRef.createByDataObject(parent);
        }).reverse() : [];
        // 删掉第一个
        parents.shift();

        return parents;
    }
}