import { Table } from "antd";
import FileSaver from "file-saver";
import * as mobx from "mobx";
import { observer } from "mobx-react";
import moment from "moment";
import * as React from "react";
import styled from "styled-components";

import ActivityManager, { IActivity } from "../../models/ActivityManager";
import UserManager from "../../models/users/UserManager";
import AuthoredComponent from "../AuthoredComponent";

import Toolbar from "./Toolbar";

const { Column } = Table;

@observer
export default class ActivityListView extends AuthoredComponent<any> {

    @mobx.computed
    private get activities() { return this.activityManager.activities.slice(); }

    @mobx.computed
    private get take() { return this.activityManager.take; }

    @mobx.computed
    private get total() { return this.activityManager.total; }

    /**
     * 活动记录管理器。
     */
    @mobx.observable
    private activityManager: ActivityManager = new ActivityManager(this.store);
    /**
     * 用户管理器。
     */
    @mobx.observable
    private userManager: UserManager = new UserManager(this.store);

    @mobx.observable
    private isLoaded: boolean = false;

    public async componentDidMount() {
        this.search();
    }

    /**
     * 筛选
     */
    private search() {
        this.run("读取活动记录", async () => {

            await this.userManager.refresh();

            await this.activityManager
                // 返回第一页
                .setPager(0, 50)
                .refresh();

            this.isLoaded = true;
        });
    }

    /**
     * 导出
     */
    public export() {
        this.run("导出记录", async () => {
            let blob = await this.activityManager.export();

            FileSaver.saveAs(blob, `activities-${Date.now()}.xlsx`);
        });
    }

    /**
     * table中自带的pagination变化行为
     */
    private async onPaginationChanged(pagination) {
        let skip = (pagination.current - 1) * pagination.pageSize;

        this.run("读取翻页数据", async () => {
            await this.activityManager
                .setPager(skip, pagination.pageSize)
                .refresh();
        });
    }

    /**
     * 翻译操作类型为中文
     */
    private getActionText(action) {
        switch (action) {
            case "update":
                return "更新";
            case "delete":
                return "删除";
            case "create":
                return "创建";
            default:
                break;
        }
    }

    public render() {
        // 读取结束之后，再渲染内容。
        if (!this.isLoaded) {
            return null;
        }
        return (
            <Container>
                <Toolbar
                    users={this.userManager.users}
                    onSubmit={(query) => {
                        this.activityManager.setQuery(query);
                        this.search();
                    }}
                    onExport={(query) => {
                        this.activityManager.setQuery(query);
                        this.export();
                    }}
                />
                <TableContainer>
                    <Table
                        rowKey="id"
                        dataSource={this.activities}
                        scroll={{ y: window.innerHeight - 165 }}
                        pagination={{ pageSize: this.take, total: this.total }}
                        onChange={this.onPaginationChanged.bind(this)} >

                        <Column key="id" dataIndex="id" title="内容" width={400}
                            render={(id, activity: IActivity) => `${activity.user.name} ${this.getActionText(activity.action)}了 ${this.store.models.getName(activity.node.type)} ${activity.node.name}`}
                        />

                        <Column key="user.id" align="center" title="操作人" width={200}
                            render={(userId, activity: IActivity) => <div>{activity.user.name}</div>}
                        />

                        <Column key="date" dataIndex="date" align="center" title="日期" width={200}
                            render={(date) => <code>{moment(date).format("YYYY-MM-DD HH:mm:ss")}</code>}
                        />

                        <Column key="action" dataIndex="action" align="center" title="操作类型" width={200}
                            render={(text, activity: IActivity) => (
                                <div>{this.getActionText(activity.action)}</div>
                            )}
                        />

                        <Column key="node.id" align="center" title="资源" dataIndex="target" width={200}
                            render={(nodeId, activity: IActivity) => {
                                if (activity.action === "delete") {
                                    return null;
                                }
                                if (activity.node.type && activity.node.id) {
                                    if (activity.node.type === "folder") {
                                        return <a target="_blank" href={`/list/${activity.node.id}`}>打开文件夹</a>;
                                    } else if (activity.node.type === "user" || activity.node.type === "usergroup") {
                                        return null;
                                    } else {
                                        return <a target="_blank" href={`apps/viewer/${activity.node.id}`}>查看文档</a>;
                                    }
                                }
                                return null;
                            }}
                        />
                    </Table>
                </TableContainer>
            </Container>
        );
    }
}
const Container = styled.div`
    position: absolute;
    top:    0;
    left:   0;
    right:  0;
    bottom: 0;
    padding: 20px;
`;
const TableContainer = styled.div`
    .ant-table-pagination{
    position: fixed;
    text-align: right;
    bottom: 0;
    right: 20px;
    cursor: pointer;
    height: 48px;
    line-height: 48px;
    color: #fff;
    transition: all 0.2s;
    background: #fff;
    width: 100%;
    &.ant-pagination{
        margin:0;
    }
    }
`;