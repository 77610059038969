import "animate.css";
import { ConfigProvider } from "antd";
import zhCN from "antd/lib/locale-provider/zh_CN";
import * as React from "react";
import * as ReactDOM from "react-dom";
import * as ReactModal from "react-modal";
import { BrowserRouter } from "react-router-dom";

import Application from "./components/Application";
import COMWrapper from "./components/com/COMWrapper";
import Switcher from "./components/com/Switcher";
import { JsTree } from "./components/common/JsTree";
import Page from "./components/Page";
import Task from "./components/Task";
import "./index.less";
import Store from "./models/Store";

// tslint:disable-next-line:ordered-imports
import "./vendor/antd.css";
import "./vendor/fontawesome/css/all.min.css";

import jstreeImage from "./vendor/jstree/32px.png";

let store = new Store();

(window as any).store = store;
(window as any).task = new Task();
(window as any).page = new Page();

ReactModal.setAppElement("#app");

declare const __IS_DEBUG__: boolean;
(window as any).isDebug = __IS_DEBUG__;

if (__IS_DEBUG__) {

} else {
    console.group = () => { };
    console.info = () => { };
    console.debug = () => { };
    console.log = () => { };
    console.warn = () => { };
}

if (window.location.pathname === "/com") {
    let GlobalStyle = JsTree.useCustomImage(jstreeImage);
    ReactDOM.render(
        <>
            <GlobalStyle />
            <Switcher />
        </>,
        document.getElementById("app"),
    );
    // JsTree.useCustomImage(jstreeImage);
} else if (window.location.pathname === "/test") {
    COMWrapper.openFileDialog("arrow", "00000000-0000-0000-0000-000000000010");
} else {
    store.setup().then(startup);
    // startup();
}

async function startup() {
    let GlobalStyle = JsTree.useCustomImage(jstreeImage);

    ReactDOM.render(
        <BrowserRouter>
            <ConfigProvider locale={zhCN}>
                <>
                    <GlobalStyle />
                    <Application />
                </>
            </ConfigProvider>
        </BrowserRouter>,
        document.getElementById("app"),
    );
}